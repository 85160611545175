.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 280px;
    padding: 12px 4px;
    margin-left: 10px;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    overflow: hidden;
}

.pieNumber {
    color: #4f4f4f;
}

.contextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 18px 10px;
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*height: 100%;*/

}

.progressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 500;
}

.progressContainer p {
    margin-bottom: 2px;
    font-size: 12px;
}

.progressContainer p:first-child {
    color: #BDBDBD;
}


.divider {
    height: 100%;
    width: 2px;
}

.title {
    color: #849AA9;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
}