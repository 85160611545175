.legendHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.legendHeaderSection {
    display: flex;
    flex-direction: row;
}

.legendHeaderNumberSection {
    display: flex;
    flex-direction: row;
}

.legendTitle {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
}

.legendBar {
    width: 7px;
    height: 20px;
    margin: 0 10px 0 6px;
}
