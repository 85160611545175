.generic_select .ant-select-selector {
    border-radius: 6px !important;
}

.generic_select__label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}


.generic_select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 5px;
}

.generic_select .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    justify-content: start;
}

.error-message {

}