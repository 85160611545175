.main {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    padding: 10px;
    width: 50%;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    background-color: #FFFFFF;
}

.title {
    padding: 10px 18px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
}

.chartContainer {
    /*overflow: hidden;*/
    padding: 20px 16px;
    /*margin-bottom: 60px;*/
}

.collapseTable {
    padding: 10px;
    margin: 20px 10px;
}

.hasDataTablePanel {
    margin-bottom: 24px;
    background: rgba(249, 249, 249, 1);
    border: none;
}

.panel {
    background-color: #FFFFFF;
    border: none;
    padding: 6px 12px;
}