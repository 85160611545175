.main {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    padding: 10px;
    margin: 30px 0 30px 10px;
    background-color: #FFFFFF;
    border-radius: 5px;
}


.graphHeader {
    padding: 10px 18px;
    border-bottom: solid 1px #E0E0E0;
}

.scrollTable {
    overflow-x: scroll;
}