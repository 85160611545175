:root {
    --primary-color: #3f4e52;
    --bg-color: #e5e5e5;
    --bg-f8-color: #f8f8f8;
    --white-color: #ffffff;
    --Gray-1-color: #333333;
    --Gray-2-color: #4f4f4f;
    --Gray-3-color: #828282;
    --Gray-4-color: #d1d1d6;
    --Gray-5-color: #e0e0e0;
    --Gray-6-color: #f2f2f2;
    --Green-1-color: #38bc94;
    --Green-2-color: #7fd4ba;
    --f5fffc-color: #f5fffc;
    --black-color: #000000;
    --balck-2-color: #2f3c40;
    --bdbdbd-color: #bdbdbd;
    --daf2eb-color: #daf2eb;
}

html {
    --antd-wave-shadow-color: transprent;
}

body,
#root,
.App {
    height: 100%;
}

body {
    font-family: "Inter", sans-serif !important;
    font-size: 14px;
}

a,
button,
.ant-btn {
    transition: 0.3s ease all;
    text-shadow: none !important;
    opacity: 1;
}

a:focus,
button:focus,
.ant-btn:focus,
a:hover,
button:hover {
    outline: none;
    text-decoration: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
}

.ant-btn:hover {
    background: var(--Gray-1-color);
    color: var(--white-color);
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-align-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-align-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hide {
    display: none !important;
}

/*--login-sign-up-css--*/

.credential-logo-left {
    background: var(--primary-color);
}

.credential-details-right {
    background: var(--bg-f8-color);
    padding: 60px 24%;
}

.retrieve-oppo {
    position: relative;
    top: -15px;
    cursor: pointer;
    float: right;
    letter-spacing: -0.24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--Green-1-color);
    text-decoration: underline !important;
}

.credential-details-right h5 {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.021em;
    color: var(--Gray-2-color);
    margin-bottom: 5px;
}

.credential-details-right p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: var(--Gray-3-color);
}

.credential-details-right p a {
    color: var(--Green-1-color);
}

.common-form {
    margin-top: 20px;
}

.common-form .ant-form-item {
    margin-bottom: 15px;
}

.common-form .ant-form-item .ant-form-item-label label {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-3-color);
}

.common-form .ant-form-item .ant-form-item-control .ant-btn.eye-btn {
    right: 3px;
    height: 40px;
}

.common-form
.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input,
.common-form
.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-affix-wrapper.ant-input-password,
.view-cost-input .ant-input-number {
    height: 40px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    color: var(--Gray-3-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    border-radius: 6px;
}

.view-cost-input .ant-input-number {
    width: 100%;
}

.view-cost-input .ant-input-number .ant-input-number-input {
    height: 38px;
    color: var(--Gray-2-color);
}

.view-cost-input .ant-input-number.ant-input-number-disabled {
    background-color: #f5f5f5;
}

.common-form
.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input::-webkit-input-placeholder {
    color: var(--Gray-4-color);
}

.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-number-input {
    color: var(--Gray-2-color);
    font-weight: 500 !important;
}

.common-form
.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-affix-wrapper.ant-input-password
.ant-input {
    height: auto;
    border: 0;
    background: transparent;
    border-radius: 0;
}

.common-form .ant-form-item .sign-in-btn,
.common-form .ant-form-item .ant-btn.sign-in-btn {
    height: 44px;
    width: 100%;
    background: transparent;
    color: var(--Gray-4-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    border: 0;
}

.common-form .ant-form-item .sign-in-btn.sign-in-primary,
.common-form .ant-form-item .ant-btn.sign-in-btn.ant-btn-primary {
    background: var(--Green-1-color);
    color: var(--white-color);
    opacity: 0.5;
}

.common-form .ant-form-item .sign-in-btn.sign-in-primary:hover,
.common-form .ant-form-item .sign-in-btn.sign-in-primary.active,
.common-form .ant-form-item .ant-btn.sign-in-btn.ant-btn-primary:hover,
.common-form .ant-form-item .ant-btn.sign-in-btn.ant-btn-primary.active {
    opacity: 1;
    border-color: var(--Green-1-color);
}
.summary-info-inner-row .collapse-update .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 45px;
}
.forgot-pswd-div {
    margin-bottom: 15px;
}

.forgot-pswd-div a {
    top: -10px;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: var(--Green-1-color);
}

.common-form
.ant-form-item.verification-input
.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 10px;
}

.common-form
.ant-form-item.verification-input
.ant-form-item-control
.ant-form-item-control-input
.ant-input {
    width: 40px;
}

/*--sidebar-css--*/

.ant-btn.menu-fold-btn {
    right: 10px;
    top: 20px;
    transition: 0.3s ease all;
}

.sidebar-main-div {
    background: linear-gradient(0deg, var(--primary-color), var(--primary-color)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) -42.33%, rgba(0, 0, 0, 0) 100%),
    #203d47;
    width: 250px;
    z-index: 99999;
    left: 0;
    top: 0;
    padding: 0 0 45px;
    transition: 0.3s ease all;
}

.sticky-section-row {
    position: static;
    top: 0;
    padding-top: 90px;
    z-index: 99;
}

.sidebar-nav-links-div {
    padding: 30px 0 0;
}

.scroll-section-row > .col-12 {
    overflow: auto;
    height: calc(100vh - 243px);
}

.nav-link-tag {
    height: 42px;
    border-radius: 6px;
    background: transparent;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--f5fffc-color);
    padding: 0 15px;
    opacity: 0.4;
    margin: 1px 0 10px;
}

.nav-link-tag .nav-icons-tag {
    width: 24px;
    margin-right: 5px;
    height: 25px;
}

.nav-link-tag .nav-icons-tag.nav-icons-tag-2 img {
    transition: 0.3s ease all;
}

.nav-link-tag .nav-icons-tag.nav-icons-tag-2 .icon-green {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

.nav-link-tag:hover .nav-icons-tag.nav-icons-tag-2 .icon-gray,
.nav-link-tag.active .nav-icons-tag.nav-icons-tag-2 .icon-gray {
    opacity: 0;
}

.nav-link-tag:hover .nav-icons-tag.nav-icons-tag-2 .icon-green,
.nav-link-tag.active .nav-icons-tag.nav-icons-tag-2 .icon-green {
    opacity: 1;
}

.nav-link-tag .nav-details-tag {
    width: calc(100% - 24px);
}

.sidebar-ul-main .nav-link-tag:hover {
    background: rgba(210, 219, 218, 0.8);
    color: #4f4f4f;
    opacity: 1;
}

.nav-icons-tag {
    background: url("../images/sidebar-icons/dashboard-gray.svg") no-repeat;
}

.nav-link-tag:hover .nav-icons-tag {
    background: url("../images/sidebar-icons/dashboardSidebar-hover.svg") no-repeat;
}

.nav-link-tag.active {
    background: rgba(209, 213, 219, 0.5);
    color: #f5fffc;
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item-active
.ant-collapse-header
.nav-collapse-card-inn {
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item
.ant-collapse-header
.anticon:hover {
    color: #4f4f4f !important;
}

.account-icon-sidebar {
    background: url("../images/sidebar-icons/person-sidebar-new.svg") no-repeat;
}

.sales-sidebar-icon {
    background: url("../images/sidebar-icons/sales-sidebar-new.svg") no-repeat;
}

.operations-sidebar-icon {
    background: url("../images/sidebar-icons/operations-sidebar-new.svg") no-repeat;
}

.managements-sidebar-icon {
    background: url("../images/sidebar-icons/management-sidebar-new.svg") no-repeat;
}

.accounting-sidebar-icon {
    background: url("../images/sidebar-icons/accounting-sidebar-new.svg") no-repeat;
}

.analytics-sidebar-icon {
    background: url("../images/sidebar-icons/analytics_sidebar_new.svg") no-repeat;
}

.data-management-sidebar-icon {
    background: url("../images/sidebar-icons/data-management-sidebar-new.svg") no-repeat;
}

/*---hover-icon-img--*/

.ant-collapse-header:hover .account-icon-sidebar {
    background: url("../images/sidebar-icons/personSidebar-hover.svg") no-repeat;
}

.ant-collapse-header:hover .sales-sidebar-icon {
    background: url("../images/sidebar-icons/salesSidebar-hover.svg") no-repeat;
}

.ant-collapse-header:hover .operations-sidebar-icon {
    background: url("../images/sidebar-icons/operationsSidebar-hover.svg") no-repeat;
}

.ant-collapse-header:hover .managements-sidebar-icon {
    background: url("../images/sidebar-icons/managementSidebar-hover.svg") no-repeat;
}

.ant-collapse-header:hover .accounting-sidebar-icon {
    background: url("../images/sidebar-icons/accounting-sidebar-new.svg") no-repeat;
}


/*-=-=-=-=-=-=-=-=-=-=-=-WHEN-SIDEBAR-NAV-DATA-ACTIVE-SHOW-TIME-=-=-=-=-=-=-=-=-=-=-=-=-=-*/

/*.ant-collapse-header:hover .analytics-sidebar-icon {*/
/*    background: url("../images/sidebar-icons/analyticsSidebar-hover.svg") no-repeat;*/
/*}*/

/*.ant-collapse-header:hover .data-management-sidebar-icon {*/
/*    background: url("../images/sidebar-icons/dataSidebar-hover.svg") no-repeat;*/
/*}*/
.bullet-left {
    display: flex;
    align-items: start;
}

.bullet-left ul li {
 text-align: left;
}


.pull-left-pull-right-btn {
    right: 0;
    bottom: 0;
    height: 56px;
    width: 83px;
    background: var(--balck-2-color);
}

.pull-left-pull-right-btn:hover {
    background: var(--black-color);
}

.sidebar-ul-main {
    padding: 0 10px;
    margin-bottom: 0;
}

.ant-collapse.collapse-sidebar-main {
    border: 0;
    background: transparent;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-item {
    border: 0;
    padding: 0 10px;
    background: transparent;
    transition: 0.3s ease all;
    border-top: 1px solid #293437;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: #3f4e52;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-disabled
.ant-collapse-header {
    background: transparent;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-disabled
.ant-collapse-header:hover {
    background: transparent !important;
    color: var(--f5fffc-color) !important;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-disabled
.ant-collapse-header:hover
.nav-collapse-card-inn,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-disabled
.ant-collapse-header:hover
.ant-collapse-arrow {
    opacity: 0.4;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-active {
    background: #2e3c40 !important;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-header {
    background: linear-gradient(0deg, var(--primary-color), var(--primary-color)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) -42.33%, rgba(0, 0, 0, 0) 100%),
    #203d47;
    letter-spacing: 0.01em;
    color: var(--f5fffc-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    width: 100%;
    transition: 0.2s ease all;
    height: 44px;
    border-radius: 6px !important;
    padding-left: 15px;
}

.sidebar-user-icon {
    width: 24px;
    margin-right: 5px;
    height: 25px;
    transition: 0.2s ease all;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item-active
.ant-collapse-header,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item.active
.ant-collapse-header {
    background: rgba(209, 213, 219, 0.5);
    color: #f5fffc;
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item
.ant-collapse-header:hover {
    background: rgba(210, 219, 218, 0.8) !important;
    color: #4f4f4f !important;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item
.ant-collapse-header:hover
.nav-collapse-card-inn,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item
.ant-collapse-header:hover
.ant-collapse-arrow {
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-header .ant-collapse-arrow {
    opacity: 0.4;
    right: 10px;
    left: auto;
    font-size: 14px;
    transform: rotate(90deg) !important;
    top: 35%;
    position: absolute;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item-active
.ant-collapse-header
.nav-collapse-card-inn,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item-active
.ant-collapse-header
.ant-collapse-arrow,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item.active
.ant-collapse-header
.nav-collapse-card-inn,
.ant-collapse.collapse-sidebar-main
.ant-collapse-item.active
.ant-collapse-header
.ant-collapse-arrow {
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-item-active
.ant-collapse-header
.ant-collapse-arrow {
    transform: rotate(180deg) !important;
}

.ant-collapse.collapse-sidebar-main .nav-collapse-card-inn {
    opacity: 0.4;
    position: relative;
    display: flex;
    align-items: center;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-content-box .nav-link-tag {
    margin: 0;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-content {
    border: 0;
    background: transparent;
}

.ant-collapse.collapse-sidebar-main .ant-collapse-content-box {
    padding: 10px 0;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-content-box
.nav-link-tag.active {
    background: transparent;
    color: var(--Green-1-color);
    opacity: 1;
}

.ant-collapse.collapse-sidebar-main
.ant-collapse-content-box
.nav-link-tag:hover {
    background: #182124;
    color: var(--white);
}

.vehicle-table-text-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th:first-child {
    min-width: 160px;
}

.width-160-id
.main-table-all
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th:first-child {
    width: 160px;
    min-width: 160px;
}

/*--header--css--*/

.custom-header-main {
    right: 0;
    top: 0;
    background: var(--white-color);
    border-bottom: 1px solid var(--Gray-5-color);
    height: 75px;
    width: calc(100% - 250px);
    float: right;
    z-index: 999;
    padding: 15px 45px 0;
    transition: 0.3s ease all;
}

.heading-header-main .ant-breadcrumb {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.heading-header-main .ant-breadcrumb .ant-breadcrumb-link {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    color: var(--bdbdbd-color);
    letter-spacing: 0.02em;
}

.heading-header-main .ant-breadcrumb > span.ant-breadcrumb-link:last-child a {
    color: var(--Gray-1-color);
    font-weight: 600;
    letter-spacing: 0.021em;
}

.heading-header-main .ant-breadcrumb .ant-breadcrumb-link a span {
    color: var(--Gray-4-color);
    font-weight: 600;
}

.heading-header-main .ant-breadcrumb .ant-breadcrumb-separator {
    margin: 0;
    position: relative;
    top: -2px;
}

.header-right-details {
    width: 100%;
}

.header-right-details ul .list-inline-item:not(:last-child) {
    margin-right: 1.2rem;
}

.search-bar-div .ant-form .ant-input, .search-input-header.ant-input-affix-wrapper {
    border: 1px solid var(--Gray-5-color);
    border-radius: 4px;
    height: 36px;
    width: 300px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--Gray-3-color);
    padding: 0 15px 0 45px;
    background: #f6f6f6;
}

.search-bar-div .ant-form .search-input-header.ant-input-affix-wrapper .ant-input {
    border: 0;
    height: 34px;
    padding: 0;
    width: 100%;
    box-shadow: none;
}

.search-input-header .ant-input-clear-icon-hidden {
    visibility: visible;
}

.search-bar-div .ant-form .ant-input:hover, .search-input-header.ant-input-affix-wrapper:hover {
    border-color: var(--Green-1-color);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
}

.search-opportunities-div .search-bar-div .ant-form .ant-input,
.search-view-header-in-collapse .search-bar-div .ant-form .ant-input {
    background: var(--white-color);
    width: 230px;
}

.search-bar-div .ant-form .ant-input::-webkit-input-placeholder {
    color: var(--Gray-3-color);
}

.search-bar-div .ant-form .ant-btn {
    height: 36px;
    left: 15px;
    top: 2px;
    z-index: 9;
}

.search-li-custom .search-bar-div .ant-form .ant-btn {
    left: 2px;
    top: 4px
}

.create-div .ant-btn {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    height: 36px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--white-color);
    /*width: 117px;*/
}

.beta-heading {
    background: #F2C94C;
    height: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222C2F;
    letter-spacing: 0.35px;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin: 15px 0;
}

.main-table-all.sorter-design-fix .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
    display: flex;
}

.email-custom {
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #828282;
}

.beta-heading.beta-heading-sidebar {
    height: 29px;
    letter-spacing: -0.41px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
}

.create-div .ant-btn:hover {
    color: var(--white-color);
    background: #222c2f;
    border-color: #222c2f;
}

.create-div .ant-btn:focus {
    background: #38bc94;
    border: 3px solid rgba(51, 51, 51, 0.1);
}

.create-div .ant-btn img {
    margin-right: 5px;
}

.account-user-details .ant-btn {
    width: 40px;
    height: 40px;
    background: var(--Green-2-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--white-color);
}

.round-red-notification {
    background: #eb5757;
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
}

.ant-dropdown.create-dropdown-main {
    min-width: 200px !important;
}

.ant-dropdown.create-dropdown-main.last-activity-dropdown {
    min-width: 150px !important;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu {
    background: #526368;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px 0;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item a,
.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item div {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--white-color);
    height: 45px;
    padding: 0 15px;
    cursor: pointer;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item a span {
    transition: 0.3s ease all;
    opacity: 0.7;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item a:hover span {
    opacity: 1;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item .ant-btn:hover {
    background: transparent;
}

.ant-dropdown.create-dropdown-main .ant-switch {
    margin-right: 10px;
    background: var(--bdbdbd-color);
}

.ant-dropdown.create-dropdown-main .ant-switch.ant-switch-checked {
    background: var(--Green-1-color);
}

.ant-dropdown.create-dropdown-main.sales-person-dropdown
.ant-dropdown-menu-item
a {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}

.ant-dropdown.create-dropdown-main.sales-person-dropdown
.ant-dropdown-menu-item
a
img {
    margin-right: 5px;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item a:hover,
.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item:hover {
    background: var(--balck-2-color);
    opacity: 1;
}

.ant-dropdown.create-dropdown-main .ant-dropdown-menu-item {
    padding: 0 15px;
}

/*--dashboard-css--*/

.main-content-div {
    width: calc(100% - 250px);
    float: right;
    margin-top: 74px;
    transition: 0.3s ease all;
}

.mini-header-filter-list-grid-row {
    min-height: 58px;
    background: var(--white-color);
    padding: 15px 30px;
    margin-top: 30px;
}

.filter-btn.ant-btn {
    background: var(--Gray-6-color);
    border-radius: 4px;
    border: 1px solid var(--Gray-6-color);
    height: 32px;
    width: 95px;
    color: var(--Gray-3-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.031em;
    float: right;
    z-index: 111;
}

.filter-btn.ant-btn img,
.list-view-btn.ant-btn img {
    margin-right: 5px;
}

.list-view-div {
    margin: 0 10px;
}

.month-select-picker-div {
    margin-left: 10px;
}

.list-view-btn.ant-btn {
    height: 32px;
    background: #f6f6f6;
    border: 1px solid var(--Gray-4-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 102px;
    color: var(--Gray-2-color);
    letter-spacing: 0.004em;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}

.main-table-all .ant-table-fixed-header .ant-table-container .ant-table-body {
    overflow: auto !important;
}

.main-table-all .ant-table-fixed-header .ant-table-container .ant-table-body {
    overflow-x: hidden;
    overflow-y: auto;
}

.main-table-all .ant-table-fixed-header .ant-table-container .ant-table-body::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: var(--Gray-5-color);
}

.main-table-all .ant-table-fixed-header .ant-table-container .ant-table-body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.main-table-all .ant-table-fixed-header .ant-table-container .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--Gray-3-color);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.calendar-view-btn {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: var(--Gray-6-color);
    border-color: var(--Gray-6-color);
    cursor: pointer;
}

.calendar-view-btn:hover,
.calendar-view-btn:focus {
    border-color: var(--Gray-6-color);
}

.calendar-view-btn .ant-picker-input > input {
    display: none;
}

.month-select-picker-div
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    height: 32px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    color: var(--Gray-1-color);
}

.month-select-picker-div .ant-select-selection-placeholder {
    color: var(--Gray-1-color);
}

.month-select-picker-div .ant-select-arrow {
    top: 50%;
}

.dashboard-card-row-main {
    background: var(--bg-f8-color);
    padding: 20px 45px;
    border-bottom: 1px solid var(--Gray-5-color);
}

.card.dashboard-card {
    min-height: 108px;
    width: 18.4%;
    padding: 15px 15px 15px 5px;
}

.card-img-div {
    width: 65px;
}

.card-details-div {
    width: calc(100% - 65px);
}

.card-details-div h6 {
    color: var(--Gray-2-color);
    opacity: 0.8;
    letter-spacing: 0.02em;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
}

.card-details-div h6.card-db-h6 {
    margin: 8px 0 3px;
}

.card-details-div h4 {
    letter-spacing: 0.018em;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: var(--primary-color);
    margin: 0;
}

.card-details-div h4.blue-color {
    color: #3391ff;
    margin-top: 15px;
}

.card-details-div h4 .ant-btn {
    margin-left: 5px;
}

.card-details-div p {
    color: var(--Gray-3-color);
    letter-spacing: 0.004em;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}

.dashboard-tabs-card-row-main {
    background: var(--white-color);
}

.ant-tabs.main-tab-card-all {
    padding: 30px;
}

.ant-tabs.main-tab-card-all .ant-tabs-nav {
    margin-bottom: 0;
}

.ant-tabs.main-tab-card-all .ant-tabs-nav:before {
    border: 0;
}

.ant-tabs.main-tab-card-all .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 15px 15px 0;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab {
    background: transparent;
    border: 0;
    height: auto;
    padding: 0;
    border-radius: 0;
    margin-right: 0;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab
.ant-tabs-tab-btn {
    color: var(--Gray-3-color);
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    background: var(--bg-f8-color);
    border: 1px solid var(--Gray-5-color);
    height: 37px;
    padding: 0 20px;
    border-radius: 18px 18px 0 0;
    margin-right: 2px;
    z-index: 999;
    position: relative;
    border-bottom: 0;
    display: flex;
    align-items: center;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab
.ant-tabs-tab-btn
.tabs-design-angle {
    position: absolute;
    top: 10px;
    right: -26px;
    width: 37px;
    height: 37px;
    border: 1px solid var(--Gray-5-color);
    z-index: -1;
    transform: rotate(-45deg);
    background: var(--bg-f8-color);
    opacity: 0;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab
.common-heading-div
.tab-heading-count-card {
    height: 17px;
    width: 32px;
    background-color: var(--Gray-5-color);
    border-radius: 10px;
    color: var(--Gray-3-color);
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004em;
    margin-left: 8px;
    transition: 0.3s ease all;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab.ant-tabs-tab-active
.ant-tabs-tab-btn,
.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab:hover
.ant-tabs-tab-btn {
    color: var(--Green-1-color);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    background: var(--white-color);
    border-color: var(--white-color);
    z-index: 10;
}

.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab.ant-tabs-tab-active
.common-heading-div
.tab-heading-count-card,
.ant-tabs.main-tab-card-all
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list
.ant-tabs-tab:hover
.common-heading-div
.tab-heading-count-card {
    background: var(--Green-1-color);
    color: var(--white-color);
}

.ant-tabs.main-tab-card-all .ant-tabs-content-holder {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    border-radius: 0 12px 12px 12px;
    background: var(--white-color);
    padding: 0 15px;
    z-index: 9;
    overflow: hidden;
}

.main-table-div {
    padding: 0;
}

.ant-dropdown.profile-fix-dropdown {
    min-width: 266px !important;
    max-width: 300px;
}

.profile-fix-dropdown .ant-dropdown-menu {
    padding: 15px 0 0;
    border-radius: 8px;
}

.profile-fix-dropdown .ant-dropdown-menu-item {
    border-bottom: 1px solid #bdbdbd;
    padding: 15px;
}

.profile-fix-dropdown .ant-dropdown-menu-item:last-child {
    border: 0;
}

.profile-fix-dropdown .ant-dropdown-menu-item:first-child:hover {
    background-color: transparent;
}

.profile-details-div {
    text-align: center;
}

.profile-avatar-div {
    width: 72px;
    height: 72px;
    background: var(--Green-2-color);
    color: var(--white-color);
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin: 0 auto 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-details-div h6 {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 5px;
    line-height: 22px;
    color: var(--Gray-2-color);
    letter-spacing: -0.41px;
}

.profile-details-div p {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.primary-text-right {
    letter-spacing: 0.07px;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: var(--Green-1-color);
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.sign-with-google-btn, .logout-btn, .logout-btn:hover {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.sign-with-icon {
    width: 24px;
    margin-right: 10px;
    float: left;
}

.sign-with-content {
    width: calc(100% - 34px);
    float: left;
    white-space: break-spaces;
    text-align: left;
}

.sign-with-google-btn img, .logout-btn img {
    margin-right: 10px;
}

.main-table-all table thead tr th {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: var(--Gray-1-color);
    padding: 5px 15px 15px !important;
    min-width: 220px;
    background: var(--white-color);
    border: 0;
}

.main-table-all.work-order-table-inner table thead tr th {
    min-width: 160px;
}

.main-table-all .ant-table-thead th.ant-table-column-has-sorters:hover,
.main-table-all .ant-table-thead th.ant-table-column-sort {
    background: var(--white-color);
}

.add-plus-icon {
    right: -60px;
    top: -4px;
    box-shadow: none;
    bottom: 0;
}

.main-table-all table tbody tr td {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--Gray-2-color);
    border-left: 0;
    border-right: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    opacity: 0.9;
    cursor: pointer;
}

.main-table-all table tbody tr:nth-child(even) td {
    background: var(--white-color);
}

.main-table-all table tbody tr:nth-child(odd) td {
    background: #f9fbfb;
}

.main-table-all .ant-table.ant-table-middle .ant-table-tbody > tr > td,
.main-table-all tbody tr td {
    padding: 17px 15px;
}

.name-id-img-div {
    width: 34px;
    float: left;
}

.name-id-details {
    font-weight: 600;
    width: calc(100% - 34px);
    float: left;
}

.main-status-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    opacity: 0.8;
    color: var(--Gray-2-color);
    border-radius: 18px;
    height: 24px;
    box-shadow: none;
}

.main-status-btn:hover {
    opacity: 1;
}

.main-status-btn.progress-btn {
    color: #f2c94c;
}

.main-status-btn.approved-btn {
    color: var(--Green-1-color);
}

.add-team-div ul li .team-tag-m {
    width: 26px;
    height: 26px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--white-color);
    position: relative;
}

.add-team-div ul li .team-tag-m.team-tag-m-1 {
    background: var(--Green-2-color);
    z-index: 9;
    left: 6px;
}

.add-team-div ul li .team-tag-m.team-tag-m-2 {
    background: #b4a3f9;
    z-index: 8;
}

.add-team-div ul li .team-tag-m.team-tag-m-3 {
    background: #fdbe86;
    left: -6px;
    z-index: 7;
}

.add-team-div .ant-btn.add-user-btn {
    border: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.main-table-all .table {
    border-collapse: separate;
    border-spacing: 0;
}

/*.internal-location-table .ant-table-pagination .ant-pagination-item,*/
/*.internal-location-table .ant-table-pagination .ant-pagination-next,*/
/*.internal-location-table .ant-table-pagination .ant-pagination-prev {*/
/*    padding-left: 0;*/
/*    font-size: 14px;*/
/*    line-height: 30px;*/
/*    text-align: center;*/
/*    width: 32px;*/
/*}*/

/*.internal-location-table .ant-table-pagination .ant-pagination-prev .ant-pagination-item-link,*/
/*.internal-location-table .ant-table-pagination .ant-pagination-next .ant-pagination-item-link {*/
/*    border-color: transparent;*/
/*}*/

/*.internal-location-table .ant-pagination-item-active {*/
/*    border-color: var(--daf2eb-color);*/
/*    background: var(--daf2eb-color);*/
/*}*/

.main-content-div .ant-pagination.mini .ant-pagination-prev,
.main-content-div .ant-pagination.mini .ant-pagination-next {
    min-width: 32px;
    height: 32px;
    margin: 0;
    line-height: 30px;
    width: 32px;
}

.main-content-div .ant-pagination.mini .ant-pagination-item {
    background: #f9fbfb;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    font-weight: normal;
    font-size: 13px;
    line-height: 26px;
    color: #595959;
    margin: 0 3px;
    text-transform: uppercase;
    letter-spacing: 0;
}

.main-content-div
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input)
.ant-select-selection-search-input {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #595959;
}

.main-content-div
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
.ant-select-selection-item {
    line-height: 34px;
    color: #595959;
}

.main-content-div
.ant-pagination.mini
.ant-pagination-item.ant-pagination-item-active {
    background: var(--daf2eb-color);
    border-color: var(--daf2eb-color);
}

/*.work-order-table.main-table-all table thead tr th:nth-child(1) {*/
/*    min-width: 200px;*/
/*}*/

.work-order-table.main-table-all table thead tr th {
    min-width: 130px;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
    padding: 12px 15px;
}

.work-order-table.main-table-all table thead tr th,
.opportunities-table.main-table-all table thead tr th,
.project-table.main-table-all table thead tr th,
.all-tabs-details.main-table-all table thead tr th,
.proposals-table-main.main-table-all table thead tr th {
    padding: 15px !important;
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th:nth-child(4) {
    text-align: center;
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td {
    padding: 12px 10px;
}

.main-status-btn.text-red {
    color: #eb5757;
}

.project_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.proposals-table-main.main-table-all table thead tr th {
    min-width: 185px;
}

.proposals-main-table .main-table-all table thead tr th {
    min-width: auto;
}

.project-table.main-table-all table thead tr th {
    min-width: 120px;
}

.project-table.main-table-all table thead tr th:nth-child(1) {
    min-width: 300px;
}

.main-table-all
.ant-table.ant-table-middle
.ant-table-thead
.ant-table-column-sorters,
.main-table-div .ant-table-column-sorters {
    display: inline-block;
}

.main-table-all .ant-table-column-title,
.main-table-div .ant-table-column-title {
    width: auto;
    float: left;
}

.main-table-all .ant-table-column-sorter,
.main-table-div .ant-table-column-sorter {
    position: relative;
    top: 1px;
    right: -10px;
    float: left;
    display: block;
    width: auto;
}

.opportunity-db-table .add-plus-icon {
    right: 0;
    box-shadow: none;
}

.email-plus-btn.add-plus-icon {
    right: 50%;
}

.opportunity-db-table .main-table-all table thead tr th {
    min-width: 200px;
    text-align: left;
}

.opportunity-db-table .main-table-all table thead tr th:nth-child(1) {
    min-width: 490px;
}

.service-family-table
.opportunity-db-table
.main-table-all
table
thead
tr
th:nth-child(2),
.service-family-table
.opportunity-db-table
.main-table-all
table
tbody
tr
td:nth-child(2) {
    text-align: center;
}

.opportunity-db-table.work-history-table
.main-table-all
table
thead
tr
th:nth-child(1) {
    min-width: auto;
}

.opportunity-db-table .main-table-all table tbody tr td:nth-child(3) {
    text-align: left;
}

.vehicle-table-text-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:first-child {
    padding-left: 45px;
}

.base-rate-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:nth-child(3),
.base-rate-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th:nth-child(3) {
    text-align: center;
}

/*--opportunity-css--*/

.new-opportunity-btn {
    height: 36px;
    border: 1px solid #3f4e52;
    border-radius: 4px;
    color: var(--white-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.031em;
    background: #3f4e52;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 0 16px;
}

.new-opportunity-btn-white {
    color: #3f4e52 !important;
    background: var(--white-color) !important;
    border: 1px solid #f2f2f2;
}

.new-opportunity-btn:hover,
.ant-upload .new-opportunity-btn.ant-btn:hover {
    color: var(--white-color);
    background: #222c2f;
    border-color: #222c2f;
}

.new-opportunity-btn:focus,
.ant-upload .new-opportunity-btn.ant-btn:focus {
    background: #38bc94;
    border: 3px solid rgba(51, 51, 51, 0.1);
}

.new-opportunity-header-row .filter-btn.ant-btn {
    height: 36px;
}

.remove-dropdown .ant-dropdown-trigger.ant-dropdown-link,
.ant-table-cell .ant-dropdown-trigger.ant-dropdown-link {
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 3px;
    border-radius: 4px;
}

.remove-dropdown .ant-dropdown-trigger.ant-dropdown-link:hover,
.ant-table-cell .ant-dropdown-trigger.ant-dropdown-link:hover {
    background: var(--Gray-6-color);
}

.remove-dropdown .ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open,
.ant-table-cell .ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open {
    background: var(--daf2eb-color);
}

.new-opportunity-header-row .filters-div {
    margin: 0 10px;
}

.opportunities-table-main-dashboard {
    background: var(--white-color);
    padding: 10px 45px 15px;
}

.status-bar-main {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.status-line {
    width: 8px;
    height: 3px;
    background: var(--daf2eb-color);
    border-radius: 100px;
    display: inline-block;
    margin-right: 2px;
}

.status-line.active {
    background: var(--Green-1-color);
}

.status-line.line-closed {
    background: #e39999;
}

.eli-more-icon {
    transition: 0.3s ease all;
    left: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

.main-table-all table thead tr th:hover .eli-more-icon {
    opacity: 1;
}

/*--popover-css--*/

.popover-main-all .ant-popover-inner {
    width: 939px;
    min-height: 489px;
    background: var(--white-color);
    border-radius: 4px;
}

.popover-main-all.ant-popover-placement-bottom
> .ant-popover-content
> .ant-popover-arrow {
    left: 60%;
}

.popover-main-all .ant-popover-inner-content {
    padding: 0;
}

.filter-main-card .col-12.col-sm-3 {
    border-right: 1px solid var(--Gray-5-color);
    padding: 30px 15px;
}

.type-working-checkbox {
    padding-bottom: 15px;
}

.type-working-checkbox h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: var(--Gray-1-color);
    margin-bottom: 15px;
}

.ant-btn.clear-btn {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--Gray-4-color);
}

.type-working-checkbox .ant-checkbox-wrapper {
    font-weight: normal;
    font-size: 13px;
    padding: 5px 0;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-1-color);
}

.type-working-checkbox .ant-checkbox-inner {
    border: 1px solid #8e8e93;
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

.type-working-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background: transparent;
}

.type-working-checkbox
.ant-checkbox.ant-checkbox-checked
.ant-checkbox-inner:after {
    border: 2px solid var(--Green-1-color);
    border-top: 0;
    border-left: 0;
}

.type-working-checkbox .ant-checkbox-checked::after {
    border: 1px solid #8e8e93;
    border-radius: 4px;
}

.expenses-row-inner .ant-input {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 4px;
    width: 80px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    height: 27px;
}

.expenses-row-inner .ant-input::-webkit-input-placeholder {
    color: var(--Gray-3-color);
}

.expenses-row-inner .between-space {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 13px;
    height: auto;
    padding: 0 3px;
}

.type-working-checkbox .ant-collapse {
    margin-top: 15px;
    border: 0;
    background: transparent;
}

.date-created-row .ant-collapse {
    width: 100%;
    border: 0;
    background: transparent;
}

.type-working-checkbox .ant-collapse > .ant-collapse-item,
.type-working-checkbox .ant-collapse-content {
    border: 0;
}

.date-created-row .ant-collapse > .ant-collapse-item,
.type-working-checkbox .ant-collapse-content {
    border: 0;
}

.type-working-checkbox
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    background: transparent;
    padding: 0 15px 0 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
}

.date-created-row .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: var(--Gray-6-color);
    padding: 10px 15px 10px 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
    border-radius: 4px;
}

.type-working-checkbox
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    left: 0;
}

.date-created-row
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    left: auto;
    right: 15px;
}

.date-created-row .ant-picker {
    width: 100%;
    background: var(--Gray-6-color);
    border-color: var(--Gray-6-color);
    border-radius: 4px;
    height: 35px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-1-color);
}

.date-created-row .ant-picker input::-webkit-input-placeholder {
    color: var(--Gray-1-color);
}

.type-working-checkbox .ant-collapse-content > .ant-collapse-content-box,
.date-created-row .ant-collapse-content > .ant-collapse-content-box {
    padding: 15px 0;
}

.type-working-checkbox .ant-select {
    margin-top: 10px;
}

.type-working-checkbox .ant-select-selector {
    border: 1px solid var(--Gray-5-color);
    border-radius: 4px;
}

.date-created-row .ant-collapse-content {
    border: 0;
}

.footer-row-main-fix {
    border-top: 1px solid var(--Gray-5-color);
    padding: 15px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px;
}

.footer-row-main-fix .ant-btn {
    color: var(--Gray-3-color);
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    border-color: transparent;
    background: transparent;
    height: 29px;
    border-radius: 4px;
}

.footer-row-main-fix .ant-btn.ant-btn-primary {
    text-transform: uppercase;
    color: var(--white-color);
    background: var(--Gray-2-color);
    border-color: var(--Gray-2-color);
}

.footer-row-main-fix .ant-btn.ant-btn-primary:hover {
    background: var(--Green-1-color);
    border-color: var(--Green-1-color);
}

.opportunities-popover-main-all.popover-main-all .ant-popover-inner {
    width: 245px;
    padding-top: 30px;
    min-height: 327px;
}

.filter-popover.popover-main-all .ant-popover-inner {
    width: 350px;
    padding-top: 30px;
    min-height: 238px;
}

.filter-popover .type-working-checkbox {
    padding: 0 15px;
}

.opportunities-popover-main-all.popover-main-all.ant-popover-placement-bottom
> .ant-popover-content
> .ant-popover-arrow {
    left: 50%;
}

.popover-main-all.filter-popover.ant-popover-placement-bottom
> .ant-popover-content
> .ant-popover-arrow {
    left: 50%;
}

.ant-btn.filter-btn:hover,
.ant-btn.filter-btn.ant-popover-open {
    background: var(--white-color);
    border-color: var(--white-color);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

/*--create-opportunity-css--*/

.create-opportunity-row {
    padding: 75px 45px 75px 9.4%;
}

.create-opportunity-row.line-items-row-view {
    padding-left: 2.4%;
}

.create-opportunity-row .ant-collapse {
    background: var(--white-color);
    border: 0;
}

.create-opportunity-row .ant-collapse > .ant-collapse-item {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 30px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgb(0 0 0 / 5%), 0 8px 16px rgb(0 0 0 / 3%);
}

.create-opportunity-row .ant-collapse-content {
    border: 0;
}

.create-opportunity-row .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.create-opportunity-row
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow,
.summary-info-inner-row
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    color: var(--Gray-3-color);
    font-size: 16px;
    left: 10px;
    position: absolute;
    top: 20px;
    margin: 0;
    padding: 0;
}

.common-form-card-row {
    padding: 20px 45px;
}

.common-form-card-row.common-form-card-row-line-items {
    padding: 20px 0 0;
}

.line-items-inner-row-pd {
    padding: 0 30px 20px;
}

.common-form-card-row.common-dry-card-row {
    padding: 20px 30px;
}

.info-card-heading-row h5 {
    color: var(--Gray-3-color);
    transition: 0.3s ease all;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-transform: capitalize;
}

.create-opportunity-row .ant-collapse-header:hover .info-card-heading-row h5,
.create-opportunity-row .ant-collapse > .ant-collapse-item.ant-collapse-item-active .info-card-heading-row h5 {
    color: var(--Green-1-color);
}

.create-opportunity-row .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    color: var(--Gray-2-color);
}

.info-card-heading-row .ant-btn {
    color: var(--Gray-4-color);
    letter-spacing: 0.004em;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    box-shadow: none;
    height: 25px;
}

.ant-form.main-inner-form .ant-form-item {
    margin-bottom: 20px;
}

.ant-form.main-inner-form .ant-form-item-label {
    padding-bottom: 5px;
}

.ant-form.main-inner-form .ant-form-item-label label {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
    width: 100%;
}

.ant-form.main-inner-form .ant-select-multiple .ant-select-selector,
.ant-form.main-inner-form .ant-input,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector,
.ant-form.main-inner-form .ant-input-number,
.ant-form.main-inner-form .ant-picker,
.main-inner-form .ant-input-group-compact .ant-input,
.main-inner-form .ant-input-group-compact .ant-select-selector {
    height: 40px;
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    width: 100%;
}

.ant-form.main-inner-form .ant-input:hover,
.ant-form.main-inner-form .ant-select-multiple .ant-select-selector:hover,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector:hover,
.ant-form.main-inner-form .ant-input-number:hover,
.ant-form.main-inner-form .ant-picker:hover,
.ant-form.main-inner-form .ant-input:focus,
.ant-form.main-inner-form .ant-select-multiple .ant-select-selector:focus,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector:focus,
.ant-form.main-inner-form .ant-input-number:focus,
.ant-form.main-inner-form .ant-picker:focus {
    border-color: var(--Green-1-color);
    box-shadow: none;
}

.ant-form.main-inner-form .ant-input.ant-input-disabled,
.ant-form.main-inner-form
.ant-select-multiple.ant-select-disabled
.ant-select-selector,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input).ant-select-disabled
.ant-select-selector,
.ant-form.main-inner-form .ant-input-number.ant-input-number-disabled,
.ant-form.main-inner-form .ant-picker.ant-picker-disabled,
.ant-form.main-inner-form .ant-input.ant-input-disabled:hover,
.ant-form.main-inner-form
.ant-select-multiple.ant-select-disabled
.ant-select-selector:hover,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input).ant-select-disabled
.ant-select-selector:hover,
.ant-form.main-inner-form .ant-input-number.ant-input-number-disabled:hover,
.ant-form.main-inner-form .ant-picker.ant-picker-disabled:hover,
.ant-form.main-inner-form .ant-input.ant-input-disabled:focus,
.ant-form.main-inner-form
.ant-select-multiple.ant-select-disabled
.ant-select-selector:focus,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input).ant-select-disabled
.ant-select-selector:focus,
.ant-form.main-inner-form .ant-input-number.ant-input-number-disabled:focus,
.ant-form.main-inner-form .ant-picker.ant-picker-disabled:focus,
.main-inner-form .ant-input-group-compact .ant-input.ant-input-disabled,
.main-inner-form .ant-input-group-compact .ant-input.ant-input-disabled:focus,
.main-inner-form .ant-input-group-compact .ant-input.ant-input-disabled:hover,
.main-inner-form
.ant-input-group-compact
.ant-select-disabled
.ant-select-selector,
.main-inner-form
.ant-input-group-compact
.ant-select-disabled
.ant-select-selector:focus,
.main-inner-form
.ant-input-group-compact
.ant-select-disabled
.ant-select-selector:hover {
    background: var(--Gray-6-color);
    border-color: var(--Gray-5-color);
}

.ant-form.main-inner-form
.ant-input-number.ant-input-number-disabled:focus
.ant-input-number-handler-wrap,
.ant-form.main-inner-form
.ant-input-number.ant-input-number-disabled:hover
.ant-input-number-handler-wrap {
    opacity: 0;
}

.ant-form.main-inner-form .ant-input:-webkit-autofill,
.ant-form.main-inner-form
.ant-select-multiple
.ant-select-selector:-webkit-autofill,
.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector:-webkit-autofill,
.ant-form.main-inner-form .ant-input-number:-webkit-autofill,
.ant-form.main-inner-form .ant-picker:-webkit-autofill {
    background: #f7fefc;
    border: 1px solid var(--Gray-5-color);
}

.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input,
.ant-form.main-inner-form .ant-input-number-input {
    height: 30px;
}

.ant-form.main-inner-form .ant-input-number-handler-wrap {
    display: none;
}

.ant-form.main-inner-form .number-arrow-none .ant-input-number-handler-wrap {
    display: none;
}

.ant-form.main-inner-form .ant-input::-webkit-input-placeholder,
.ant-form.main-inner-form .ant-select-selection-placeholder {
    color: var(--Gray-4-color);
}

.main-inner-form .ant-select-selection-item {
    color: var(--Gray-2-color);
}

.ant-form.main-inner-form
.ant-select-single
.ant-select-selector
.ant-select-selection-item,
.main-inner-form
.ant-select-single
.ant-select-selector
.ant-select-selection-item,
.main-inner-form
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder,
.ant-form.main-inner-form
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder {
    padding: 0;
    line-height: 40px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-form.main-inner-form
.editalble-form-data
.ant-select-single
.ant-select-selector
.ant-select-selection-item {
    line-height: 57px;
}

.ant-form.main-inner-form .text-area-main.ant-input {
    height: 85px;
}

.main-drawer-div
.ant-form.main-inner-form
.text-area-task.text-area-main.ant-input {
    height: 103px;
}

.ant-form.main-inner-form .ant-select-multiple .ant-select-selector {
    padding-left: 35px;
}

.ant-form.main-inner-form .ant-select-selection-placeholder {
    left: 35px;
}

.ant-btn.search-icon {
    left: 20px;
    top: 42px;
}

.ant-form.main-inner-form .ant-upload.ant-upload-select {
    width: 100%;
}

.upload-file-btn.ant-btn {
    background: var(--bg-f8-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    height: 100px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: var(--Gray-2-color);
}

.upload-file-btn.ant-btn:hover {
    background: var(--bg-f8-color);
}

.upload-file-btn.ant-btn span {
    color: #3391ff;
}

.bullet-point-row {
    padding: 30px;
}

.upload-file-btn.ant-btn img {
    margin-right: 10px;
}

.common-form-btn-row {
    margin-top: 30px;
    margin-bottom: 15px;
}

.common-form-btn-row .ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #aeaeb2;
    letter-spacing: -0.24px;
    border: 0;
    background: transparent;
    height: 44px;
    box-shadow: none;
}

.common-form-btn-row .ant-btn.ant-btn-primary,
.steps-action.common-steps-action .ant-btn.ant-btn-primary {
    color: var(--white-color);
    background: var(--Green-1-color);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
    0 3px 1px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    width: 228px;
}

.common-form-btn-row .ant-btn.ant-btn-primary:hover,
.steps-action.common-steps-action .ant-btn.ant-btn-primary:hover {
    background: #20cb97;
}

.common-form-btn-row .ant-btn.ant-btn-primary[disabled],
.steps-action.common-steps-action .ant-btn.ant-btn-primary:disabled {
    background: var(--Green-2-color);
}

.common-form-btn-row .ant-btn.ant-btn-primary:focus,
.steps-action.common-steps-action .ant-btn.ant-btn-primary:focus {
    background: #3f4e52;
    border: 3px solid rgba(51, 51, 51, 0.1);
}

.contact-details-col {
    margin-bottom: 15px;
}

.contact-name-info-row {
    height: 67px;
    border-bottom: 1px solid var(--Gray-5-color);
}

.branch-icon-text img {
    margin-right: 10px;
}

.account-name-card.contact-name-info-row {
    border: 1px solid var(--Gray-5-color);
    height: auto;
    border-radius: 6px;
    padding: 20px 15px;
}

.contact-info-icon {
    width: 32px;
}

.contact-info-details {
    width: calc(100% - 32px);
    padding-right: 15px;
}

.contacts-div-inner {
    margin-top: 20px;
}

.ant-form.main-inner-form .contacts-div-inner .ant-form-item {
    margin-bottom: 0;
}

.contact-info-details h5,
.contact-info-details h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
}

.datePicker-calendar input {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
    border: 0;
    border-radius: 0;
}

.datePicker-calendar.ant-picker {
    border: 0;
}

.datePicker-calendar .ant-picker-suffix {
    color: #64bd95;
}

.contact-info-details h5 {
    color: var(--Gray-2-color);
    margin-bottom: 5px;
}

.contact-info-details h6 {
    color: var(--Gray-4-color);
}

.close-btn-contact.ant-btn {
    right: 0;
    top: 10px;
    padding: 2px 10px;
}

.select-inner-add-item {
    flex-flow: nowrap;
    padding: 8px;
}

.add-select-drop-btn.ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.small-heading-main {
    margin: 10px 0;
}

.small-heading-main h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
}

.ant-modal.main-all-form-modal .ant-modal-content {
    border-radius: 6px;
    overflow: hidden;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-close {
    display: none;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-close .anticon {
    font-size: 20px;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-close-x {
    height: 67px;
    line-height: 67px;
}

.ant-modal.main-all-form-modal.design-update-modal
.ant-modal-content
.ant-modal-close {
    display: block;
    color: var(--white-color);
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-header,
.main-drawer-div.main-all-form-modal .ant-drawer-header {
    background: #f6f6f6;
    height: 68px;
    padding: 15px 30px;
    display: flex;
    z-index: 99;
    align-items: center;
}

.ant-modal.main-all-form-modal.bg-gray-main
.ant-modal-content
.ant-modal-header,
.main-drawer-div.main-all-form-modal .ant-drawer-header {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
}

.main-all-form-modal.bg-gray-main .ant-drawer-body {
    background: #f6f6f6;
}

.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-header
.ant-modal-title,
.main-all-form-modal .ant-drawer-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    position: relative;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-header:before,
.main-drawer-div.main-all-form-modal .ant-drawer-header:before {
    content: "";
    position: absolute;
    left: 30px;
    bottom: 0;
    width: 67px;
    height: 2px;
    background: var(--Green-2-color);
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-body {
    padding: 20px;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-footer,
.main-drawer-div .ant-drawer-footer {
    padding: 15px 30px;
}

.ant-modal.main-all-form-modal .ant-modal-content .ant-modal-footer .ant-btn,
.main-drawer-div .ant-drawer-footer .ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #aeaeb2;
    background: transparent;
    border: 0;
    height: 44px;
    box-shadow: none;
}

.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-footer
.ant-btn.ant-btn-primary,
.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary {
    color: var(--white-color);
    background: var(--Gray-2-color);
    border-radius: 4px;
}

.main-drawer-div .ant-drawer-footer .ant-btn.apply-filter-update {
    height: 32px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
}

.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary.apply-filter-update {
    background: #3F4E52;
    border: 1px solid #30403F;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-footer
.ant-btn.ant-btn-primary[disabled],
.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary[disabled],
.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary[disabled]:hover {
    opacity: 0.5;
    background: var(--Gray-2-color);
}

.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-footer
.ant-btn.ant-btn-primary.active,
.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-footer
.ant-btn.ant-btn-primary:hover,
.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary:hover,
.main-drawer-div .ant-drawer-footer .ant-btn.ant-btn-primary.active {
    background: var(--Green-1-color);
}

.main-drawer-div .ant-drawer-close {
    padding: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 18px;
}

.opportunities-details-select-save-div {
    background: var(--Gray-6-color);
    position: fixed;
    right: -100%;
    top: 18.1%;
    margin: auto;
    z-index: 9;
    transition: 0.3s ease all;
    width: 100%;
    padding: 11px 45px;
    height: 160px;
}

.main-content-div.pull-push-add .opportunities-details-select-save-div {
    right: 0;
}

.opportunities-checkbox-div {
    width: 40%;
}

.save-value-div {
    width: 60%;
}

.save-value-div .ant-btn {
    width: 83px;
    height: 36px;
    background: var(--primary-color);
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--white-color);
}

.common-checkbox-div .ant-checkbox-wrapper {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #000000;
    opacity: 0.5;
}

.common-checkbox-div .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    opacity: 1;
}

.common-checkbox-div .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border: 1px solid #8e8e93;
    border-radius: 4px;
    background: transparent;
}

.common-checkbox-div .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid var(--Green-1-color);
    border-top: 0;
    border-left: 0;
}

.common-checkbox-div .ant-checkbox-checked::after {
    border: 1px solid #8e8e93;
    border-radius: 4px;
}

.common-checkbox-div .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.common-checkbox-div .ant-checkbox:hover .ant-checkbox-inner,
.common-checkbox-div .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #8e8e93;
}

/*--carpet-cleaning-main-css--*/

.carpet-cleaning-main-row {
    padding: 0 45px 30px;
    background: var(--white-color);
}

.ant-tabs.carpet-cleaning-main-common-tab {
    width: 100%;
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-nav {
    position: fixed;
    right: 0;
    width: calc(100% - 250px);
    background: var(--white-color);
    z-index: 99;
    padding: 0 45px;
    transition: 0.3s ease all;
}

.small-sidebar .ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-nav {
    width: calc(100% - 83px);
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-nav:before {
    display: none;
}

.small-sidebar .dispatch-header-fixed {
    width: calc(100% - 83px);
}

.small-sidebar .dispatch-main-left {
    left: 83px;
}

.dispatch-work-details-row-main {
    align-items: flex-start;
}

.small-sidebar .ant-tabs.carpet-cleaning-main-common-tab.common-tab-dispatch .ant-tabs-nav {
    width: calc(100% - 511px);
}

.ant-tabs.carpet-cleaning-main-common-tab
.ant-tabs-nav
.ant-tabs-nav-wrap
.ant-tabs-nav-list {
    justify-content: flex-start;
    /* width: 100%; */
    width: 90%;
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    color: var(--Gray-4-color);
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-tab {
    margin: 0;
    padding: 12px;
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-tab:hover {
    background: var(--daf2eb-color);
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--Gray-6-color);
}

.ant-tabs.carpet-cleaning-main-common-tab
.ant-tabs-tab.ant-tabs-tab-active
.ant-tabs-tab-btn,
.ant-tabs.carpet-cleaning-main-common-tab
.ant-tabs-tab:hover
.ant-tabs-tab-btn {
    color: var(--Gray-2-color);
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-ink-bar {
    top: 0;
    bottom: auto;
    background: var(--Green-2-color);
    display: none;
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-content-holder {
    padding-top: 60px;
}

.carpet-cleaning-mini-header {
    margin-bottom: 15px;
}

.count-div-mini {
    margin-right: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.main-table-all.carpet-cleaning-table {
    border: 1px solid var(--Gray-5-color);
    border-radius: 8px;
    overflow: hidden;
}

.main-table-all.carpet-cleaning-table table thead tr th {
    min-width: 165px;
}

.main-table-all.carpet-cleaning-table table thead tr th:nth-child(1) {
    min-width: 300px;
}

.main-table-all.carpet-cleaning-table table thead tr th:nth-child(3),
.main-table-all.carpet-cleaning-table table tbody tr td:nth-child(3) {
    text-align: left;
}

.main-table-all.carpet-cleaning-table table thead tr th:nth-child(4) {
    padding-left: 15px !important;
}

.carpet-cleaning-mini-header h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.018em;
    margin-right: 10px;
    color: var(--Gray-2-color);
    margin-top: 15px;
}

.carpet-cleaning-mini-header h6 span {
    color: var(--Gray-4-color);
}

.main-table-all.carpet-cleaning-table.activity-info-table .ant-table-thead {
    display: none;
}

.main-table-all.activity-info-table
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td {
    border-bottom: 0.031em solid var(--Gray-6-color);
}

.main-table-all.activity-info-table table tbody tr:nth-child(odd) td {
    background: var(--white-color);
}

.name-id-details .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    border: 0.094em solid var(--bdbdbd-color);
}

.name-id-details .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.name-id-details .ant-checkbox:hover .ant-checkbox-inner,
.name-id-details .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--Green-1-color);
}

.name-id-details .ant-checkbox-wrapper {
    color: var(--Gray-2-color);
    opacity: 0.9;
}

.name-id-details .ant-checkbox {
    top: 0.5em;
}

.name-id-details .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: var(--Green-1-color);
}

/*.summary-header-details .ant-upload-list{*/
/*    display: none;*/
/*}*/
.name-id-details .ant-checkbox-inner::after {
    left: 24%;
    opacity: 1;
    border: 2px solid var(--bdbdbd-color);
}

.name-id-details .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--Green-1-color);
    border-color: var(--Green-1-color);
}

.name-id-details .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid var(--white-color);
    border-top: 0;
    border-left: 0;
}

.assign-tag-card {
    width: 30px;
    height: 30px;
    background: var(--Green-2-color);
    color: var(--white-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004em;
    margin-right: 5px;
}

.main-table-all.carpet-cleaning-table.notes-info-table
table
thead
tr
th:nth-child(1) {
    min-width: 165px;
}

.main-table-all.carpet-cleaning-table.notes-info-table
table
thead
tr
th:nth-child(2) {
    min-width: 300px;
}

.main-table-all.carpet-cleaning-table.notes-info-table table tbody tr td a {
    color: var(--Gray-2-color);
    font-weight: 600;
}

.notes-div-main {
    display: block;
    width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-data-upload-screens {
    background: var(--white-color);
    height: 105px;
    align-items: center;
}

.no-data-upload-screens h6, .no-data-wrapper h6 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: var(--Gray-2-color);
}

.no-data-upload-screens h6 {
    color: var(--Green-1-color);
}

.ant-btn.add-btn-collapse {
    background: #3f4e52;
    border-color: #3f4e52;
    height: 32px;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    line-height: 15px;
    color: var(--white-color);
    margin-left: 15px;
}

.ant-btn.add-btn-collapse:hover,
.carpet-cleaning-main-common-tab .ant-upload .ant-btn:hover {
    background: #222c2f;
    border-color: #222c2f;
}

.design-update-collapse .ant-collapse {
    border-bottom: 1px solid #d9d9d9;
}

.ant-btn.add-btn-collapse:focus {
    background: #38bc94;
    border: 3px solid rgba(51, 51, 51, 0.1);
}

/*--confirm-modal--*/

.confirmation-popup-modal.ant-modal {
    width: 598px !important;
}

.ant-modal.dispatch-assign-variant-modal .ant-modal-content {
    border-radius: 0;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.ant-modal.dispatch-assign-variant-modal .ant-modal-header {
    border-radius: 0;
    padding: 20px 30px;
    background: var(--Gray-3-color);
}

.dispatch-assign-variant-modal .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-modal.dispatch-assign-variant-modal .ant-modal-body {
    overflow: auto;
}

.dispatch-assign-variant-modal .main-table-div {
    overflow: auto;
    height: 360px;
}

.dispatch-assign-variant-modal .ant-modal-close {
    right: 15px;
}

.dispatch-assign-variant-modal .ant-modal-close-x {
    font-size: 22px;
    color: var(--white-color);
}

.dispatch-assign-variant-modal .ant-modal-title {
    color: var(--white-color);
    font-size: 18px;
    font-weight: 600;
}

.ant-modal.dispatch-assign-variant-modal .ant-modal-footer {
    border-radius: 0;
    padding: 20px 30px;
}

.dispatch-assign-variant-modal .ant-modal-footer .ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #aeaeb2;
    background: transparent;
    border: 0;
    height: 44px;
    box-shadow: none;
    padding: 0 20px;
}

.dispatch-assign-variant-modal .ant-modal-footer .ant-btn.ant-btn-primary {
    color: var(--white-color);
    background: var(--Gray-2-color);
    border-radius: 4px;
}

.dispatch-assign-variant-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {
    background: var(--Green-1-color);
}

.confirmation-popup-modal.ant-modal.sites-drawer {
    width: 611px !important;
}

.confirmation-popup-modal.ant-modal .ant-modal-content {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
}

.confirmation-popup-modal.ant-modal .ant-modal-content .ant-modal-close {
    display: none;
}

.confirmation-popup-modal.ant-modal .ant-modal-body {
    padding: 45px 30px 30px;
}

.confirmation-popup-modal.ant-modal.account-type-all .ant-modal-body {
    padding: 45px 63px 30px;
}

.confirm-modal-row h5 {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.021em;
    color: var(--Gray-2-color);
    margin-top: 15px;
}

.account-type-all .confirm-modal-row h5 {
    font-size: 22px;
    line-height: 28px;
}

.confirm-modal-row p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: var(--Gray-3-color);
}

.confirm-modal-row p .ant-btn:hover {
    color: var(--Gray-1-color);
}

.warning-modal-row.confirm-modal-row p {
    width: 85%;
}

.warning-modal-row.confirm-modal-row.service-modal p {
    width: 100%;
}

.confirmation-popup-modal.ant-modal .ant-modal-footer {
    padding: 0;
    border: 0;
}

.confirmation-popup-modal.ant-modal .ant-modal-footer .ant-btn {
    height: 50px;
    background: var(--Gray-6-color);
    width: 50%;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-4-color);
    border: 0;
    margin: 0;
    border-radius: 0;
}

.success-new.confirmation-popup-modal.ant-modal .ant-modal-footer .ant-btn,
.warning-new.confirmation-popup-modal.ant-modal .ant-modal-footer .ant-btn {
    background: #3f4e52;
    color: var(--white-color);
}

.success-new.confirmation-popup-modal.ant-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    background: var(--Green-1-color);
}

.success-new.confirmation-popup-modal.ant-modal
.ant-modal-footer
.ant-btn.ant-btn-danger {
    background: var(--red);
}

.confirmation-popup-modal.ant-modal.success-modal .ant-modal-footer .ant-btn {
    display: none;
}

.confirmation-popup-modal.ant-modal.account-type-all .ant-modal-footer {
    display: none;
}

.confirmation-popup-modal.ant-modal.success-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    display: block;
    width: 100%;
}

.confirmation-popup-modal.ant-modal.warning-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    background: var(--primary-color);
}

.confirmation-popup-modal.ant-modal.warning-modal
.ant-modal-footer
.ant-btn.ant-btn-primary:hover {
    background: var(--Green-1-color);
}

.confirmation-popup-modal.ant-modal .ant-modal-footer .ant-btn.ant-btn-primary {
    background: #38BC94;
    /*background: var(--Green-1-color);*/
    color: var(--white-color);
}

.confirmation-popup-modal.customer-owner-confirm.ant-modal
.ant-modal-footer
.ant-btn {
    background-color: var(--Green-1-color);
    color: var(--white-color);
}

.confirmation-popup-modal.customer-owner-confirm.ant-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    background-color: var(--primary-color);
}

.confirmation-popup-modal.ant-modal.customer-owner-confirm
.ant-modal-footer
.ant-btn.ant-btn-primary:hover {
    background-color: var(--primary-color);
}

.customer-owner-confirm p {
    width: 117% !important;
}

.siteowner-row {
    margin-top: -8px !important;
}

.nonActiveRole {
    color: var(--Gray-4-color) !important;
}

.nonActiveRole:hover {
    border-bottom: 1px solid var(--Green-1-color);
}

/*.confirmation-popup-modal.ant-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover{*/

/*    background: var(--Green-1-color);*/

/*}*/

/*--tabs-css-summary--*/

.summary-info-row-main .summary-info-status-green-line-main,
.summary-info-inner-row .summary-info-status-green-line-main {
    position: fixed;
    right: 0;
    width: calc(100% - 250px);
    padding: 50px 45px 20px;
    z-index: 8;
    top: 115px;
    background: var(--white-color);
    transition: 0.3s ease all;
}

.small-sidebar .summary-info-row-main .summary-info-status-green-line-main,
.small-sidebar .summary-info-inner-row .summary-info-status-green-line-main {
    width: calc(100% - 83px);
}

.summary-info-row-main .summary-info-status-green-line-main.bar-scroll {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100% - 290px);
    padding: 50px 10px 20px;
    transition: 0.3s ease all;
}

.small-sidebar
.summary-info-row-main
.summary-info-status-green-line-main.bar-scroll {
    width: calc(100% - 122px);
}

.summary-info-row-main .bar-scroll .summary-line-main {
    width: auto;
    min-width: 70px;
    text-align: center;
    flex-shrink: 0;
    padding: 2px 15px 2px 20px !important;
    margin-right: 14px;
}

.summary-info-status-green-line-main:last-child .summary-line-main:last-child {
    margin: 0;
}


.summary-info-row-main .bar-scroll .summary-line-main:before {
    margin: auto;
    border: 14px solid;
    border-color: transparent transparent transparent var(--Gray-5-color);
    right: -28px;
}

.summary-info-row-main .summary-line-main {
    width: 15.6%;
    background: var(--Gray-5-color);
    z-index: 0;
    margin-right: 13px;
    font-weight: 600;
    font-size: 11px;
    line-height: initial;
    letter-spacing: 0.004em;
    color: var(--Gray-3-color);
    max-height: 26px;
    min-height: 26px;
}

.summary-info-row-main .summary-line-main:nth-child(1) {
    border-radius: 25px 0 0 25px;
}

.summary-line-template-main:before {
    display: none !important;
}

.summary-line-template-main {
    margin-left: 2rem !important;
    width: 96% !important;
}

.summary-line-template-main:nth-child(1) {
    border-radius: 25px !important;
    background-color: #fcebdb;
    color: #f2994a;
}
/* .summary-info-row-main .summary-line-main:nth-child(5) {
    border-radius: 0 25px 25px 0;
} */

.summary-info-row-main .summary-line-main:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 13px solid;
    border-color: transparent transparent transparent var(--Gray-5-color);
    transform: rotate(0deg);
    z-index: 99;
    right: -26px;
}

.summary-info-row-main .summary-line-main:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 12px solid;
    border-color: transparent transparent transparent var(--white-color);
    transform: rotate(0deg);
    z-index: -1;
    margin: auto;
}

/* .summary-info-row-main .summary-line-main:nth-child(5):before, */
.summary-info-row-main .summary-line-main:nth-child(1):after {
    visibility: hidden;
}

.summary-info-row-main .summary-line-main.active {
    background: #3f4e52;
    color: var(--white-color);
}

.summary-info-row-main .summary-line-main.finish {
    background: var(--Green-1-color);
    color: white;
    font-size: larger;
}
.summary-info-row-main.summary-info-row-main-invoice .summary-line-main{
    width: 19%;
}
.summary-info-row-main-invoice.summary-info-row-main .summary-line-main.finish{
    font-size: 11px;
}

.summary-info-row-main .summary-line-main.active:before {
    border-color: transparent transparent transparent #3f4e52;
}

.summary-info-row-main .summary-line-main.finish:before {
    border-color: transparent transparent transparent var(--Green-1-color);
}

.steps-main-div-inn {
    min-height: 470px;
    margin: 90px 0 45px;
}

.summary-info-inner-row {
    margin-top: 0;
}

.summary-info-inner-row .ant-collapse {
    background: var(--white-color);
    border: 0;
}

.bg-gray-main .summary-info-inner-row .ant-collapse {
    background: transparent;
}

.summary-info-inner-row .ant-collapse > .ant-collapse-item {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 8px;
    margin-bottom: 30px;
    overflow: hidden;
    box-shadow: 0 2px 3px rgb(0 0 0 / 5%), 0 8px 16px rgb(0 0 0 / 3%);
}

.summary-info-inner-row
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.018em;
    padding-left: 30px;
}

.create-opportunity-row .ant-collapse-item.ant-collapse-item-active .info-card-heading-row span {
    color: var(--Gray-2-color)
}

.summary-info-inner-row .ant-collapse > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    color: var(--Gray-2-color)
}

/* .create-opportunity-row .ant-collapse-item.ant-collapse-item-active .info-card-heading-row span {
    color: var(--Gray-3-color)
} */

.ant-btn.edit-btn-summary {
    border: 1px solid var(--Gray-5-color);
    border-radius: 4px;
    background: var(--white-color);
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--black-color);
    letter-spacing: 0.031em;
    text-transform: capitalize;
    text-align: left;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.ant-btn.edit-btn-summary img {
    margin-right: 5px;
}

.opportunity_info-collapse {
    width: 100%;
}

.opportunity_info-collapse span {
    display: flex;
    align-items: center;
}

.opportunity_info-collapse span aside {
    color: var(--Gray-4-color);
    margin-left: 5px;
}

.summary-info-inner-row .ant-collapse-content {
    border: 0;
}

.summary-info-inner-row .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 8px 8px;
}

.summary-collapse-inner-row-main h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #a9a7a7;
}

.no-data-upload-screens img {
    height: 24px;
    width: 24px;
}

.summary-collapse-inner-row-main h5 {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: var(--Gray-1-color);
}

.summary-info-inner-row .ant-collapse-content > .ant-collapse-content-box,
.proposal-update-table .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.summary-collapse-inner-row-main {
    padding: 15px 25px 30px;
}

.summary-collapse-inner-row-main-scroll {
    height: 250px;
    overflow: auto;
}

.summary-collapse-inner-row-main ul li {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: var(--Gray-1-color);
    width: 35%;
    padding: 3px 0;
}

.summary-collapse-inner-row-main ul li.gray-color-li {
    color: var(--Gray-3-color);
    width: 60%;
}

.mb-15-bt {
    margin-bottom: 15px;
}

.status-small-select.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    background: var(--white-color);
    border: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.165em;
    height: 26px;
    padding: 0 8px;
}

.status-small-select .ant-select-selection-item {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 26px !important;
}

.status-small-select.ant-select-single.ant-select-show-arrow
.ant-select-selection-search {
    right: 10px;
}

.status-small-select.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    height: 26px;
}

.status-small-select.ant-select-single.ant-select-show-arrow
.ant-select-selection-placeholder {
    padding-right: 10px;
    line-height: 26px;
    font-weight: 500;
    font-size: 9px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    color: var(--Gray-1-color);
}

.summary-info-header-inner-row {
    margin-bottom: 30px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 8px;
    min-height: 154px;
}

.carpet-cleaning-mini-header.summary-header-details {
    margin-bottom: 0;
    background: #f9f9f9;
    border-radius: 8px 8px 0 0;
    min-height: 49px;
    padding: 0 15px;
}

.carpet-cleaning-mini-header.summary-header-details.search-view-header-in-collapse {
    border-top: 1px solid var(--Gray-5-color);
    border-radius: 0;
}

.carpet-cleaning-mini-header.summary-header-details.search-view-header-in-collapse.account-tabs-min {
    border: 1px solid var(--Gray-5-color);
}

.opportunity_info-collapse.account-summary-header {
    margin: 15px 0;
}

.opportunity_info-collapse.account-summary-header span {
    font-weight: 500;
    /*font-size: 20px;*/
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.018rem;
    color: var(--Gray-2-color);
}

.ant-btn.view-all-btn {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004em;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 0.165em;
    color: var(--black-color);
    margin-left: 15px;
}

.ant-btn.view-all-btn:hover {
    background: var(--daf2eb-color);
    border-color: var(--Green-2-color);
}

.account-contact-card {
    border-right: 1px solid var(--Gray-6-color);
    padding: 18px 20px;
    width: 33.33%;
}

.info-icon-card-flag {
    width: 50px;
}

.info-icon-card-details {
    width: calc(100% - 50px);
}

.info-icon-card-details h5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--Gray-1-color);
}

.info-icon-card-details h5 span {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    color: var(--Green-1-color);
    margin-left: 10px;
}

.info-icon-card-details p {
    font-size: 15px;
    line-height: 20px;
    color: var(--Gray-3-color);
    margin: 5px 0 0;
    padding-right: 50%;
}

.info-icon-card-details p a {
    color: var(--Gray-3-color);
    font-weight: 600;
    margin-left: 10px;
}

.ant-btn.contact-btn-small {
    height: 32px;
    width: 68px;
    background: rgba(242, 242, 242, 0.5);
    border-radius: 0.165em;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.004em;
    color: var(--black-color);
    right: 5px;
    bottom: 5px;
}

.summary-header-details .new-opportunity-btn {
    width: auto;
    margin-left: 10px;
}

.span-tg-summary {
    width: 62%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.main-drawer-div .ant-form.main-inner-form .text-area-main.ant-input {
    height: 500px;
    padding: 15px;
}

.main-drawer-div .ant-drawer-header {
    border: 0;
    padding: 15px 30px;
}

.create-note-div .create-note-heading-drawer {
    margin-left: 5px;
    background: var(--daf2eb-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.018rem;
    color: var(--Gray-1-color);
    opacity: 0.8;
    padding: 5px;
}

.ant-btn.delete-btn-drawer {
    margin-right: 30px;
}

.add-contact-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.031rem;
    text-transform: capitalize;
    color: var(--black-color);
    height: 32px;
    background: #f6f6f6;
    border: 1px solid #30403f;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left: 15px;
}

.account-contact-card.view-contact-row {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 20px;
}

.ant-dropdown.create-dropdown-main.remove-dropdown {
    min-width: 120px !important;
}

.opportunity-db-table .main-table-all table tbody tr td,
.main-table-all.carpet-cleaning-table table tbody tr td,
.post-table .main-table-all tbody tr td {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

.main-table-all .ant-table.ant-table-middle .ant-table-tbody > tr.ant-table-placeholder:hover > td.ant-table-cell {
    border: transparent;
}

.opportunity-db-table .main-table-all table tbody tr td:first-child,
.main-table-all.carpet-cleaning-table table tbody tr td:first-child,
.post-table .main-table-all tbody tr td:first-child {
    border-left: 2px solid transparent;
    border-radius: 4px 0 0 4px;
}

.opportunity-db-table .main-table-all table tbody tr td:last-child,
.main-table-all.carpet-cleaning-table table tbody tr td:last-child,
.post-table .main-table-all tbody tr td:last-child {
    border-right: 2px solid transparent;
    border-radius: 0 4px 4px 0;
}

.account-table-one-td.opportunity-db-table .main-table-all table tbody tr td:last-child {
    border-radius: 4px;
}

.opportunity-db-table .main-table-all table tbody tr.ant-table-row:hover td,
.post-table .main-table-all tbody tr:hover td {
    border-top-color: var(--Green-2-color);
    border-bottom-color: var(--Green-2-color);
    background: var(--white-color);
}

/*.opportunity-db-table .main-table-all table tbody tr.ant-table-row.is-required:hover td,*/
/*.opportunity-db-table .main-table-all table tbody tr.ant-table-row.is-required:focus td {*/
/*    border-top-color: #EB5757;*/
/*    border-bottom-color:#EB5757;*/
/*}*/

.opportunity-db-table .main-table-all table tbody tr.ant-table-row:focus td,
.post-table .main-table-all tbody tr:focus td {
    border-top-color: var(--Green-2-color);
    border-bottom-color: var(--Green-2-color);
    background: var(--daf2eb-color);
}

/*.opportunity-db-table .main-table-all table tbody tr.ant-table-row:focus td,*/
/*.summary-info-inner-row .main-table-all tbody tr td{*/
/*    border-top-color: var(--Green-2-color);*/
/*    border-bottom-color: var(--Green-2-color);*/
/*    background: var(--daf2eb-color);*/
/*}*/

.opportunity-db-table .main-table-all table tbody tr.ant-table-row:hover td:first-child,
.post-table .main-table-all tbody tr:hover td:first-child {
    border-left-color: var(--Green-2-color);
}

/*.opportunity-db-table .main-table-all table tbody tr.ant-table-row.is-required:hover td:first-child {*/
/*    border-left-color: #EB5757;*/
/*}*/

.opportunity-db-table .main-table-all table tbody tr.ant-table-row:hover td:last-child,
.post-table .main-table-all tbody tr:hover td:last-child {
    border-right-color: var(--Green-2-color);
}

.main-table-all .ant-table.ant-table-middle .ant-table-tbody > tr.post-opacity > td {
    background: #DAF2EB;
    border-color: #7FD4BA;
}

/*.opportunity-db-table .main-table-all table tbody tr.ant-table-row.is-required:hover td:last-child {*/
/*    border-right-color: #EB5757;*/
/*}*/

.main-table-all.carpet-cleaning-table table tbody tr.ant-table-row:hover td,
.main-table-all.activity-info-table
.ant-table.ant-table-middle
.ant-table-tbody
> tr.ant-table-row:hover
> td {
    background: var(--daf2eb-color);
}

.elipsis-remove-icon {
    opacity: 0;
}

.main-table-all.carpet-cleaning-table
table
tbody
tr.ant-table-row:hover
td
.elipsis-remove-icon {
    opacity: 1;
}

.ant-select-dropdown.select-month-day-select {
    background: #526368;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
    0 3px 1px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.select-month-day-select .ant-select-item {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
    color: var(--white-color);
    opacity: 0.7;
    height: 42px;
    display: flex;
    align-items: center;
}

.select-month-day-select .ant-select-item.active,
.select-month-day-select .ant-select-item:hover,
.select-month-day-select
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #3c4f55;
    opacity: 1;
}

.view-proposal-drawer-btn.ant-btn {
    color: var(--white-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    height: 36px;
    background: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.031rem;
    margin-right: 10px;
}

.inner-modal-main-row .search-bar-div .ant-form .ant-input {
    width: 160px;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
    outline: 0 !important;
}

.ant-table-wrapper.file-table-main .ant-table-thead {
    display: none;
}

.ant-table-wrapper.file-table-main .ant-table-tbody tr td {
    padding: 0;
    min-width: 35px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--Gray-3-color);
}

.ant-table-wrapper.file-table-main .ant-table-tbody tr td:nth-child(2) {
    min-width: 200px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-1-color);
}

.ant-table-wrapper.file-table-main .ant-table-tbody tr td:nth-child(3) {
    min-width: 70px;
}

.ant-table-wrapper.file-table-main .ant-table-tbody tr td:last-child {
    text-align: right;
}

.main-status-btn.gray-bg-btn {
    background: var(--Gray-6-color);
}

.main-status-btn.orange-bg-btn {
    background: rgba(242, 201, 76, 0.1);
}

.main-status-btn.red-bg-btn {
    background: rgba(227, 153, 153, 0.1);
}

.main-status-btn.primary-bg-btn {
    background: rgba(51, 145, 255, 0.05);
}

.main-status-btn.pur-po-al-btn {
    background: rgba(122, 127, 230, 0.1);
    color: #7a7fe6;
}

.main-status-btn.approved-bg-btn {
    background: rgba(56, 188, 148, 0.1);
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:nth-child(4) {
    text-align: center;
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr:nth-child(2)
> td {
    background: #fefcf6;
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr:nth-child(3)
> td {
    background: #fefafa;
}

.work-order-table.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr:nth-child(5)
> td {
    background: #f9fbfb;
}

.no-data-card-row {
    background: #F9F9F9;
    height: 100px;
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 20px;
}

.no-data-card-row.teams-update-no-teams-card {
    margin: 15px 0 0;
    height: 85px;
}

.no-data-card-row.no-data-card-row-2 {
    height: 140px;
}

.no-data-card-row > img {
    height: 24px;
    width: 24px;
}

.no-data-card-row h6, .add-site-blank-row h6, .common-card-upload h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: var(--Gray-2-color);
    text-transform: capitalize;
    margin-top: 3px;
    letter-spacing: 0.01em;
}

.validate-div-col {
    margin-top: 45px;
}

.validate-div-col.validate-div-col-2 {
    margin: 10px 0 30px;
}

.validate-div-col .ant-btn.validate-btn-main {
    color: var(--Green-1-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    background: var(--white-color);
    border-radius: 4px;
    height: 44px;
    border: 1px solid var(--Green-1-color);
    width: 228px;
    opacity: 0.5;
}

.validate-div-col .ant-btn.validate-btn-main:hover {
    background: var(--daf2eb-color);
    border: 1px solid #20cb97;
    opacity: 1;
}

.validate-div-col .ant-btn.validate-btn-main.active,
.validate-div-col .ant-btn.validate-btn-main:focus {
    opacity: 1;
    background: transparent;
    border: 1px solid var(--Green-1-color);
    color: var(--Green-1-color);
}

.ant-table-wrapper.documents-table {
    margin-bottom: 30px;
}

.ant-table-wrapper.documents-table table {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
}

.ant-table-wrapper.documents-table
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th {
    color: var(--Gray-1-color);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    background: transparent;
    padding: 2px 15px;
}

.ant-table-wrapper.documents-table
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td {
    border-color: var(--Gray-5-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: var(--black-color);
    padding: 15px;
}

.user-info-div-main {
    height: 100px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 20px 0;
    transition: 0.3s ease all;
}

.user-info-div-main.opportunity-info-div-main {
    min-height: 100px;
    height: auto;
    margin: 15px 0 0;
    padding: 5px 0;
    overflow: hidden;
}

.contact-col-12 {
    padding: 8px 15px 13px;
    min-height: 68px;
    display: flex;
    align-items: center;
}

.user-info-div-main.active,
.teams-view.user-info-div-main:hover {
    border-color: var(--Green-1-color);
    background: #f7fefc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
}

.ant-modal-body
.ant-form-item
.ant-select.search-and-select-tag
.ant-select-selector {
    padding-left: 15px;
}

.user-icons-div img {
    transition: 0.3s ease all;
}

.user-icons-div img.green-icon-emp {
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 0;
}

.user-info-div-main.active .user-icons-div img.black-icon-emp {
    opacity: 0;
}

.user-info-div-main.active .user-icons-div img.green-icon-emp {
    opacity: 1;
}

.user-info-div-main.opportunity-info-div-main.disabled {
    opacity: 0.5;
}

.opportunity-info-div-main .user-icons-div {
    width: 45px;
}

.user-icons-div span {
    width: 40px;
    height: 40px;
    background: var(--Green-2-color);
    color: var(--white-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
}

.opportunity-info-div-main .user-info-div {
    width: calc(100% - 45px);
}

.user-icons-div {
    width: 45px;
    float: left;
}

.user-info-div {
    width: calc(100% - 45px);
    float: left;
    padding: 4px 0 0 15px;
    position: relative;
}

.point-details {
    color: var(--Green-1-color);
    letter-spacing: 0.004rem;
    font-size: 10px;
    line-height: 13px;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.3s ease all;
    /* opacity: 0; */
}

.user-info-div-main.active .point-details {
    opacity: 1;
}

.user-info-div h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    margin-bottom: 0;
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.user-info-div p {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #828282;
    margin-top: 4px;
    display: inline-block;
    white-space: break-spaces;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.elipsis-btn-card.ant-btn {
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.ant-dropdown.add-remove-dropdown-main.dispatch-dropdown {
    width: 180px;
}

.ant-dropdown.add-remove-dropdown-main {
    width: 98px;
}

.ant-dropdown.add-remove-dropdown-main .ant-dropdown-menu {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.ant-dropdown.add-remove-dropdown-main .ant-dropdown-menu .ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.ant-dropdown.add-remove-dropdown-main .ant-dropdown-menu .ant-btn:hover {
    color: var(--Gray-2-color);
}

.new-btn-main.ant-btn {
    color: var(--white-color);
    height: 30px;
    background: #3f4e52;
    border-radius: 4px;
    border: 1px solid #3f4e52;
    padding: 0 30px;
}

.main-inner-form .new-btn-main.ant-btn.new-contact-account-btn {
    right: 20px;
    top: 42px;
}

.new-btn-main.ant-btn.new-btn-main-create {
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-color: var(--Gray-3-color);
    background: var(--white-color);
    color: var(--Gray-2-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    margin-left: 20px;
    padding: 0 30px;
}

.small-heading {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    margin-right: 45px;
}

.border-pr-row-btm {
    border-bottom: 1px solid var(--Gray-4-color);
}

.customer-account-heading {
    margin: 20px 0 10px;
}

.customer-account-heading h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--Gray-4-color);
}

.summary-info-cr h5 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: #a9a7a7;
}

/*--search-results--*/

.search-results-main {
    padding: 15px 0;
    background: var(--Gray-6-color);
    height: auto;
}

.search-results-div h6 {
    margin-bottom: 0;
    padding-left: 15px;
    height: 79px;
    color: var(--Gray-1-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.018rem;
    border-bottom: 1px solid var(--Gray-5-color);
    display: flex;
    align-items: center;
}

.search-results-div {
    width: 266px;
    position: fixed;
    background: var(--white-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
    height: calc(100vh - 105px);
}

.ant-collapse.custom-collapse-results {
    background: transparent;
    overflow: auto;
    height: calc(100vh - 226px);
}

.ant-collapse.custom-collapse-results
> .ant-collapse-item
> .ant-collapse-header {
    margin-bottom: 0;
    height: 44px;
    border-right: 4px solid transparent;
    padding: 0 15px;
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
    display: flex;
    align-items: center;
}

.ant-collapse.custom-collapse-results
> .ant-collapse-item.ant-collapse-item-active
.ant-collapse-header,
.ant-collapse.custom-collapse-results
> .ant-collapse-item
> .ant-collapse-header:hover {
    background: var(--daf2eb-color) !important;
    border-color: var(--Green-2-color) !important;
    color: var(--Gray-1-color) !important;
}

.ant-collapse.custom-collapse-results > .ant-collapse-item {
    border-bottom: 1px solid var(--Gray-6-color);
}

.search-tab-head .count-tag-col-h,
.search-tab-head .count-tag-col-x {
    background: var(--white-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: 17px;
    padding: 1px 8px;
    color: var(--Gray-2-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    margin-right: 17px;
}

.search-tab-head .count-tag-col-x {
    margin-right: 0;
}

.all-results {
    padding: 10px 5px;
}

.custom-results-list li .ant-btn {
    border: 0;
    color: var(--Gray-3-color);
    letter-spacing: 0.01em;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    box-shadow: none;
    padding: 0 3px 0 55px;
    height: 40px;
    position: relative;
}

.custom-results-list li .dot-icon {
    content: "";
    left: 40px;
    top: 0;
    width: 5px;
    height: 5px;
    position: absolute;
    background: var(--Gray-3-color);
    border-radius: 50%;
    bottom: 0;
    margin: auto;
}

.custom-results-list li .ant-btn:hover,
.custom-results-list li .ant-btn.active {
    color: var(--Gray-2-color);
    background: transparent;
}

.search-results-right-details {
    border: 0;
    width: calc(100% - 266px);
    margin-left: 266px;
    float: right;
    padding-left: 30px;
}

.search-table-card-row {
    background: var(--white-color);
    margin-bottom: 15px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14);
}

.search-main-header-table {
    height: 79px;
    background: var(--white-color);
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 0 15px;
}

.results-div-head {
    color: var(--Gray-1-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.018rem;
}

.results-div-head span {
    color: var(--bdbdbd-color);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    /*margin-left: 10px;*/
}

.select-div-head-default {
    color: var(--Gray-1-color);
    letter-spacing: 0.004rem;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}

.select-div-head-default span {
    color: var(--Gray-3-color);
    margin-right: 5px;
}

.sorted-select-view
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    background: var(--white-color);
    border-radius: 6px;
    width: 180px;
    border: 1px solid var(--Gray-5-color);
}

.sorted-select-view .ant-select-selection-item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.main-table-all.search-result-table table thead tr th {
    background: #f5f5f5;
    border-radius: 0 !important;
    color: var(--Gray-3-color);
    font-size: 13px;
    line-height: 18px;
    padding: 11px 15px !important;
}

.main-table-all.search-result-table table tbody tr td {
    color: var(--Gray-2-color);
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

.main-table-all.search-result-table table tbody tr td:first-child {
    border-left: 2px solid transparent;
    border-radius: 0;
}

.main-table-all.search-result-table table tbody tr td:last-child {
    border-right: 2px solid transparent;
    border-radius: 0;
}

.main-table-all.search-result-table table tbody tr.ant-table-row:hover td,
.main-table-all.search-result-table table tbody tr.ant-table-row:focus td {
    border-top-color: var(--Green-2-color);
    border-bottom-color: var(--Green-2-color);
    background: var(--white-color);
}

.main-table-all.search-result-table
table
tbody
tr.ant-table-row:hover
td:first-child,
.main-table-all.search-result-table
table
tbody
tr.ant-table-row:focus
td:first-child {
    border-left-color: var(--Green-2-color);
}

.main-table-all.search-result-table
table
tbody
tr.ant-table-row:hover
td:last-child,
.main-table-all.search-result-table
table
tbody
tr.ant-table-row:focus
td:last-child {
    border-right-color: var(--Green-2-color);
}

.main-table-all.search-result-table.search-table-second
table
thead
tr
th:first-child {
    min-width: 320px;
}

.main-table-all.search-result-table.search-table-second table thead tr th {
    min-width: 150px;
}

.ant-form.main-inner-form .account-type-item .ant-input {
    background: var(--Gray-5-color);
    color: var(--Gray-3-color);
}

.ant-form.main-inner-form
.account-type-item
.ant-input::-webkit-input-placeholder {
    color: var(--Gray-3-color);
}

.small-text-div-select {
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #828282;
    bottom: 0;
    left: 15px;
}

.add-site-blank-row {
    background: #f8f8f8;
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    min-height: 100px;
    margin-top: 30px;
}

.add-site-blank-row span {
    color: var(--Gray-3-color);
    opacity: 0.8;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
}

.ant-btn.create-btn-main {
    color: var(--white-color);
    text-transform: uppercase;
    letter-spacing: -0.08px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    background: var(--primary-color);
    border: 1px solid #30403f;
    height: 34px;
    width: 95px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    right: 19px;
    top: 40px;
    bottom: 0;
}

.ant-btn.create-btn-main:hover {
    color: var(--white-color);
    background: var(--primary-color);
}

.ant-btn.create-btn-main[disabled], .ant-btn.create-btn-main[disabled]:hover {
    opacity: 0.4;
    background: var(--primary-color);
    color: var(--white-color);
    border: 1px solid #30403f;
}

.ant-select-disabled .ant-select-arrow {
    z-index: -1;
}

.no-data-upload-screens .no-data-main-tg {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    color: var(--Gray-2-color);
    margin-top: 5px;
}

.site-details-row-card {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    min-height: 115px;
    margin-top: 15px;
    overflow: hidden;
    transition: 0.3s ease all;
}

.site-details-row-card.active {
    border-color: var(--Green-2-color);
}

.site-details-row-card.site-details-row-card-update.active {
    background: #F7FEFC;
}

.site-details-row-card.site-details-row-card-update.active .bg-gray-main {
    background: var(--daf2eb-color);
}

.font-weight-500 {
    font-weight: 500;
}

.site-details-row-card .title {
    border-right: 1px solid var(--Gray-5-color);
    background: #f8f8f8;
}

.site-details-row-card > div {
    padding: 20px 15px;
}

.site-details-row-card.no-data-card-row {
    background: #F9F9F9;
    border: 1px solid var(--Gray-5-color);
    min-height: 100px;
}

.site-name-location {
    display: flex;
    align-items: center;
}

.site-name-location img {
    float: left;
    width: 41px;
}

.site-name-location span {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    float: left;
    width: calc(100% - 41px);
    padding-left: 5px;
}

.site-details-row-card h6 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: #a9a7a7;
}

.site-details-row-card p {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #333333;
}

.site-details-row-card p.text-elipsiss {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ant-dropdown-link-2.ant-btn {
    right: 10px;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}

.contact-row-line {
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 0 0 15px;
    margin-bottom: 15px;
}

/*.opportunity-db-table.customer-account-table .main-table-all table thead tr th:nth-child(1) {*/
/*    min-width: 700px;*/
/*}*/
.main-inner-form h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--Gray-4-color);
}

.same-address-label {
    right: 0;
    top: 0;
}

.border-rit-div {
    border-right: 1px solid var(--Gray-5-color);
}

.ant-select-single-placeholder .ant-select-selection-placeholder,
.ant-select-single-placeholder .ant-select-selection-search-input,
.ant-select-single-placeholder .ant-select-selection-item {
    padding-left: 22px !important;
}

.ant-input-number-handler-wrap {
    display: none;
}

/*--card-css--*/

.shade-card-main-div {
    border: 1px solid var(--Gray-6-color);
    border-radius: 8px;
    overflow: hidden;
    min-height: 106px;
    margin-bottom: 30px;
    background: var(--white-color);
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
}

.dashboard-card-new .shade-img-section {
    background: var(--Green-2-color);
}

.shade-card-main-div:hover {
    background: #f7fefc;
    mix-blend-mode: multiply;
    border: 1px solid #7fd4ba;
    box-sizing: border-box;
}

.shade-card-main-div.dashboard-card-new:hover .shade-img-section {
    background: var(--Green-2-color);
}

.shade-img-section {
    width: 104px;
    min-height: 104px;
    background: #f7fefc;
}

.shade-card-main-div:hover .shade-img-section {
    background: #f0fffb;
}

.shade-card-inactive-section[disabled]:hover .shade-img-section {
    background: var(--bdbdbd-color);
}

.card-content-section {
    width: calc(100% - 106px);
    padding: 25px 30px;
    min-height: 104px;
    display: flex;
    align-items: center;
}

.card-content-section h5 {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
}

.card-content-section h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--Gray-3-color);
}

.sales-site-design-fix .account-contact-collapse-div .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 45px 0 15px;
}

.sales-site-design-fix .account-contact-collapse-div.account-contact-update .ant-collapse-content {
    padding: 0 15px 20px 15px;
}

.sales-site-design-fix .opportunity-customer-div.account-contact-update .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 42%;
}

.sidebar-nav-links-div::-webkit-scrollbar,
.summary-info-row-main .summary-info-status-green-line-main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.sidebar-nav-links-div {
    -ms-overflow-style: none;
}

.cursor-pointer {
    cursor: pointer;
}

.collapse-icon-open,
.small-sidebar .collapse-icon-close {
    display: none;
}

.small-sidebar .sidebar-main-div {
    width: 83px;
}

.small-sidebar .main-content-div {
    width: calc(100% - 83px);
}

.small-sidebar .custom-header-main {
    width: calc(100% - 83px);
}

.small-sidebar .sidebar-main-div .ant-btn.menu-fold-btn {
    left: 0;
    right: 0;
    margin: auto;
    width: 35px;
}

.small-sidebar .nav-link-tag .nav-details-tag,
.small-sidebar .nav-collapse-card-inn .nav-details-tag,
.small-sidebar
.ant-collapse.collapse-sidebar-main
.ant-collapse-header
.ant-collapse-arrow {
    display: none;
}

.small-sidebar .nav-link-tag .nav-icons-tag,
.small-sidebar .sidebar-user-icon {
    margin: 0;
}

.small-sidebar .nav-link-tag,
.small-sidebar .ant-collapse.collapse-sidebar-main .ant-collapse-header {
    justify-content: center;
}

.small-sidebar .ant-collapse.collapse-sidebar-main .ant-collapse-item {
    border: 0;
}

.small-sidebar .collapse-icon-open {
    display: block;
}

.account-type-btn-main.ant-btn {
    background: var(--Green-1-color);
    height: 42px;
    border-radius: 8px;
    margin: 12px 0;
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--white-color);
    border-color: var(--Green-1-color);
    padding: 0 25%;
}

.account-type-btn-main.ant-btn img {
    margin-right: 5px;
}

.user-info-div-main.isActive {
    background: #f7fefc;
    border-color: var(--Green-2-color);
}

.home-labor-group {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    right: 15px;
    top: 15px;
    letter-spacing: 0.01em;
    color: var(--Green-1-color);
}

.home-labor-group.home-labor-group-2 {
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.main-content-div.home-labor-group {
    bottom: 0;
    top: 0;
    margin: auto;
    width: auto;
    display: flex;
    align-items: center;
}

.color-gray-3 {
    color: var(--Gray-2-color) !important;
}

.notes-all-common {
    min-height: 64px;
    background: #f9fbfb;
    border: 1px solid var(--Gray-6-color);
    margin-bottom: 15px;
}

.icon-info-notes {
    background: var(--daf2eb-color);
}

.small-text-main {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #828282;
    bottom: 3px;
}

.small-text-main.small-text-input {
    text-decoration-line: underline;
    color: var(--Gray-1-color);
}

.main-inner-form ul li {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.41px;
    color: var(--Gray-1-color);
    padding-left: 15px;
}

.add-sub-tier-main-row {
    border-top: 1px solid var(--Gray-5-color);
    border-bottom: 1px solid var(--Gray-5-color);
    margin: 0;
    padding: 8px 15px;
}

.add-sub-tier-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Green-1-color);
}

.add-sub-tier-btn:hover,
.add-sub-tier-btn:focus {
    color: var(--Green-1-color);
}

.ant-steps.vehicle-steps-main {
    margin-bottom: 45px;
}

.ant-steps.vehicle-steps-main .ant-steps-item {
    width: 50%;
    height: 24px;
    background: var(--Gray-5-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
}

.ant-steps.vehicle-steps-main .ant-steps-item:first-child:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -24px;
    width: 0;
    height: 0;
    border: 12px solid;
    border-color: transparent transparent transparent #000000;
}

.ant-steps.vehicle-steps-main .ant-steps-item:last-child:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 12px solid;
    border-color: transparent transparent transparent var(--white-color);
}

.ant-steps.vehicle-steps-main .ant-steps-item:first-child {
    border-radius: 25px 0 0 25px;
    margin-right: 12px;
}

.ant-steps.vehicle-steps-main .ant-steps-item:last-child {
    border-radius: 0 25px 25px 0;
}

.ant-steps.vehicle-steps-main
.ant-steps-item.ant-steps-item-process.ant-steps-item-active {
    background: #38bc94;
}

.ant-steps.vehicle-steps-main
.ant-steps-item.ant-steps-item-active:first-child:after {
    border-color: transparent transparent transparent #38bc94;
}

.ant-steps.vehicle-steps-main .ant-steps-item-icon {
    display: none;
}

.ant-steps.vehicle-steps-main .ant-steps-item-title {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
    color: #828282;
}

.ant-steps.vehicle-steps-main
.ant-steps-item.ant-steps-item-process.ant-steps-item-active
.ant-steps-item-title {
    color: var(--white-color);
}

.ant-steps.vehicle-steps-main .ant-steps-item-title::after {
    display: none;
}

.ant-steps.vehicle-steps-main .ant-steps-item.ant-steps-item-finish {
    background: var(--daf2eb-color);
}

.ant-steps.vehicle-steps-main
.ant-steps-item.ant-steps-item-finish:first-child:after {
    border-color: transparent transparent transparent var(--daf2eb-color);
}

.ant-steps.vehicle-steps-main
.ant-steps-item.ant-steps-item-finish
.ant-steps-item-title {
    color: var(--Green-1-color);
}

/*extra css*/
.create-opportunity-row
.ant-collapse-item.ant-collapse-item-active
.info-card-heading-row
h5.vehicle-group-heading {
    padding-left: 10px;
}

/*//extra css//*/
.vehicle-group-heading img {
    padding-right: 10px;
}

.steps-action.common-steps-action .ant-btn {
    height: 44px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #aeaeb2;
    padding: 0 20px;
}

.collapse-breadcrumb-main {
    min-height: 44px;
    background: #f8f8f8;
    margin-top: 15px;
    border-top: 1px solid var(--Gray-5-color);
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 0 30px 0 40px;
}

.create-opportunity-row
.ant-collapse.vehicle-group-collapse-main
.ant-collapse-header {
    padding: 12px 0 0;
    margin-bottom: -1px;
}

.vehicle-group-collapse-main .info-card-heading-row {
    padding: 0 30px 0 40px;
}

.breadcrumb-inner-details .ant-breadcrumb a {
    color: #828282;
    font-weight: 400;
}

.breadcrumb-inner-details .ant-breadcrumb > span:last-child a {
    font-weight: bold;
    color: #4f4f4f;
}

.ant-breadcrumb > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--Gray-3-color);
    letter-spacing: 0.01em;
}

/*.ant-breadcrumb > span:first-child .ant-breadcrumb-link {*/
/*    color: var(--Gray-2-color);*/
/*}*/

.ant-breadcrumb > span:last-child .ant-breadcrumb-link {
    color: var(--Gray-1-color);
}

.ant-breadcrumb > span a:hover {
    color: var(--Gray-3-color);
}

.small-heading-in-form h5,
.small-heading-in-form h6 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin: 25px 0;
}

.small-heading-in-form h5 {
    color: var(--Gray-2-color);
}

.small-heading-in-form h6 {
    color: var(--Gray-4-color);
}

.create-dry-table .ant-table-thead {
    display: none;
}

.create-dry-table .main-table-all table tbody tr:nth-child(odd) td {
    background: var(--white-color);
}

/*.main-table-all table tbody tr:nth-child(odd) td {*/
/*    background-color: #fff;*/
/*}*/

.create-dry-table .ant-table-tbody tr td {
    border-bottom: 1px solid var(--Gray-5-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.create-dry-table .ant-table-tbody tr td:first-child {
    min-width: 385px;
}

.create-dry-table .ant-table-tbody tr td:nth-child(2) {
    min-width: 200px;
}

.qty-details span {
    margin-right: 10px;
}

.qty-details form .ant-input-number {
    width: 57px;
    height: 32px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 4px;
}

.qty-details .ant-input-number-input {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-1-color);
}

.qty-details .ant-input-number-input::-webkit-input-placeholder {
    color: var(--Gray-1-color);
}

.save-btn-in-table {
    margin-left: 10px;
    width: 50px;
    height: 32px;
    background: #3f4e52;
    border-radius: 4px;
    border: 1px solid #3f4e52;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--white-color);
}

.save-btn-in-table span {
    margin: 0;
}

.text-green-tag {
    color: var(--Green-1-color) !important;
}

.text-gray-tag {
    color: var(--Gray-2-color) !important;
}

.total_cost_per {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--Gray-1-color);
    width: 35%;
    padding-left: 30px;
}

.total_cost_per_count {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.024rem;
    color: var(--Gray-2-color);
}

.summary-view-row-vehicle {
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 20px 0;
}

.add-vehicles-main-row {
    min-height: 85px;
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 15px;
}

.add-vehicles-img {
    width: 75px;
}

.add-vehicles-content {
    width: calc(100% - 75px);
}

.add-vehicles-content h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.add-vehicles-content p {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-4-color);
}

.dropdown-main-select {
    right: 15px;
    top: 0;
    bottom: 0;
    position: absolute;
}

.summary-info-inner-row.inventory-items-inner-row,
.mt-30 {
    margin-top: 30px;
}

.estimated-head-details {
    padding: 0 15px;
}

.estimated-head-details h6,
.estimated-head-details span {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.estimated-head-details span {
    font-weight: 600;
    color: var(--Green-1-color);
    padding-left: 5px;
}

.create-opportunity-row .ant-collapse.ct-pricing-collapse .ant-collapse-header {
    padding: 12px 0;
    background: #f8f8f8;
}

.ct-pricing-collapse .info-card-heading-row .ant-btn,
.ny-pricing-row-inner {
    border-radius: 6px;
}

.ny-pricing-row-inner {
    border: 1px solid var(--Green-2-color);
    padding: 0 60px 60px;
}

.create-opportunity-row
.ant-collapse.ny-pricing-collapse-main
> .ant-collapse-item {
    border: 0;
    border-radius: 0;
}

.create-opportunity-row
.ant-collapse.ny-pricing-collapse-main
.ant-collapse-header {
    padding: 12px 0;
    background: var(--daf2eb-color);
    margin-bottom: 10px;
}

.pricing-ny-table-main.main-table-all table thead tr th {
    min-width: auto;
    padding: 20px 15px !important;
    text-transform: capitalize;
}

.main-table-all.pricing-ny-table-main table tbody tr td {
    min-width: 120px;
    background: var(--white-color);
    border-bottom: 1px solid var(--Gray-6-color);
}

.main-table-all.pricing-ny-table-main table tbody tr td:first-child {
    min-width: 200px;
}

.main-table-all.pricing-ny-table-main table tbody tr:nth-child(odd) td {
    background: var(--white-color);
}

.main-table-all.pricing-ny-table-main
table
tbody
tr.ant-table-row.ant-table-row-level-0
td {
    color: var(--Gray-2-color);
}

.estimated-totals-main-div {
    background: #f8f8f8;
    height: 44px;
    padding: 0 30px 0 15px;
    bottom: -60px;
    left: 0;
    right: 0;
}

.estimated-totals-text,
.estimated-totals-price {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Green-1-color);
}

.estimated-totals-price {
    padding: 0 60px;
}

/*--tree-css--*/

.custom-tree-structure.ant-tree .ant-tree-treenode {
    width: 100%;
    padding: 0 45px 0 40px;
    border-bottom: 1px solid var(--Gray-5-color);
}

.custom-tree-structure.ant-tree .ant-tree-treenode:last-child {
    border: 0;
}

.custom-tree-structure.ant-tree-show-line .ant-tree-switcher {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-tree-structure.ant-tree-show-line
.ant-tree-switcher
.ant-tree-switcher-icon {
    font-size: 15px;
    color: var(--Gray-1-color);
}

.custom-tree-structure.ant-tree .ant-tree-node-content-wrapper {
    min-height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 0;
}

.custom-tree-structure.ant-tree .ant-tree-treenode-leaf-last {
    min-height: 48px;
    background: var(--white-color);
}

.custom-tree-structure.ant-tree .ant-tree-treenode:hover {
    background: #f9fbfb;
}

.custom-tree-structure
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open.ant-tree-node-selected,
.custom-tree-structure.ant-tree
.ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: var(--white-color);
}

.custom-tree-structure
.ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-selected,
.custom-tree-structure
.ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
    background: var(--white-color);
}

.custom-tree-structure .ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
}

.custom-tree-structure .ant-tree-title {
    width: 100%;
}

.branch-icon-text {
    padding: 0 20px 0 5px;
}

.branch-icon-text h6 {
    font-weight: 500;
    font-size: 15px;
    color: var(--Gray-2-color);
    line-height: 20px;
    letter-spacing: -0.24px;
}

.tree-heading {
    border-top: 1px solid var(--Gray-5-color);
    padding: 15px;
}

.main-inner-form .tree-heading,
.tree-heading-second.tree-heading-second {
    padding-right: 30px;
}

.summary-info-inner-row
.ant-collapse-content
> .ant-collapse-content-box
.tree-heading {
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 12px 30px;
}

.tree-heading span {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
    color: #a9a7a7;
}

.count-span-tg {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #828282;
}

.text-green-tag {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
}

.add-sub-tier-input-form {
    margin-top: 20px;
}

.add-sub-tier-input-form .ant-form {
    border: 1px solid #7fd4ba;
    border-radius: 14px;
    background: var(--white-color);
    width: 100%;
}

.add-sub-tier-input-form .ant-form .ant-input {
    background: transparent;
    color: var(--Gray-2-color);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    height: 50px;
    line-height: 15px;
    border-radius: 8px 8px 0 0;
    border: none;
    border-bottom: 1px solid var(--daf2eb-color);
    padding: 0 15px 0 45px;
}

.add-sub-tier-input-form .ant-form .ant-input::-webkit-input-placeholder {
    color: var(--Gray-2-color);
}

.img-tag-icon {
    left: 13px;
    top: 13px;
}

.add-sub-tier-input-form .ant-form .ant-input:hover,
.add-sub-tier-input-form .ant-form .ant-input:focus {
    outline: 0;
}

.add-sub-tier-input-form .ant-form .ant-btn.create-sub-tier {
    background: var(--Gray-6-color);
    height: 50px;
    font-weight: 600;
    z-index: 999;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Green-1-color);
    border-radius: 0 0 8px 8px;
    text-align: left;
}

.add-sub-tier-input-form .ant-form .ant-btn.create-sub-tier:hover {
    background: var(--daf2eb-color);
}

.add-sub-tier-main-row:hover {
    background: var(--daf2eb-color);
    border-color: var(--daf2eb-color);
}

.estimated-total-row {
    padding: 8px 0;
    align-items: center;
}
 .ant-form.main-inner-form .ant-input-number-input {
    text-align: right;
 }
.estimated-total-row .estimated-total-row.estimated-total-row-3 {
    border-top: 1px solid var(--Gray-5-color);
    padding: 10px 0 0;
    margin-top: 10px;
}

.sub-total-text {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: var(--Gray-3-color);
}

.sub-total-text.gray-1 {
    color: var(--Gray-1-color);
}

.sub-total-text small {
    font-style: italic;
    color: var(--Gray-3-color);
}

.ant-form.main-inner-form .estimated-total-row .ant-input {
    height: 32px;
    color: var(--Gray-2-color);
    text-align: right;
}

.ant-form.main-inner-form
.estimated-total-row
.ant-input::-webkit-input-placeholder {
    color: var(--Gray-2-color);
}

.estimated-total-row ul li {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    padding: 0;
}

.sub-total-text-main {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.022rem;
    color: var(--Gray-1-color);
}

.normal-text .sub-total-text-main {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: var(--Gray-3-color);
    display: inline-block;
    padding: 8px 0;
}

.estimated-total-row .value-div-inner ul li {
    width: 41%;
}

.value-div-inner .ant-select,
.value-div-inner .ant-input-number {
    width: 100%;
    text-align: left !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after {
    border-color: var(--Green-1-color);
}

.ant-radio-inner::after {
    background-color: var(--Green-1-color);
}

.common-form-card-row .ant-radio-checked::after {
    width: 16px;
    height: 16px;
    bottom: 0;
    margin: auto;
}

.line-items-row-main {
    background: #f9fbfb;
    padding: 30px 30px 60px;
    margin: 0;
}

.proposals-line-items-table .main-table-all table thead tr th {
    min-width: auto;
}

.proposals-line-items-table .main-table-all table tbody tr:nth-child(odd) td {
    background: var(--white-color);
}

.proposals-line-items-table.line-items-row-view-table
.main-table-all
table
tbody
tr:nth-child(odd)
td {
    background: #f9fbfb;
}

.proposals-line-items-table .main-table-all table tbody tr td {
    border-bottom: 1px solid var(--Gray-6-color);
}

.ant-form.main-inner-form
.proposals-line-items-table
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector,
.ant-form.main-inner-form .proposals-line-items-table .ant-input-number {
    width: 80px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid var(--Gray-4-color);
    padding: 0 5px;
}

.ant-form.main-inner-form
.proposals-line-items-table
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder {
    line-height: 32px;
}

.ant-form.main-inner-form .proposals-line-items-table .ant-input-number-input {
    height: 30px;
    padding: 0;
}

.breadcrumb-menu-main {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breadcrumb-menu-main span {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.breadcrumb-menu-main span.strong-tag {
    color: var(--Gray-2-color);
    font-weight: 600;
}

.rate-div-main span {
    padding-right: 8px;
}

.ant-btn.print-pdf-btn {
    background: #f6f6f6;
    border: 1px solid #30403f;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.031rem;
    color: var(--Gray-2-color);
    height: 36px;
}

.ant-btn.send-proposal-btn {
    background: #3f4e52;
    border: 1px solid #30403f;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.031rem;
    color: var(--white-color);
    height: 36px;
    margin: 0 10px;
}

.line-items-row-main.line-items-row-view-1 {
    background: #f9f9f9;
    border-top: 1px solid var(--Gray-5-color);
    padding: 30px 0;
}

.inner-modal-main-row p {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.customer-account-send {
    margin-top: 20px;
}

.customer-account-send .ant-checkbox-wrapper {
    width: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.customer-account-send .ant-checkbox + span {
    width: 100%;
}

.customer-account-send .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.customer-account-send .ant-checkbox:hover .ant-checkbox-inner,
.customer-account-send .ant-checkbox-input:focus + .ant-checkbox-inner,
.customer-account-send .ant-checkbox-checked::after {
    border-color: var(--Green-1-color);
}

.customer-account-send .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--Green-1-color);
}

.name-id-breadcrumb ul {
    display: flex;
    align-items: center;
}

.name-id-breadcrumb ul li {
    padding: 0 3px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    margin: 0 !important;
}

.ant-form.main-inner-form .name-id-details .ant-input-number {
    width: 64px;
    height: 32px;
    background: var(--white-color);
    border: 0.056rem solid var(--Gray-5-color);
    border-radius: 4px;
}

.ant-form.main-inner-form
.name-id-details
.ant-input-number
.ant-input-number-handler-wrap {
    opacity: 1;
}

.ant-form.main-inner-form
.name-id-details
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input,
.ant-form.main-inner-form .ant-input-number-input {
    height: 30px;
}

.main-table-all.inventory-kits-table table thead tr th {
    min-width: 125px;
}

.main-table-all.inventory-kits-table table thead tr th:nth-child(1) {
    min-width: 220px;
}

.rate-div span {
    padding-right: 10px;
}

/*.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {*/
/*    background: var(--Gray-5-color) !important;*/
/*}*/

.color-gray {
    color: #cacaca !important;
}

.price-estimated-row-table {
    height: 56px;
    background: #f9fbfb;
    right: 0;
}

ul.pricing-estimated-ul li {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
    padding: 0;
}

ul.pricing-estimated-ul li span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: var(--Gray-2-color);
}

.dry-group-collapse-main .main-table-scrolled .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.create-opportunity-row
.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
.ant-collapse-header {
    padding: 15px 0;
}

.create-opportunity-row
.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
.ant-collapse-item-active
.ant-collapse-header {
    background: var(--daf2eb-color);
}

.vehicle-group-collapse-main.dry-group-collapse-main .info-card-heading-row {
    padding: 0 15px 0 30px;
}

.dry-group-collapse-main .info-card-heading-row h5 {
    font-size: 17px;
    line-height: 22px;
    color: var(--Gray-1-color);
}

.site-info-checkbox-row .ant-radio-wrapper {
    border-top: 1px solid var(--Gray-5-color);
    width: 100%;
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.site-details-row-card .bg-gray-main {
    border-right: 1px solid var(--Gray-5-color);
    background: #f8f8f8;
    transition: 0.3s ease all;
}

.site-details-row-card.active .bg-gray-main {
    background: #f7fefc;
    border-color: var(--Green-2-color);
}

.main-radio-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
}

.main-radio-text .small-text-radio {
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    padding-left: 10px;
}

.user-info-div-main.user-info-div-main-2 {
    padding: 10px 0 0;
}

.site-info-checkbox-row.site-info-checkbox-row-2 .ant-radio-wrapper {
    margin: 10px 0 0;
}

.site-info-checkbox-row-2 .main-radio-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--black-color);
}

.site-info-checkbox-row-2 .small-text-radio {
    font-size: 8px;
    line-height: 10px;
}

.validate-div-col-line-items.validate-div-col {
    padding: 0 30px 20px;
}

.proposal-recipient-tag {
    color: var(--Green-1-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.24px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.user-info-div-main.user-info-div-main-view {
    height: 76px;
    padding: 10px 0;
}

.user-info-div-main.user-info-div-main-view.active {
    background: #f7fefc;
}

.add-sub-tier-input-form .ant-btn.ant-dropdown-trigger {
    background: #526368;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
    height: 61px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--white-color);
    padding: 0 15px;
    top: 119px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
}

.add-sub-tier-input-form.add-sub-tier-input-form-resources
.ant-btn.ant-dropdown-trigger {
    top: 0;
}

/*.add-adding-dropdown.ant-dropdown{*/
/*    min-width: 736px !important;*/
/*}*/
.add-adding-dropdown .ant-dropdown-menu {
    border-radius: 12px;
    border: 1px solid var(--Green-2-color);
    overflow: hidden;
}

.add-adding-dropdown .ant-dropdown-menu-item {
    padding: 0;
}

.add-adding-dropdown .ant-dropdown-menu-item .ant-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-1-color);
    height: 50px;
}

.add-adding-dropdown .ant-dropdown-menu-item .ant-btn img {
    margin-right: 10px;
}

.add-sub-tier-input-form .ant-select {
    width: 100%;
}

.ant-form.main-inner-form
.add-sub-tier-input-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    height: 60px;
    border: 1px solid var(--Green-2-color);
    border-radius: 12px 12px 0 0;
    padding: 0 45px 0 69px;
}

.ant-form.main-inner-form
.add-sub-tier-input-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    height: 58px;
}

.ant-form.main-inner-form
.add-sub-tier-input-form
.ant-select-single
.ant-select-selector
.ant-select-selection-item {
    line-height: 60px;
}

.add-sub-tier-input-form .ant-select-arrow {
    display: none;
}

.according-img-custom-fixes-div {
    height: 100%;
    width: 90px;
    justify-content: space-between;
    padding: 0 10px;
}

.according-img-custom-fixes-div .icon-div .anticon {
    color: #828282;
}

.wordsF-sub-tier-select {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.sub-tier-select-line-green span {
    width: 1px;
    height: 20px;
    background: #38bc94;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.ant-select-dropdown.sub-tier-select {
    border: 1px solid var(--Green-2-color);
    border-top: 0;
    border-radius: 0 0 12px 12px;
    margin-top: -8px;
}

.sub-tier-select .ant-select-item {
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
}

.sub-tier-select .ant-select-item.ant-select-item-option-active {
    background: var(--white-color);
}

.vc-tr-select-option-row {
    align-items: center;
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 10px 0;
}

.sub-tier-select .ant-select-item:last-child .vc-tr-select-option-row {
    border: 0;
}

.vc-select-option-img {
    width: 45px;
}

.vc-select-option-data h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
}

.vc-select-display-name .display-data {
    font-size: 11px;
    color: #828282
}
.vc-select-option-data .ant-breadcrumb span {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: #828282;
}

.ant-form.main-inner-form
.add-sub-tier-input-form-2
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder {
    line-height: 60px;
}

.ant-form.main-inner-form
.add-sub-tier-input-form.add-sub-tier-input-form-2
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    padding-left: 60px;
}

/*scroll-table-data-css--*/

.main-table-scrolled.line-items-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(4),
.main-table-scrolled.line-items-table-scrolled
.ant-table-tbody
> tr
> td:nth-child(8) {
    background: #f8f8f8;
}

.main-table-scrolled .ant-table-container {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.main-table-scrolled .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid var(--Gray-5-color);
    border-right: 0;
    border-bottom: 0;
}

.main-table-scrolled .ant-table-thead > tr > th {
    background: #f9fbfb;
    border-bottom: 1px solid var(--Gray-5-color);
    font-weight: bold;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: var(--Gray-2-color);
    padding: 10px;
}

.main-table-scrolled
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-header
> table
> thead
> tr
> th {
    border-right: 1px solid var(--Gray-5-color);
}

.main-table-scrolled
.ant-table-container
table
> thead
> tr:first-child
th:last-child {
    border-top-right-radius: 0;
}

.main-table-scrolled .ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--Gray-5-color);
    height: 61px;
    padding: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
    background: #f8f8f8;
    text-align: center;
}

.main-table-scrolled .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f8f8f8;
}

.main-table-scrolled
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-body
> table
> tbody
> tr
> td {
    border-right: 1px solid var(--Gray-5-color);
}

.main-table-scrolled .ant-table-tbody > tr > td:first-child,
.main-table-scrolled .ant-table-tbody > tr > td:nth-child(2),
.main-table-scrolled .ant-table-tbody > tr > td:nth-child(7),
.main-table-scrolled .ant-table-tbody > tr > td:nth-child(8),
.main-table-scrolled .ant-table-tbody > tr.ant-table-row:hover > td:first-child,
.main-table-scrolled
.ant-table-tbody
> tr.ant-table-row:hover
> td:nth-child(2),
.main-table-scrolled
.ant-table-tbody
> tr.ant-table-row:hover
> td:nth-child(7),
.main-table-scrolled
.ant-table-tbody
> tr.ant-table-row:hover
> td:nth-child(8) {
    background: var(--white-color);
}

.main-table-scrolled .ant-table-tbody .ant-table-row.ant-table-row-level-0 {
    position: relative;
}

.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:first-child {
    position: sticky;
    width: 306px;
    background: #f9f9f9;
    z-index: 1;
    left: 0;
    display: flex;
    align-items: center;
    color: var(--Green-1-color);
    padding-left: 10px;
}

.main-table-scrolled
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-body
> table
> tbody
> tr.ant-table-row.ant-table-row-level-0
td:nth-child(1),
.main-table-scrolled
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-body
> table
> tbody
> tr.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(1) {
    background: #f9f9f9;
    border-right: 0;
}

.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(4),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(5),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(6),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(4),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(5),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(6) {
    background: var(--white-color);
    z-index: 2;
}

.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(7),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(8),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(7),
.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(8) {
    background: #f9f9f9;
    border-right: 0;
    z-index: 2;
}

.main-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-1
td:nth-child(1)
a {
    display: none;
}

.main-table-scrolled
.ant-table-row.ant-table-row-level-1
.ant-table-row-indent.indent-level-1,
.main-table-scrolled
.ant-table-row.ant-table-row-level-1
.ant-table-row-expand-icon.ant-table-row-expand-icon-spaced {
    display: none;
}

.ant-table-row-expand-icon-collapsed {
    display: none;
}

.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:first-child {
    position: relative;
    width: auto;
    color: var(--Gray-3-color);
}

.main-table-scrolled.main-table-pricing
.ant-table.ant-table-bordered
> .ant-table-container
> .ant-table-body
> table
> tbody
> tr.ant-table-row.ant-table-row-level-0
td:nth-child(1) {
    border-right: 1px solid var(--Gray-5-color);
}

.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td
.background-gray-main {
    background: #f8f8f8;
    padding: 0 15px;
}

.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(7),
.main-table-scrolled
.ant-table-tbody.main-table-pricing
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(7) {
    border-right: 1px solid var(--Gray-5-color);
}

.main-table-scrolled.main-table-pricing .ant-table-tbody .ant-table-row td,
.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(4),
.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(5),
.main-table-scrolled.main-table-pricing
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(6) {
    background: #f8f8f8;
}

.main-table-scrolled.line-items-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:first-child {
    color: var(--Gray-2-color);
    position: relative;
    width: 279px;
}

.main-table-scrolled.line-items-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0
td:nth-child(7),
.main-table-scrolled.line-items-table-scrolled
.ant-table-tbody
.ant-table-row.ant-table-row-level-0:hover
> td:nth-child(7) {
    border-right: 1px solid var(--Gray-5-color);
}

.rc-virtual-list-scrollbar {
    display: block !important;
}

/*==custom-table-with-div-css==*/

.custom-table-main-row {
    /*overflow: auto;*/
    position: relative;
    width: 1120px;
    max-height: 550px;
}

.custom-table-main-row.custom-update-table {
    width: 1561px;
}

/*.custom-table-main-row.proposal-table {*/
/*    width: 1014px;*/
/*}*/
.proposal-table.custom-table-main-row.proposal-table-new {
    width: 100%;
}

.proposal-table.proposal-table-new.custom-table-header1 .custom-table-cell-th,
.proposal-table.proposal-table-new.custom-table-header1 .custom-table-cell-td {
    width: 12.5%;
}

.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:first-child {
    width: 25%;
}

.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:nth-child(2),
.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:nth-child(3),
.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:nth-child(4),
.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:nth-child(5),
.proposal-table.proposal-table-new
.line-item-grid
.custom-table-cell-td:nth-child(6) {
    width: 12.5%;
}

.custom-table-main-row.proposal-table.custom-table-proposal {
    width: 1120px;
}

.proposal-table .line-item-grid .custom-table-cell-td:first-child {
    width: 343px;
}

.proposal-table .line-item-grid .custom-table-cell-td:nth-child(2) {
    width: 235px;
}

.custom-table-main-row.custom-table-main-row-line-item-view {
    width: 961px;
}

.custom-table-cell-th {
    height: 60px;
    width: 120px;
    background: #f9fbfb;
    border: 1px solid var(--Gray-5-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0;
}

.update-header .custom-table-cell-th {
    background: #3f4e52;
}

.update-header .custom-th-heading {
    color: var(--white-color);
}

.custom-table-header1 .custom-table-cell-th {
    width: 107px;
}

.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-1 {
    width: 143px;
}

.service-variant-update.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-1,
.service-variant-update.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-1 {
    width: 9%;
}

.service-variant-update.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-2,
.service-variant-update.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-2 {
    width: 13%;
}

.service-variant-update.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-3,
.service-variant-update.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-3 {
    width: 15%;
}

.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-2 {
    width: 200px;
}

.custom-table-main-row-wage-info-main .service-variants-table .custom-table-cell-td:nth-child(5) > div {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.custom-table-main-row-line-item-view .custom-table-cell-th,
.custom-table-main-row-line-item-view .custom-table-cell-td {
    width: 125px;
}

.custom-table-cell-th:last-child {
    border-right: 1px solid var(--Gray-5-color);
}

.custom-table-cell-th.custom-table-cell-th-1 {
    width: 160px;
}

.custom-table-cell-th.custom-table-cell-th-2 {
    width: 180px;
}

.custom-table-cell-th.custom-table-cell-th-3 {
    width: 235px;
}

.custom-table-main-row-line-item-view
.custom-table-cell-th.custom-table-cell-th-3,
.custom-table-main-row-line-item-view
.custom-table-cell-td.custom-table-cell-td-3 {
    width: 121px;
}

.custom-th-heading {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: var(--Gray-2-color);
    text-align: center;
}

.custom-table-cell-td {
    border: 1px solid var(--Gray-5-color);
    width: 120px;
    min-height: 60px;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
    border-top: 0;
    border-right: 0;
    cursor: default;
}

.custom-table-cell-td-double-column {
    border: 1px solid var(--Gray-5-color);
    min-width: 50%;
    min-height: 60px;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
    border-top: 0;
    border-right: 0;
    cursor: default;
}


.custom-table-cell-td div {
    cursor: default;
}

.custom-table-cell-td.green-light-color-div {
    background: rgba(218, 242, 235, 0.5);
}

.custom-table-cell-td:last-child {
    border-right: 1px solid var(--Gray-5-color);
}

.custom-table-cell-td.custom-table-cell-td-1 {
    width: 160px;
    padding: 0 15px;
}

.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-1 {
    width: 143px;
}

.custom-update-table.custom-table-header1
.custom-table-cell-td.custom-table-cell-td-1 {
    background: #f7fefc;
}

.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-2 {
    width: 200px;
}

.custom-table-header1 .custom-table-cell-td {
    width: 107px;
}

.gray-2-color {
    color: var(--Gray-2-color) !important;
}

.custom-table-cell-td.custom-table-cell-td-2 {
    width: 180px;
    padding: 0 15px;
}

.custom-table-cell-td.custom-table-cell-td-2.custom-table-cell-td-name-info,
.custom-table-cell-td.custom-table-cell-td-3.custom-table-cell-td-labor-group {
    background: var(--white-color);
}

.custom-table-cell-td.custom-table-cell-td-3 {
    width: 235px;
}

.custom-table-cell-td.custom-table-cell-td-4 {
    padding: 0 15px;
}

.custom-table-cell-td.custom-table-cell-td-6,
.custom-table-cell-td.custom-table-cell-td-7 {
    justify-content: flex-end;
    padding: 0 15px;
}

.custom-table-cell-td.custom-table-cell-td-8 {
    justify-content: flex-start;
    padding: 0 15px;
}

.custom-table-cell-td.custom-table-cell-td-5 {
    justify-content: flex-end;
}

.editalble-form-data,
.editalble-form-data .ant-select,
.editalble-form-data .ant-form {
    width: 100%;
    height: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-table-collapse-main .editalble-form-data {
    text-align: right;
}

.editalble-form-data .ant-select-arrow {
    display: none;
}

.editalble-form-data
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    background: transparent !important;
    border-color: transparent !important;
    padding: 0 0 0 30px;
    height: 100% !important;
}

.editalble-form-data-edit.editalble-form-data
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    padding-left: 15px;
}

.ant-form.main-inner-form
.editalble-form-data
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    height: 57px;
}

.ant-form.main-inner-form
.editalble-form-data
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder {
    line-height: 57px;
}

.editalble-form-data .ant-select-selection-placeholder {
    color: var(--Gray-3-color) !important;
}

.custom-table-cell-td .ant-input-number {
    border-radius: 0 !important;
    height: 59px !important;
    border-color: transparent !important;
    transition: 0.3s ease all;
    width: 100% !important;
}

.custom-table-cell-td .ant-input-number-input {
    height: 58px !important;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
    caret-color: var(--Green-1-color);
}

.main-table-scrolled .ant-table-tbody > tr > td .ant-input-number {
    height: 60px !important;
}

.custom-table-cell-td .ant-input-number-input::-webkit-input-placeholder {
    color: var(--Gray-3-color);
}

.custom-table-row .custom-table-cell-td:hover .ant-input-number {
    border-color: var(--Green-1-color) !important;
}

.pencil-btn-edit {
    transition: 0.3s ease all;
    left: 15px;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    color: var(--Green-1-color);
    opacity: 0;
}

.pencil-btn-edit:hover {
    color: var(--Green-1-color);
}

.custom-table-cell-td:hover .pencil-btn-edit,
.main-table-scrolled .ant-table-tbody > tr > td:hover .pencil-btn-edit {
    opacity: 1;
}

.search-btn-icon {
    color: var(--Gray-3-color);
    top: 15px;
    right: 5px;
}

.search-btn-icon:hover,
.search-btn-icon:focus {
    color: var(--Gray-3-color);
}

/*.custom-table-main-row::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*.custom-table-main-row::-webkit-scrollbar {*/
/*    width: 0;*/
/*    !* Remove scrollbar space *!*/
/*    background: transparent;*/
/*    !* Optional: just make scrollbar invisible *!*/
/*}*/

/* Optional: show position indicator in red */

/*.custom-table-main-row::-webkit-scrollbar-thumb {*/
/*    background: transparent;*/
/*}*/
.main-table-div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
}

.main-table-div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .4);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.name-info-div {
    width: 100%;
    padding-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
}

.name-info-div .anticon.anticon-caret-down {
    color: var(--Gray-4-color);
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 5px;
}

.rectangle-icon-div {
    left: 0;
    top: 0;
}

.rectangle-icon-div:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -28px;
    width: 2px;
    height: 35px;
    background: #dedede;
}

.custom-table-cell-td.last-child .rectangle-icon-div:before {
    display: none;
}

.rectangle-icon-div.rectangle-icon-div-green {
    top: 0;
}

.rectangle-icon-div.d-none-rectangle-before:before,
.rectangle-icon-div.rectangle-icon-div-green:before {
    display: none;
}

.ant-btn.add-new-row-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Green-1-color);
    background: transparent;
    border: 0;
    padding: 0;
    box-shadow: none;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
}

.ant-btn.add-new-row-btn img {
    margin-right: 5px;
}

.custom-table-cell-td.background-white-div {
    background: var(--white-color);
    padding: 0 15px 0 30px;
}

.custom-table-cell-td.wage-info-collapse-td .anticon-caret-down {
    padding-right: 10px;
    color: var(--Gray-2-color);
}

.custom-table-main-row-wage-info-main
.custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main
.custom-table-cell-th.custom-table-cell-th-9,
.custom-table-main-row-wage-info-main
.custom-table-cell-td.custom-table-cell-td-1,
.custom-table-main-row-wage-info-main
.custom-table-cell-td.custom-table-cell-td-9 {
    width: 12.206%;
}

.custom-table-main-row-wage-info-main
.custom-table-cell-td.custom-table-cell-td-9 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.custom-table-main-row-wage-info-main
.custom-table-cell-td.background-white-div {
    padding: 0;
}

.custom-table-main-row-wage-info-main .custom-table-cell-th,
.custom-table-main-row-wage-info-main .custom-table-cell-td {
    width: 10.463%;
}

.custom-table-main-row-wage-info-main
.custom-table-cell-td.wage-info-collapse-td {
    width: 370px;
    padding: 0 15px 0 40px;
    color: var(--Green-1-color);
}

.table-width {
    width: 358px;
}

.custom-table-main-row-wage-info-main
.custom-table-cell-td.wage-info-collapse-td-second {
    width: 235px;
}

.create-opportunity-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item,
.summary-info-inner-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item,
.proposal-update-table .ant-collapse,
.proposal-update-table .ant-collapse > .ant-collapse-item,
.proposal-update-table .ant-collapse-content > .ant-collapse-content-box,
.proposal-update-table .ant-collapse .ant-collapse-content {
    border: 0;
    border-radius: 0;
    margin: 0;
    background: transparent;
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    padding: 0;
    background: transparent;
}

.create-opportunity-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow,
.summary-info-inner-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 20px;
}

.custom-table-cell-td .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--Green-1-color);
    border-color: var(--Green-1-color);
}

.custom-table-cell-td .ant-checkbox-checked::after {
    border: 1px solid var(--Green-1-color);
}

.proposals-table-line-item .custom-table-cell-th.custom-table-cell-th-1,
.proposals-table-line-item .custom-table-cell-th.custom-table-cell-th-2,
.proposals-table-line-item .custom-table-cell-td.custom-table-cell-td-1,
.proposals-table-line-item .custom-table-cell-td.custom-table-cell-td-2 {
    width: 200px;
}

.proposals-table-line-item.custom-table-main-row {
    width: 1260px;
}

.custom-table-main-row-wage-info-main.proposals-table-line-item
.custom-table-cell-td.wage-info-collapse-td {
    width: 400px;
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td {
    background: var(--Gray-6-color);
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td.facility-column {
    background: var(--white)
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td>.facility-dropdown {
   width: 100%;
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td>.facility-dropdown>.ant-select-selector {
    border: transparent;
    outline: 0;
    box-shadow: none;
    
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td>.facility-dropdown>.ant-select-focused {
    outline: 0;
    box-shadow: none;
}


.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td.checkbox-new {
    background: var(--white-color);
}


.summary-info-inner-row
.custom-table-collapse-main
.ant-collapse-item:last-child
> .ant-collapse-content {
    border-radius: 0;
}

.proposals-table-line-item .ant-select {
    width: 100%;
}

.proposals-table-line-item
.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    padding: 0;
    background: transparent;
    border: 0;
    color: var(--Gray-2-color);
}

.proposals-table-line-item .ant-select-selection-placeholder,
.proposals-table-line-item .ant-select-arrow .anticon {
    color: var(--Gray-2-color);
}

.custom-tree-structure.ant-tree-show-line .ant-tree-indent-unit::before {
    display: none;
}

/*.custom-tree-structure.ant-tree-show-line .ant-tree-indent span:last-child:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    background: url("../images/icons/rectangle-gray.svg") no-repeat;*/
/*    left: 10px;*/
/*    width: 16px;*/
/*}*/
/*.custom-tree-structure.ant-tree-show-line .ant-tree-indent span:last-child:before{*/
/*    display: block;*/
/*    bottom: 0;*/
/*    border-right: 2px solid #d9d9d9;*/
/*}*/
.custom-tab-pane .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.custom-tab-pane .ant-collapse,
.custom-tab-pane .ant-collapse > .ant-collapse-item {
    border: 0;
}

.search-small-icon-position
.ant-input-search
> .ant-input-group
> .ant-input-group-addon {
    position: absolute;
    left: 10px;
    top: 6px;
    margin: auto;
}

.search-small-icon-position
.ant-input-search
> .ant-input-group
> .ant-input-group-addon
.ant-input-search-button {
    height: auto;
    border: 0;
    width: auto;
    z-index: 1;
}

.remaining-tag {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: var(--Gray-2-color);
}

.ant-form.main-inner-form .search-small-icon-position .ant-input {
    padding-left: 30px;
}

.select-option-icon {
    width: 40px;
}

.select-option-details h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
}

.common-select-option-row .ant-breadcrumb span {
    /*font-weight: 500;*/
    /*font-size: 11px;*/
    /*line-height: 13px;*/
    /*letter-spacing: 0.004rem;*/
    color: var(--Gray-4-color);
}

.common-select-option-row
.ant-breadcrumb
> span:last-child
.ant-breadcrumb-link {
    color: var(--Gray-3-color);
    font-weight: bold;
}

.custom-select-drop-main .ant-select-item {
    padding: 0;
}

.custom-select-main
.ant-select-item.ant-select-item-option.ant-select-item-option-selected,
.custom-select-main
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.custom-select-drop-main
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.custom-select-drop-main
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    padding: 0;
}

.ant-select-dropdown.custom-select-drop-main {
    width: 375px !important;
    background: var(--white);
    border: 1px solid var(--Gray-5-color);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.ant-form.main-inner-form
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input,
.ant-form.main-inner-form .ant-input-number-input {
    height: 38px;
}

.add-sub-tier-input-form
.ant-select-single.ant-select-show-arrow
.ant-select-selection-search {
    left: 90px;
}

.heading-header-main {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.add-sub-tier-input-form-2.add-sub-tier-input-form
.ant-select-single.ant-select-show-arrow
.ant-select-selection-search {
    left: 0;
}

.text-red-tag {
    color: #eb5757 !important;
}

.text-right-tree {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
}

/*.custom-table-body:last-child .custom-table-row:last-child .custom-table-cell-td .name-info-div .rectangle-icon-div:before{*/
/*    display: none;*/
/*}*/
.editalble-form-data
.ant-select-single
.ant-select-selector
.ant-select-selection-item,
.editalble-form-data
.ant-select-single
.ant-select-selector
.ant-select-selection-placeholder {
    line-height: 58px;
    padding-right: 30px;
}

.ant-radio-wrapper.custom-radio-btn-group {
    display: flex;
    align-items: center;
    width: 100%;
}

.custom-radio-btn-group h6 {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #4f4f4f;
}

.custom-radio-btn-group span {
    color: var(--Gray-3-color);
    font-size: 13px;
}

.account-heading-all {
    margin-bottom: 25px;
}

.account-heading-all h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--Gray-4-color);
}

.account-heading-all .ant-btn.view-all-btn {
    background: var(--white-color);
    border: 1px solid var(--Gray-3-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: var(--Gray-2-color);
    padding: 0 30px;
}

.account-heading-all .ant-btn.view-all-btn:hover {
    background: var(--daf2eb-color);
    border-color: var(--Green-2-color);
}

.account-contact-collapse-div {
    margin-top: 30px;
}

.summary-info-inner-row
.account-contact-collapse-div
.ant-collapse
> .ant-collapse-item,
.account-contact-collapse-div .ant-collapse > .ant-collapse-item,
.summary-info-inner-row .account-contact-collapse-div .ant-collapse-content,
.account-contact-collapse-div .ant-collapse-content {
    background: #f9fbfb;
}

.account-contact-collapse-div .site-details-row-card {
    margin: 0;
}

.summary-info-inner-row
.account-contact-collapse-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header,
.account-contact-collapse-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    padding: 12px 40px 12px 60px;
}

.summary-info-inner-row
.account-contact-collapse-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow,
.account-contact-collapse-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 50%;
}

.account-contact-collapse-div .ant-collapse > .ant-collapse-item {
    margin-bottom: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.account-contact-collapse-div .ant-collapse,
.account-contact-collapse-div .ant-collapse-content {
    border: 0;
    background: transparent;
}

.editable-list {
    min-height: 90px;
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    width: 100%;
    padding: 10px;
}

.editable-list ul li {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 15px 0;
    color: var(--Gray-2-color);
}

.editable-list ul:focus {
    outline: none;
}

.editable-list ul li:empty:after {
    content: "Tap enter to create new bullet point";
    color: #aeaeae;
}

.account-contact-collapse-div .ant-collapse.site-owner-collapse-main {
    border-radius: 0;
}

.summary-info-inner-row
.account-contact-collapse-div
.ant-collapse.site-owner-collapse-main
> .ant-collapse-item,
.account-contact-collapse-div .ant-collapse > .ant-collapse-item {
    border: 0;
    border-radius: 0;
}

.summary-info-inner-row
.account-contact-collapse-div
.site-owner-collapse-main
.ant-collapse-content {
    padding: 0 45px 20px 60px;
}

.account-contact-collapse-div.site-owner-div .site-details-row-card {
    margin-top: 15px;
}

.main-inner-form .contact-green-small-heading h5,
.contact-green-small-heading h5 {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Green-1-color);
    background: #f9fbfb;
    z-index: 1;
    padding-right: 20px;
    margin: 0;
}

.contact-green-small-heading:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: var(--Gray-5-color);
    top: 2px;
    bottom: 0;
    margin: auto;
    right: 0;
}

.contact-site-add-card li {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--Gray-2-color);
}

.contact-site-add-card li span {
    padding-left: 5px;
}

.custom-progress-btn {
    color: #f2994a !important;
}

.custom-completed-btn {
    color: var(--Gray-2-color) !important;
}

.custom-success-btn {
    color: var(--Green-1-color) !important;
}

.custom-primary-btn {
    color: #3391ff !important;
}

.ant-form-item .ant-select.search-and-select-tag .ant-select-arrow {
    display: none;
}

.inner-modal-main
.ant-form-item
.ant-select.search-and-select-tag
.ant-select-selector,
.ant-form-item .ant-select.search-and-select-tag .ant-select-selector {
    padding-left: 35px;
    height: auto;
    min-height: 40px;
}

.ant-form-item
.ant-select.search-and-select-tag
.ant-select-selection-search-input {
    padding-left: 25px;
}

.opportunity-customer-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 35%;
}

.ant-dropdown-link.dropdown-remove-edit-dots,
.close-icon-btn.ant-btn {
    right: 15px;
    top: 10px;
}

.customer-account-heading h5.text-small-black {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
}

.work-order-table-data
.opportunity-db-table
.main-table-all
table
thead
tr
th:nth-child(1) {
    min-width: 225px;
}

.work-order-table-data .opportunity-db-table .main-table-all table thead tr th {
    max-width: 160px;
    min-width: 100px;
}

.work-order-table-data .ant-table-row-expand-icon::after,
.work-order-table-data .ant-table-row-expand-icon::before {
    content: "";
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotate(0deg);
}

.work-order-table-data .ant-table-row-expand-icon-collapsed::before,
.work-order-table-data .ant-table-row-expand-icon-collapsed::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.work-order-table-data .ant-table-row-indent + .ant-table-row-expand-icon {
    background: transparent;
    border: none;
}

.work-order-table-data .ant-table-row-indent + .ant-table-row-expand-icon:focus,
.work-order-table-data
.ant-table-row-indent
+ .ant-table-row-expand-icon:hover {
    background: transparent;
    border: none;
    outline: 0;
}

.grip-system-div {
    position: absolute;
    right: 45px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.border-right-1 {
    border-right: 1px solid var(--Gray-5-color);
}

.work-order-table-data
.main-table-all
table
tbody
tr.ant-table-row.ant-table-row-level-1
td {
    background: var(--white-color);
}

.ant-btn.route-assign-btn {
    color: var(--Green-1-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    height: 75px;
    background: #f9f9f9;
    border: 1px solid var(--Gray-5-color);
    box-sizing: border-box;
    border-radius: 6px;
    white-space: break-spaces;
}

.site-details-row-card-map {
    border-top: 1px solid var(--Gray-5-color);
}

.ant-dropdown.dropdown-custom .ant-dropdown-menu {
    width: 171px;
    min-height: 376px;
    background: var(--white-color);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 8px 0;
}

.ant-dropdown.dropdown-custom .ant-dropdown-menu-item {
    padding: 14px 15px;
}

.ant-dropdown.dropdown-custom .ant-dropdown-menu-item a {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.ant-dropdown.dropdown-custom .ant-dropdown-menu-item .ant-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-4-color);
    border: 0;
    box-shadow: none;
    padding: 0;
    height: auto;
    background: transparent;
}

.heading-create-card {
    border-top: 1px solid var(--Gray-5-color);
    margin: 20px 0 10px;
    padding: 20px 0 0;
}

.heading-create-card h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: var(--Gray-4-color);
}

.grid-view-card {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    min-height: 76px;
    padding: 8px 0;
    margin-bottom: 30px;
}

.caret-icon-div {
    width: 30px;
}

.work-order-settings-div {
    width: 40px;
}

.work-order-details-div {
    width: calc(100% - 70px);
}

.work-order-details-div h5,
.work-order-details-div p,
.work-order-details-div .time-date-details ul li,
.routes-card-footer .time-date-details ul li,
.grid-view-card.grid-view-card-routes .work-order-details-div ul li {
    letter-spacing: 0.004rem;
    font-size: 11px;
    line-height: 13px;
}

.work-order-details-div h5 {
    color: var(--Green-1-color);
}

.work-order-details-div h5 span {
    color: #f2994a;
}

.work-order-details-div ul li {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
}

.work-order-details-div ul li.border-right-1 {
    padding-right: 5px;
    border-color: var(--Gray-4-color);
}

.work-order-details-div p,
.work-order-details-div .time-date-details ul li,
.grid-view-card.grid-view-card-routes .work-order-details-div ul li {
    color: var(--Gray-4-color);
}

.work-order-details-div .ant-dropdown-link {
    right: 0;
    top: 0;
}

.work-order-details-div .time-date-details ul li {
    font-weight: 500;
}

.grid-view-card.grid-view-card-routes .caret-icon-div {
    width: 35px;
}

.grid-view-card.grid-view-card-routes .work-order-details-div {
    width: calc(100% - 35px);
}

.grid-view-card.grid-view-card-routes .work-order-details-div h5 {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #3391ff;
}

.grid-view-card.grid-view-card-routes .work-order-details-div h5 span {
    color: #3f4e52;
}

.routes-card-footer {
    border-top: 1px solid var(--Gray-6-color);
    padding: 5px 0 0 35px;
    margin-top: 10px;
}

.routes-card-footer .time-date-details ul li {
    color: var(--Gray-3-color);
}

.routes-card-footer .time-date-details ul li.border-right-1 {
    padding-right: 0.7rem;
    border-color: var(--Gray-3-color);
}

.routes-card-footer .time-date-details ul li.list-inline-item:not(:last-child) {
    margin-right: 0.7rem;
}

.scheduled-btn {
    padding: 0;
    width: 72px;
    height: 16px;
    right: 25px;
    top: 4px;
    background: var(--Gray-6-color);
    border-radius: 0.165rem;
    font-weight: bold;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--Gray-3-color);
}

.ant-btn.grid-btn img {
    transition: 0.3s ease all;
}

.ant-btn.grid-btn img.view-gray-img {
    opacity: 1;
}

.ant-btn.grid-btn img.view-primary-img {
    left: 0;
    opacity: 0;
}

.ant-btn.grid-btn:hover img.view-gray-img,
.ant-btn.grid-btn.active img.view-gray-img {
    opacity: 0;
}

.ant-btn.grid-btn:hover img.view-primary-img,
.ant-btn.grid-btn.active img.view-primary-img {
    opacity: 1;
}

.ant-dropdown.dropdown-custom.dropdown-custom-routes .ant-dropdown-menu {
    min-height: auto;
}

/* new css */
.main-content-div .ant-collapse-header:hover {
    background: #F9FBFB;
    color: var(--Green-1-color);
}

.main-content-div .ant-collapse-item-active .ant-collapse-header:hover {
    background: var(--white);
}

.main-content-div .ant-collapse-item-active .ant-collapse-header {
    color: var(--Green-1-color);
}

.summary-info-inner-row .ant-collapse > .ant-collapse-item:hover,
.create-opportunity-row .ant-collapse > .ant-collapse-item:hover {
    border-color: #7FD4BA;
}

.summary-info-inner-row .ant-collapse > .ant-collapse-item.ant-collapse-item-active:hover,
.create-opportunity-row .ant-collapse > .ant-collapse-item.ant-collapse-item-active:hover {
    border-color: var(--Gray-5-color);
}

.ant-collapse-header .anticon:hover {
    /* background: var(--white-color) !important; */
    color: var(--Green-1-color) !important;
}

.panel-title {
    color: #20cb97 !important;
}

.no-data-wrapper {
    width: 100%;
    text-align: center;
}

.no-data-wrapper h6 {
    color: var(--bdbdbd-color);
}

.justify-between {
    margin-right: 5%;
}

.ant-table-container table > thead > tr:first-child th {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
    color: #333333;
}

.pay-info h6 {
    color: var(--Gray-2-color);
}

.associated-table tr td:nth-child(2) .more-text {
    font-weight: 600;
    text-transform: capitalize;
}

.associated-table
.opportunity-db-table
.main-table-all
table
thead
tr
th:nth-child(1),
.associated-table .opportunity-db-table .main-table-all table thead tr th {
    min-width: 200px;
}

.associated-table .opportunity-db-table .main-table-all table tbody tr td {
    text-transform: capitalize;
}

.custom-tree-row .add-new-line-item {
    opacity: 0;
    transition: 0.3s ease all;
}

.custom-tree-row:hover .add-new-line-item {
    opacity: 1;
}

.custom-tree-structure.custom-tree-structure-update.ant-tree
.ant-tree-treenode {
    width: 100%;
    padding: 0 35px 0 25px;
    border-top: 0.031rem solid var(--Gray-5-color);
    border-bottom: 0;
}

.custom-tree-structure-update .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.custom-tree-structure-update .branch-icon-text {
    padding: 0;
}

.custom-tree-structure.custom-tree-structure-update.ant-tree
.ant-tree-treenode-leaf-last.ant-tree-treenode-switcher-open {
    background: var(--white-color);
}

.custom-tree-structure-update .small-heading {
    margin: 0 0 0 10px;
}

.text-green-tag .ant-dropdown-link.ant-btn {
    color: #38bc94;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    width: auto;
    margin: 0;
}

.text-green-tag .ant-dropdown-link.ant-btn.cancel-btn-tree {
    color: #eb5757;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    margin: 0 15px 0 0;
    background: var(--Gray-6-color) !important;
    border-radius: 20px;
    width: 66px;
    height: 20px;
    justify-content: center;
}

.text-green-tag .remove-dropdown .ant-btn.cancel-btn-tree.cancel-btn-tree-1 {
    margin-right: 20px;
}

span.mr-1.cancel-btn-tree {
    margin-right: 20px !important;
}

span.mr-1.cancel-btn-tree-1 {
    margin-right: 28px !important;
}

.custom-tree-structure-update .branch-icon-text h6 {
    font-weight: bold;
}

.add-tier-div-plus {
    margin-left: 10px;
}

.add-tier-div-plus .ant-btn,
.add-new-line-item {
    color: var(--Gray-3-color);
    font-size: 20px;
    transition: 0.3s ease all;
}

.add-tier-div-plus .ant-btn {
    opacity: 0;
}

.custom-tree-structure.custom-tree-structure-update.ant-tree
.ant-tree-treenode:hover
.add-tier-div-plus
.ant-btn {
    opacity: 1;
}

.custom-tree-structure.custom-tree-structure-update.ant-tree
.ant-tree-treenode:hover,
.custom-tree-structure.ant-tree .ant-tree-node-content-wrapper:hover {
    background: #f9fbfb;
}

.custom-tree-structure.ant-tree.custom-tree-structure-update
.ant-tree-treenode-leaf-last {
    height: 48px;
    background: transparent;
}

.children-heading {
    font-weight: 400;
    font-size: 15px;
}

.children-heading-last {
    font-size: 13px;
}

.children-heading-last.images-with-name,
.children-heading-last.input-with-name {
    font-weight: 500;
}

.ant-dropdown.add-tier-dropdown-custom {
    min-width: 87px !important;
    width: 350px !important;
}

.add-tier-dropdown-custom .ant-dropdown-menu {
    border: 1px solid var(--Green-1-color);
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
}

.add-tier-dropdown-custom .ant-dropdown-menu-item {
    border-bottom: 1px solid var(--Gray-6-color);
    padding: 12px 30px;
    font-size: 15px;
}

.add-tier-dropdown-custom .ant-dropdown-menu-item:last-child {
    border: 0;
}

.tree-row-main.custom-tree-row .ant-input {
    height: 30px;
    border: 1px solid var(--Green-1-color);
    transition: 0.3s ease all;
}

/*.service-div .tree-row-main.custom-tree-row {*/
/*    padding: 0 30px;*/
/*    position: relative;*/
/*    top: 10px;*/
/*}*/

.tree-row-main.custom-tree-row .branch-icon-text .ant-input {
    height: 30px;
    border: 1px solid var(--Green-1-color);
}

.remove-cross-icon .ant-select-selection-item-remove {
    display: none;
}

.add-resource-btn.text-right-tree {
    color: var(--Green-1-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 4px 0;
    margin-top: 5px;
    cursor: pointer;
}

.add-resource-btn.text-right-tree.add-resources-btn-update {
    padding: 0 15px;
    height: 61px;
    display: flex;
    margin: 0;
    align-items: center;
}

.add-resource-btn.text-right-tree.add-resources-btn-update:hover {
    background: #f5f5f5;
}

.add-adding-dropdown .ant-dropdown-menu-item:hover,
.add-adding-dropdown .ant-dropdown-menu-item-active,
.add-adding-dropdown .ant-dropdown-menu-item-active:hover,
.add-adding-dropdown .ant-dropdown-menu-item .ant-btn:hover {
    background-color: var(--Gray-6-color);
}

.add-sub-tier-input-form.add-resources
.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    background: var(--white-color);
    border: 1px solid var(--Green-1-color);
    box-sizing: border-box;
    border-radius: 6px;
    height: 32px;
    width: 230px;
}

.add-sub-tier-input-form.add-resources
.ant-select-single.ant-select-show-arrow
.ant-select-selection-search {
    left: 0;
    width: 100%;
}

.add-sub-tier-input-form.add-resources
.ant-select:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    padding: 0 15px;
}

.main-content-div
.ant-table-thead
> tr
> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    opacity: 0;
}

.inline-item-drawer .ant-drawer-body {
    padding: 0;
}

.resource-row-main {
    padding: 30px;
}

.inline-item-breadcrumb .ant-breadcrumb {
    padding: 10px 30px;
    background: var(--Gray-6-color);
}

.info-card-heading-row {
    padding-left: 20px;
}

.create-opportunity-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header,
.summary-info-inner-row
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    padding-left: 0;
}

.common-card-upload {
    background: #f9f9f9;
    border: 1px solid var(--Gray-5-color);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 25px 0;
    min-height: 100px;
}

.site-details-row-card.site-details-row-card-2 {
    min-height: 100px;
}

.site-details-rowpROJECTS-card.site-details-row-card-2.details-div-update {
    min-height: 480px;
}

.site-common-details-row {
    min-height: 80px;
}

.site-details-row-card.site-details-row-card-2 > div {
    padding: 15px;
}

.site-details-row-card.site-details-row-card-2 > div:first-child,
.site-details-row-card.site-details-row-card-2 .site-details-heading {
    background: #f8f8f8;
    border-right: 1px solid var(--Gray-5-color);
}

.site-details-row-card.site-details-row-card-2 .site-details-heading {
    padding: 15px;
    margin-bottom: 30px;
    height: 70px;
}

.site-details-row-card.site-details-row-card-2.details-div-update.site-card-grid-change {
    min-height: auto;
}

.site-details-row-card.site-details-row-card-2.site-card-grid-change .site-details-heading {
    margin: 0;
    padding: 30px 15px;
    height: 100%;
}

.site-details-row-card.site-details-row-card-2.site-card-grid-change .site-common-details-row {
    padding: 30px 0 0;
}

.site-card-grid-change .Quantities-details-div.Quantities-details-div-vendor {
    min-height: auto;
}

/*.internal-location-table {*/
/*    margin-top: 15px;*/
/*}*/
.unit-measurement-select.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    height: 32px;
    background: var(--white-color);
    border: 1px solid #828282;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 230px;
}

.unit-measurement-select .ant-select-selection-placeholder {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--Gray-2-color);
}

.unit-measurement-select .ant-select-arrow {
    top: 34%;
    right: 3px;
    width: auto;
    height: auto;
}

.inner-collapse-section.ant-collapse
.ant-collapse-content
> .ant-collapse-content-box {
    border: 0;
    background: #f9fbfb;
    padding: 0 45px;
}

.ant-collapse.inner-collapse-section > .ant-collapse-item {
    border: 0;
    background: #f9fbfb;
    padding: 15px;
}

.ant-collapse.inner-collapse-section .ant-collapse-content {
    border: 0;
}

.ant-collapse.inner-collapse-section .ant-collapse-header:hover {
    background: #f9fbfb !important;
}

.ant-collapse.inner-collapse-section
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 40px;
    margin: 0;
    position: absolute;
    left: 0;
}

.ant-upload .ant-btn:hover {
    background: transparent;
}

.carpet-cleaning-mini-header.summary-header-details.search-view-header-in-collapse.design-update-bar {
    border: 1px solid var(--Gray-5-color);
    margin: 30px 0 0;
}

.custom-summary {
    padding: 0 30px;
}

.sub-total-row {
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 10px 0;
    margin-bottom: 15px;
}

.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow,
.opportunity-customer-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    position: absolute;
    color: var(--Gray-3-color);
    font-size: 16px;
    left: 10px;
    top: 20px;
    margin: 0;
    padding: 0;
}

.opportunity-customer-div
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 35%;
    left: 15px;
}

.opportunity-customer-div .ant-collapse-header:hover {
    background: #f9fbfb !important;
    color: var(--Green-1-color) !important;
}

.labor-emp-document-table {
    background: var(--white-color);
}

.create-opportunity-row
.ant-collapse.min-head-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    top: 20%;
}

.ant-collapse.dry-group-collapse-main
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    position: absolute;
    top: 35%;
    margin: 0;
    padding-top: 0;
}

.main-inner-form > .row:last-child > .col-12 > .custom-tree-row {
    padding: 8px 25px 8px 20px;
    border-top: 1px solid var(--Gray-5-color);
}

.tree-row-main.custom-tree-row > .d-flex {
    width: 80%;
}

.tree-row-main.custom-tree-row > .text-green-tag.d-flex {
    width: 20%;
    justify-content: flex-end;
}

.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-number-input[value] {
    color: var(--Gray-4-color);
}

/*.ant-form-item.ant-form-item-has-success .ant-form-item-control .ant-form-item-control-input .ant-input-number-input[value] {*/
/*    color: var(--Gray-2-color)*/
/*}*/
.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-number-input[aria-valuenow] {
    color: var(--Gray-2-color);
}

.ant-tree-treenode-switcher-open .ant-tree-switcher-icon {
    transition: ease 0.3s;
    transform: rotate(90deg);
}

.ant-tree-treenode-switcher-close .ant-tree-switcher-icon {
    transition: ease 0.3s;
}

.ant-form.main-inner-form .custom-table-cell-td .ant-input-number-handler-wrap {
    display: none;
}

.icon-div-search {
    left: 5px;
    top: 2px;
}

.add-sub-tier-input-form.add-resources.add-search-icon
.ant-select:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    padding-left: 30px;
}

.add-search-icon
.ant-select-single.ant-select-show-arrow
.ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
.custom-table-cell-td
.ant-select-single.ant-select-show-arrow.edit-select-box
.ant-select-selection-placeholder {
    padding-left: 15px;
    padding-right: 0;
    line-height: 40px;
}

.custom-table-cell-td
.ant-select-single.ant-select-show-arrow
.ant-select-selection-placeholder {
    line-height: 32px;
}

.main-inner-form
.custom-table-cell-td
.edit-select-box.ant-select
.ant-select-selector {
    background: transparent;
    border-radius: 0;
    border-color: transparent;
}

.custom-select-drop-main .custom-tree-row {
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 7px 15px;
    min-height: 48px;
}

.custom-select-drop-main
.rc-virtual-list-holder-inner:last-child
.ant-select-item:last-child
.custom-tree-row {
    border: 0;
}

.custom-select-drop-main .common-select-option-row {
    width: 80%;
}

.select-text-tier {
    width: 13%;
    justify-content: flex-end;
    display: flex;
}

.custom-select-drop-main .select-option-details h6 {
    line-height: 15px;
    font-size: 14px;
}

.common-select-option-row .ant-breadcrumb {
    display: inline-block;
    white-space: nowrap;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    top: 6px;
}

.common-select-option-row .ant-breadcrumb-separator {
    margin: 0 4px;
}

.ant-select-single.ant-select-show-arrow.unit-measurement-select
.ant-select-selection-placeholder {
    padding-left: 0;
}

.account-history-heading {
    margin-bottom: 15px;
}

.account-history-heading h5 span {
    font-weight: normal;
    padding-left: 5px;
    color: var(--Gray-2-color);
    font-size: 16px;
}

.account-history-heading h5 span.heading-tag-history {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.022rem;
    color: var(--Gray-3-color);
    display: inline-block;
    width: 52%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
}

/*.ant-form.main-inner-form .ant-select.ant-select-show-arrow .ant-select-selection-placeholder {*/
/*    padding-left: 15px;*/
/*}*/

.ant-form.main-inner-form
.ant-select.ant-select-show-arrow.ant-select-show-search
.ant-select-selection-placeholder {
    padding-left: 0;
    line-height: 40px;
}

.ant-collapse.border-add {
    border-bottom: 1px solid #d9d9d9;
}

.create-account {
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 2px;
    height: 28px;
    background: var(--white-color);
    width: 204px;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #333333;
    cursor: pointer;
}

.ant-btn.edit-btn-summary:hover,
.create-account:hover {
    border-color: var(--Green-1-color);
    background: var(--daf2eb-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.ant-btn.edit-btn-summary:focus,
.create-account:focus,
.ant-btn.view-all-btn:focus {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--white-color);
}

.line-items-details-table
.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td,
.line-items-details-table .main-table-all tbody tr td {
    padding: 17px 45px;
}

.ant-tabs.carpet-cleaning-main-common-tab .ant-tabs-content-holder,
.ant-tabs-tabpane .summary-collapse-inner-row-main {
    margin: 0;
}

.associated-history,
.account-type-name {
    display: inline-block;
    width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.associated-history {
    width: 130px;
}

.associated-history-div {
    width: 220px;
}

.count-div-tag {
    width: 100px;
    padding-left: 4px;
    text-transform: lowercase;
}

.documents-table-main .ant-table-thead > tr > th,
.documents-table-main .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: transparent;
    border: 0;
}

.documents-table-main .ant-table-tbody > tr.ant-table-placeholder {
    height: 375px;
}

.documents-table-main .ant-table-tbody > tr.ant-table-placeholder td {
    border: 0;
}

.documents-table-main .ant-table-tbody > tr > td a {
    color: var(--Gray-2-color);
}

.shade-card-inactive-section[disabled] {
    cursor: not-allowed;
}

.shade-card-inactive-section[disabled]:hover {
    border-color: var(--Gray-6-color);
    background: transparent;
}

.shade-card-inactive-section .shade-img-section {
    background: var(--bdbdbd-color);
    opacity: 0.25;
}

.coming-soon-div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 30px;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--Green-2-color);
    background: #ffffffc2;
}

.vehicle-3-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:last-child,
.vehicle-3-center
.ant-table-container
table
> thead
> tr:first-child
th:last-child {
    text-align: center;
}

.border-1 {
    border: 1px solid var(--Gray-5-color);
}

.small-text-tag {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--Gray-3-color);
    position: absolute;
    left: 15px;
    bottom: 0;
}

.no-data-card-row-new .no-data-card-row,
.no-data-card-row-new .no-data-second,
.no-data-card-row-new .no-data-wrapper,
.no-data-card-row-new .no-data-upload-screens,
.no-data-card-row-new .documents-upload-height {
    height: calc(100vh - 310px);
    background: transparent;
    border: 0;
}

.ant-form.main-inner-form .ant-select.ant-select-show-arrow.ant-select-show-search.select-paddingLFT-0 .ant-select-selection-placeholder {
    padding-left: 0 !important;
}

.no-data-card-row-new-table .no-data-upload-screens,
.no-data-card-row-new-table .no-data-second {
    height: calc(100vh - 410px);
}

.design-update-collapse .ant-collapse {
    background: transparent;
    border: 0;
}

.curser-pointer {
    cursor: pointer;
}

.design-update-collapse
.ant-collapse.inner-collapse-section
> .ant-collapse-item {
    margin-bottom: 25px;
}

.customer-account-ifo {
    background: var(--bg-f8-color);
    height: 100px;
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 20px;
}

.documents-upload-height {
    height: 120px;
}

.documents-upload-height .ant-btn {
    height: auto;
}

.main-table-all .ant-table-pagination {
    position: relative;
}

.main-table-all .ant-table-pagination .ant-pagination-total-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    opacity: 0.8;
}

.post-custom-tab .main-table-all .ant-table-pagination .ant-pagination-total-text {
    left: 15px;
}

.small-sidebar .ant-collapse.collapse-sidebar-main .ant-collapse-content {
    display: none;
}

.small-sidebar
.ant-collapse.collapse-sidebar-main
.ant-collapse-item.ant-collapse-item-active {
    background: transparent !important;
}

/*.ant-tabs-dropdown-menu-item {*/
/*    text-transform: capitalize;*/
/*}*/

.edit-create-btn.ant-btn {
    background: #3f4e52;
    border: 1px solid #3f4e52;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 32px;
    width: 174px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: var(--white-color);
}

.edit-create-btn.ant-btn:disabled, .edit-create-btn.ant-btn:disabled:hover {
    opacity: 0.5;
    background: #3f4e52;
    border: 1px solid #3f4e52;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    color: var(--white-color);
}

.measurement-table-custom {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    overflow: hidden;
}

.m-table-thead {
    background: #f9f9f9;
    height: 40px;
    color: var(--Gray-2-color);
    border-bottom: 1px solid var(--Gray-5-color);
}

.m-table-thead,
.m-table-td {
    color: var(--Gray-2-color);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: lowercase;
}

.m-table-td {
    background: #f9fbfb;
    border-right: 1px solid var(--Gray-5-color);
    height: 60px;
    width: 16.66%;
}

.m-table-td:last-child {
    border-right: 0;
}

.margin-tb-30 {
    margin: 30px 0;
}

.unit-measurement-row-drawer {
    padding: 30px 15px;
}

.unit-measurement-row-drawer h4 {
    color: var(--bdbdbd-color);
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
}

.unit-measurement-row-drawer p {
    color: var(--bdbdbd-color);
    letter-spacing: 0.02em;
    font-weight: 500;
    line-height: 20px;
}

.unit-measurement-row-drawer .ant-radio-wrapper small {
    color: var(--bdbdbd-color);
}

.custom-table-main-row.unit-measurement-table {
    width: 100%;
}

.unit-measurement-table .custom-table-cell-th,
.unit-measurement-table .custom-table-cell-td {
    width: 33.33%;
}

.custom-row-heading {
    color: var(--Gray-2-color);
    letter-spacing: -0.08px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    background: var(--Gray-6-color);
    border: 1px solid var(--Gray-5-color);
    border-top: 0;
    width: 100%;
    padding: 10px 15px;
}

.info-card-heading-row-info {
    border: 1px solid var(--Gray-6-color);
    background: #fffefb;
    height: 64px;
    margin-bottom: 30px;
}

.info-icon-card-flag-info {
    background: #ffeaaa;
    width: 86px;
    height: 62px;
}

.info-icon-card-details-info {
    width: calc(100% - 86px);
    padding: 15px;
}

.info-icon-card-details-info h6 {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.measurement-unit-card {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    box-sizing: border-box;
    border-radius: 6px;
    min-height: 115px;
    margin-bottom: 20px;
    overflow: hidden;
}

.unit-card-head {
    background: #f8f8f8;
    border-right: 1px solid var(--Gray-5-color);
    width: 190px;
    padding: 10px 15px;
}

.unit-card-head h6 {
    color: var(--Gray-2-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
}

.unit-card-head h6 img {
    margin-right: 10px;
}

.unit-card-details {
    width: calc(100% - 190px);
    padding: 10px 15px;
}

.unit-card-details h6 {
    color: #a9a7a7;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 20px;
}

.main-inner-form .ant-input-group-compact .ant-input:hover,
.main-inner-form .ant-input-group-compact .ant-input:focus,
.main-inner-form .ant-input-group-compact .ant-select-selector:focus,
.main-inner-form .ant-input-group-compact .ant-select-selector:hover {
    border-color: var(--Gray-5-color) !important;
    outline: 0;
    box-shadow: none !important;
}

.main-inner-form
.ant-input-group-compact
.custom-pound-select
.ant-select-selector {
    border-left: 0 !important;
    border-radius: 0 6px 6px 0 !important;
}

.summary-info-inner-row .unit-card-details .equal-sign,
.measurement-unit-card .equal-sign,
.main-inner-form .equal-sign {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    right: 8px;
    top: 8px;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
}

.more-btn-tag {
    right: 0;
    top: 8px;
}

.main-inner-form h5 {
    color: var(--Gray-1-color);
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin: 30px 0 15px;
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-select-selector {
    background: #f9fbfb;
    height: 60px;
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-select-selector:focus,
.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-select-selector:hover {
    border-color: var(--Gray-5-color);
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-select-selector
.ant-select-selection-item {
    line-height: 60px;
}

.unit-measurement-select.ant-select:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-placeholder {
    line-height: 30px;
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-input-number {
    height: 60px;
    border-radius: 0 0 6px 6px;
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-input-number
.ant-input-number-input {
    height: 60px;
    color: var(--Gray-3-color);
    text-align: right;
}

.summary-info-inner-row
.ant-form.main-inner-form
.custom-unit-converter-row
.ant-input-number
.ant-input-number-input::-webkit-input-placeholder {
    color: var(--Gray-3-color);
}

.summary-info-inner-row .equal-sign {
    top: 50px;
    right: 0;
}

.heading-h4-form {
    color: var(--Gray-4-color);
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin: 15px 0 25px;
}

.lost-row-show {
    margin-top: 15px;
}

.lost-row-show ul {
    margin-bottom: 5px;
}

.summary-collapse-inner-row-main .lost-row-show ul li {
    width: auto;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
}

.summary-collapse-inner-row-main
.lost-row-show
ul
.list-inline-item:not(:last-child) {
    color: var(--Gray-2-color);
}

.summary-info-row-main .summary-line-main.lost-line-main {
    width: 100%;
    color: #eb5757;
    background-color: #fae9b7;
    border-radius: 25px;
}

.summary-info-row-main .summary-line-main.lost-line-main:before {
    display: none;
}

.suggest-price-row {
    align-items: center;
}

.price-estimated-row-table-1 {
    background: #f7f7f7;
    border: 1px solid #e0e0e0;
    margin-top: 1px;
    border-bottom: 0;
}

.price-estimated-row-table-2 {
    background: #f7fefc;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 8px 8px;
}

.price-estimated-row-table-1
.ant-form.main-inner-form
.ant-input.ant-input-disabled::-webkit-input-placeholder {
    color: var(--Gray-2-color);
}

.internal-location-row {
    border: 1px solid var(--Gray-5-color);
    background: #f8f8f8;
    box-sizing: border-box;
    border-radius: 6px;
    height: 100px;
    padding: 15px;
    margin-bottom: 20px;
}

.internal-location-row .inn-heading {
    margin-left: 10px;
    color: var(--Gray-2-color);
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
}

.unit-cost-name {
    color: var(--bdbdbd-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    right: 10px;
    top: 12px;
}

.react-trello-board.custom-data-board .react-trello-lane header > div {
    display: none;
}

.unit-cost-name.unit-uom {
    top: 38px;
    right: 25px;
}

.input-left .ant-input-number .ant-input-number-input {
    text-align: left;
}

.ant-btn.btn-dropdown-elipsis {
    right: 15px;
    top: 0;
    bottom: 0;
}

.col-12-12 .location-row-main:last-child {
    border: 0;
}

.location-row-main {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--Gray-6-color);
    margin-bottom: 30px;
}

.qty-location-ul li {
    color: var(--Gray-2-color);
    letter-spacing: 0.01em;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 0;
}

.main-table-all table tbody tr td .ant-breadcrumb {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.qty-location-ul li span {
    opacity: 0.8;
    border-left: 1px solid var(--Gray-5-color);
    padding-left: 10px;
    margin-left: 10px;
}

.pricing-summary-table .main-table-all table thead tr th {
    min-width: 120px;
}

.pricing-summary-table .main-table-all table thead tr th:nth-child(1) {
    min-width: 200px;
}

.pricing-summary-table .main-table-all table thead tr th:nth-child(2) {
    min-width: 340px;
}

.pricing-summary-table .main-table-all table tbody tr td:nth-child(3),
.pricing-summary-table .main-table-all table tbody tr td:nth-child(4),
.pricing-summary-table .main-table-all table tbody tr td:nth-child(5) {
    text-align: right;
}

.custom-uom-dropdown .ant-select-item {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: var(--Gray-2-color);
    padding: 15px 10px;
}

.custom-uom-dropdown .ant-select-item-option-content {
    display: flex;
    align-items: center;
}

.custom-uom-dropdown .ant-select-item.ant-select-item-group {
    color: var(--bdbdbd-color);
}

.check-icon-mark {
    width: 0;
    margin-right: 5px;
    transition: 0.3s ease all;
}

.custom-uom-dropdown .ant-select-item.ant-select-item-option-active,
.custom-uom-dropdown .ant-select-item:hover {
    background: var(--Gray-6-color);
}

.custom-uom-dropdown .ant-select-item.ant-select-item-group:hover {
    background: transparent;
}

.custom-uom-dropdown
.ant-select-item.ant-select-item-option-active
.check-icon-mark {
    width: 20px;
}

.add-uom-details {
    color: var(--bdbdbd-color);
    letter-spacing: -0.24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    top: 0;
    bottom: 0;
    right: 15px;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.custom-uom-table .table tr th {
    background: #f9fbfb;
    border: 1px solid #e0e0e0;
    color: var(--Gray-2-color);
    letter-spacing: -0.08px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    padding: 20px 15px;
    text-align: center;
    float: left;
    width: 50%;
    border-bottom: 0;
}

.custom-uom-table .table {
    border: 0;
}

.custom-uom-table .table tr th:first-child {
    border-radius: 8px 0 0 0;
    border-right: 0;
}

.custom-uom-table .table tr th:last-child {
    border-radius: 0 8px 0 0;
}

.custom-uom-table .table tr.uom-table-small-header td {
    background: var(--Gray-6-color);
    border: 1px solid #e0e0e0;
    color: var(--Gray-2-color);
    letter-spacing: -0.08px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 15px;
    width: 100%;
}

.custom-uom-table thead,
.custom-uom-table tbody {
    width: 100%;
}

.custom-uom-table thead,
.custom-uom-table tbody,
.custom-uom-table tr,
.custom-uom-table td,
.custom-uom-table th {
    display: block;
}

.custom-uom-table .table tbody {
    overflow: auto;
    min-height: auto;
    max-height: 345px;
}

.custom-uom-table .table tr td {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    float: left;
    width: 50%;
    letter-spacing: -0.24px;
    color: var(--Gray-3-color);
    background: var(--white-color);
    border: 1px solid #e0e0e0;
    padding: 20px 15px;
    border-top: 0;
}

.custom-uom-table .table tr td:first-child {
    border-right: 0;
}

.custom-uom-table .table:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 8px;
}

.custom-uom-table .table:last-child tr:last-child td:last-child {
    border-radius: 0 0 8px 0;
}

.hr-line {
    border: 1px solid var(--Gray-6-color);
    margin: 15px 0;
    width: 100%;
}

.service-line-item {
    background: #f9fbfb;
    padding: 15px 30px;
}

.priority-card-tag {
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.cost-symbol {
    position: absolute;
    right: 25px;
    top: 45px;
    display: flex;
    align-items: center;
    color: var(--bdbdbd-color);
    font-weight: 500;
    font-size: 15px;
}

.remove-padding-placeholder .ant-select-selection-placeholder {
    padding: 0 !important;
}

.custom-th-main {
    width: 33.33%;
    border: 1px solid var(--Gray-5-color);
    background: #f9fbfb;
    min-height: 60px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: var(--Gray-2-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.custom-th-main:first-child {
    border-top-left-radius: 6px;
    border-right: 0;
}

.custom-th-main:last-child {
    border-top-right-radius: 6px;
    border-left: 0;
}

.custom-td {
    width: 33.33%;
    border: 0;
    padding: 0;
}

.custom-table-tbody .custom-td .ant-input-number {
    border: 1px solid var(--Gray-5-color);
    min-height: 60px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--Gray-2-color);
    border-top-color: transparent;
    border-radius: 0;
    width: 100%;
}

.custom-table-tbody .custom-td .ant-input-number:hover {
    color: var(--Green-1-color);
    border-color: var(--Green-1-color);
}

.custom-table-tbody
.custom-td
.ant-input-number.ant-input-number-disabled:hover {
    border-color: var(--Gray-5-color);
    border-top-color: transparent;
    color: var(--Gray-2-color);
}

.custom-table-tbody
.custom-td:last-child
.ant-input-number.ant-input-number-disabled:hover {
    border-left-color: transparent;
}

.custom-table-tbody
.custom-td:first-child
.ant-input-number.ant-input-number-disabled {
    border-right-color: transparent;
}

.custom-table-tbody .custom-td:first-child .ant-input-number {
    border-bottom-left-radius: 6px;
    border-right-color: transparent;
}

.custom-table-row
.custom-table-cell-td:hover
.ant-input-number.ant-input-number-disabled {
    border-color: transparent !important;
}

.custom-table-row
.custom-table-cell-td:hover
.ant-input-number.ant-input-number-disabled
~ .pencil-btn-edit {
    opacity: 0;
}

.custom-table-tbody .custom-td:last-child .ant-input-number {
    border-bottom-right-radius: 6px;
    border-left-color: transparent;
}

.custom-td:last-child .ant-input-number:hover {
    border-left-color: var(--Green-1-color);
}

.custom-td:first-child .ant-input-number:hover {
    border-right-color: var(--Green-1-color);
}

.custom-td .ant-input-number .ant-input-number-input {
    text-align: left;
    padding: 0 15px;
    height: 58px;
}

.custom-internal-location {
    margin-top: 15px;
}

.radio-btn-custom .ant-radio-wrapper {
    background: #f9fbfb;
    height: 36px;
    margin: 0;
    width: 100%;
    padding: 8px 15px;
    font-size: 12px;
}

.status-tag {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 7px;
    position: relative;
    top: 1px;
}

.radio-btn-custom .ant-radio-wrapper.ant-radio-wrapper-checked {
    background: var(--daf2eb-color);
}

.radio-btn-custom {
    top: 1px;
}

.main-inner-form .info-icon-card-details-info ul {
    padding-left: 15px;
}

.main-inner-form .info-icon-card-details-info ul li {
    text-align: left;
    padding: 0;
    color: var(--Gray-3-color);
    letter-spacing: 0.02em;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
}

.proposals-table-line-item
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td {
    background: #eaf1ef;
}

.proposals-table-line-item
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td.wage-info-collapse-td {
    background: var(--daf2eb-color);
}

.custom-row-space {
    margin: 0;
}

.padding-left-0 .ant-select-selection-placeholder {
    padding-left: 0 !important;
}

.shadow-card-box {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.show-arrow-false .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.show-arrow-false .ant-select .ant-select-selector {
    padding: 0 15px 0 30px;
}

.global-search-cats .ant-collapse-content-box {
    padding: 0;
}

.highlighted-search {
    background-color: var(--daf2eb-color) !important;
}

.highlighted-search:hover {
    background-color: var(--daf2eb-color) !important;
}

.editalble-form-data-select .form-control {
    border: transparent;
}

.editalble-form-data-select .editabel-form-image {
    padding: 0 !important;
}
.editalble-form-data.editalble-form-data-select
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    background: var(--white-color) !important;
    padding-left: 15px;
}

.editalble-form-data.editalble-form-data-select .ant-select-arrow {
    display: block;
}

.footer-data-main,
.show-when-collapsed {
    transition: 0.3s ease all;
}

.table-footer--2-row, .table-footer--3-row, .table-footer--1-row {
    background: #f7fefc;
    min-height: 56px;
    padding: 17px 0;
}

.table-footer--1-row {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.table-footer--3-row {
    background: #F7FEFC;
    border-top: 1px solid #e0e0e0;
    padding-bottom: 37px;
}

.theme-color {
    color: var(--Green-1-color) !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

ul.pricing-estimated-ul li small {
    color: var(--Gray-3-color);
    letter-spacing: 0.02em;
    font-weight: normal;
    font-size: 9px;
    line-height: 17px;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 150px;
}

.table-footer--1-row ul.pricing-estimated-ul li,
.table-footer--2-row ul.pricing-estimated-ul li,
.table-footer--1-row ul.pricing-estimated-ul li span,
.table-footer--2-row ul.pricing-estimated-ul li span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.table-footer--1-row ul.pricing-estimated-ul li {
    color: #4f4f4f;
}

.footer-data-main-update .table-footer--1-row {
    background: var(--white-color);
}

.footer-data-main-update .table-footer--2-row {
    background: var(--Gray-6-color);
}

.footer-data-main-update .table-footer--1-row ul.pricing-estimated-ul li.list-inline-item:not(:last-child),
.footer-data-main-update .table-footer--2-row ul.pricing-estimated-ul li.list-inline-item:not(:last-child),
.footer-data-main-update .table-footer--3-row ul.pricing-estimated-ul li.list-inline-item:not(:last-child) {
    border-right: 1px solid #BDBDBD;
    padding-right: 0.5rem;
}

.footer-data-main-update .table-footer--1-row ul.pricing-estimated-ul li:nth-child(1),
.footer-data-main-update .table-footer--2-row ul.pricing-estimated-ul li:nth-child(1),
.footer-data-main-update .table-footer--3-row ul.pricing-estimated-ul li:nth-child(1) {
    border-right: 0 !important;
}

.table-footer--1-row ul.pricing-estimated-ul li:nth-child(1),
.table-footer--3-row ul.pricing-estimated-ul li:nth-child(1) {
    width: 15%;
}

.table-footer--2-row ul.pricing-estimated-ul li:nth-child(1) {
    width: 15%;
    color: #4f4f4f;
}

.ant-collapse-item
.ant-collapse-header[aria-expanded="false"]
.show-when-collapsed {
    opacity: 1;
}

.ant-collapse-item
.ant-collapse-header[aria-expanded="true"]
.show-when-collapsed {
    opacity: 0;
}

.ant-table-thead > tr > th {
    text-transform: uppercase;
}

.sub-heading-search {
    color: var(--bdbdbd-color);
    padding-left: 20px;
    font-size: 13px;
}

/* ......contacts->accounts_info... */
.contacts-account-empty {
    background: var(--white-color);
}

.contacts-opportunity-empty {
    background: var(--white-color);
}

.contacts-account-empty h6 {
    color: var(--bdbdbd-color);
    font-size: 13px;
}

.contacts-opportunity-empty h6 {
    color: var(--bdbdbd-color);
    font-size: 13px;
}

.sub-head-search-sidebar {
    color: var(--bdbdbd-color);
    padding-left: 35px;
    font-size: 13px;
    font-weight: 600;
}

.add-dollar {
    z-index: 999;
    left: 25px;
    top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-2-color);
}

.add-dollar.add-dollar-2 {
    top: 47px;
    left: 25px;
    color: var(--Gray-4-color);
}

.vendor-dollar {
    z-index: 999;
    left: 12px;
    top: 9px;
    font-size: 15px;
    font-weight: 500;
    color: var(--Gray-2-color);
}

.add-dollar-main-item.ant-form-item
.ant-form-item-control
.ant-form-item-control-input
.ant-input-number-input[value] {
    color: var(--Gray-1-color);
}

.color-change ~ .add-dollar.add-dollar-2 {
    color: var(--Gray-1-color);
}

.editalble-form-data
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
.ant-select-selector,
.editalble-form-data .ant-input-number-disabled .ant-input-number-input {
    cursor: default;
}

/* .editalble-form-data.editalble-form-data-select  */
body
.editalble-form-data.editalble-form-data-select
.ant-select.ant-select-disabled
.ant-select-selector,
body .ant-input-number-disabled .ant-input-number-input {
    background: #f9f9f9 !important;
}

.line-item-grid .custom-table-cell-td:first-child {
    width: 298px;
}

.line-item-grid .custom-table-cell-td:nth-child(2) {
    width: 8.4%;
    justify-content: center;
    text-align: center;
}

.line-item-grid .custom-table-cell-td:nth-child(3) {
    width: 9.2%;
}

.proposal-update-table .line-item-grid .custom-table-cell-td:nth-child(3) {
    width: 9.16%;
}

.proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4),
.proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5),
.proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6) {
    width: 10.46%;
}

.proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7),
.proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(8) {
    width: 8.42%;
}


.line-item-grid .custom-table-cell-td:nth-child(4),
.line-item-grid .custom-table-cell-td:nth-child(5),
.line-item-grid .custom-table-cell-td:nth-child(6) {
    width: 107px;
    justify-content: center;
}

/* ........ Contacts search field(site owner and billing acc)........ */
.site-owner-contact .ant-select-selection-placeholder {
    margin: 0 0 0 2%;
}

.customer-contact .ant-select-selection-placeholder {
    margin: 0 0 0 2%;
}

/* ................................ */
.resource-type-title {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #38bc94;
    display: flex;
    align-items: center;
    text-align: right;
}

.custom-inventory-table-create .ant-select {
    width: 100%;
}

.ant-form.main-inner-form
.custom-inventory-table-create
.name-id-details
.ant-input-number {
    height: 40px;
}

.custom-inventory-table-create .name-id-details {
    width: 100%;
}

.validate-div-col .ant-btn.validate-btn-main-new {
    color: #20cb97;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    background: var(--white-color);
    border-radius: 4px;
    height: 44px;
    border: 1px solid #20cb97;
    width: 228px;
    /* opacity: 0.5; */
}

.validate-div-col .ant-btn.validate-btn-main-new:hover {
    background: var(--daf2eb-color);
    border: 1px solid var(--Green-1-color);
    opacity: 1;
}

.checkbox-div-main {
    right: 0;
    top: 0;
}

.ant-form-item-label
> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.view-dropdowns-tbl .custom-table-cell-td.custom-table-cell-td-name-info,
.view-dropdowns-tbl .custom-table-cell-td.custom-table-cell-td-labor-group {
    background-color: #f9f9f9 !important;
}

.ant-form.main-inner-form .gray-2-color .ant-input-number {
    background: var(--Gray-6-color);
}

.custom-collapse-line-item.custom-table-row-level-1.line-item-grid
.custom-table-cell-td {
    padding-left: 45px;
}

.ant-form.main-inner-form .custom-collapse-line-item .ant-input-number-input {
    text-align: center;
}

.create-opportunity-row
.ant-collapse
> .ant-collapse-item.ant-collapse-item-disabled {
    opacity: 0.5;
    border-color: var(--Gray-5-color);
}

.create-opportunity-row .ant-collapse-item-disabled .ant-collapse-header:hover .info-card-heading-row h5 {
    color: var(--Gray-3-color);
}

.post-opacity td,
.post-opacity td .post-checkbox-opacity > span:last-child {
    opacity: 0.5;
}

.post-opacity td:first-child {
    opacity: 1;
}

.post-opacity td:first-child .ant-checkbox-inner {
    opacity: 1;
}

/* .post-checkbox-opacity {
    opacity: 1;
} */
.ant-select-item-group {
    background: var(--Gray-6-color);
    color: var(--bdbdbd-color);
    height: 48px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-grouped {
    height: 48px;
    color: #4f4f4f;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-active,
.ant-select-item.ant-select-item-option.ant-select-item-option-grouped:hover {
    background: #fafafa;
}

.info-gray-div {
    background: var(--Gray-6-color);
    border: 1px solid var(--Gray-6-color);
    border-left: 6px solid #c4c4c4;
    min-height: 39px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 15px;
    z-index: 9;
}

.info-gray-div h6 {
    color: #828282;
    letter-spacing: 0.02em;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
}

.fleet-kit-label {
    color: #ff0000;
}

.custom-design-update {
    border: 1px solid var(--Gray-5-color);
    border-radius: 0 0 8px 8px;
    padding: 20px 0;
    border-top: none;
}

.radius-bottom-0 {
    border-radius: 8px 8px 0 0;
}

.small-text-input {
    position: relative;
    top: -14px;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    letter-spacing: 0.07px;
    color: var(--Gray-3-color);
}

.Quantities-details-div {
    background: #f7fefc;
    padding: 15px 20px;
    margin-top: 30px;
    min-height: 100px;
}

.Quantities-details-div.Quantities-details-div-vendor {
    min-height: 270px;
}

.Quantities-details-div h6 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #a9a7a7;
}

.Quantities-details-div ul li {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #333333;
}

.Quantities-details-div ul li.list-inline-item:not(:last-child) {
    width: 27%;
}

.Quantities-details-div.Quantities-details-div-vendor ul li.list-inline-item:not(:last-child) {
    width: 45%;
}

.margin-btn-30 {
    margin-bottom: 30px;
}

.margin-btn-15 {
    margin-bottom: 15px;
}

.vendor-details-with-warehouse-div {
    padding-top: 20px;
    border-left: 1px solid var(--Gray-5-color);
    border-right: 1px solid var(--Gray-5-color);
    border-bottom: 1px solid var(--Gray-5-color);
    border-radius: 0 0 8px 8px;
}

.ant-modal.main-all-form-modal.modal-disposal .ant-modal-content {
    border-radius: 0;
    padding-bottom: 30px;
}

.ant-modal.main-all-form-modal.modal-disposal
.ant-modal-content
.ant-modal-header {
    background: #828282;
}

.ant-modal.main-all-form-modal.modal-disposal
.ant-modal-content
.ant-modal-header
.ant-modal-title {
    color: var(--white-color);
}

.common-form.warehouse-select-modal .ant-form-item .ant-form-item-label label {
    color: var(--Gray-1-color);
}

.warehouse-select-modal
.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    box-sizing: border-box;
    border-radius: 6px;
    height: 40px;
}

.warehouse-select-modal .ant-select-selection-placeholder {
    color: var(--bdbdbd-color);
    font-size: 15px;
    font-weight: 500;
}

.warehouse-select-modal
.ant-select-single.ant-select-show-arrow
.ant-select-selection-search {
    right: 105px;
    left: 40px;
}

.warehouse-select-modal
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
    height: 38px;
}

.warehouse-select-modal .ant-btn.search-icon {
    left: 20px;
    top: 65px;
    box-shadow: none;
}

.warehouse-select-modal .ant-btn.create-btn-main {
    top: 63px;
}

.modal-disposal.ant-modal.main-all-form-modal
.ant-modal-content
.ant-modal-header:before {
    display: none;
}

.ant-modal.main-all-form-modal.modal-disposal
.ant-modal-content
.ant-modal-close {
    display: block;
    color: var(--white-color);
    top: 5px;
}

.ant-modal.main-all-form-modal.modal-disposal
.ant-modal-content
.ant-modal-close
.anticon {
    font-size: 20px;
    font-weight: 600;
}

.ant-modal.main-all-form-modal.design-update-modal
.ant-modal-content
.ant-modal-header,
.main-drawer-div.main-all-form-modal.drawer-update-design .ant-drawer-header,
.drawer-update.main-drawer-div.main-all-form-modal .ant-drawer-header {
    background: #828282;
}

.main-all-form-modal.drawer-update-design .ant-drawer-title,
.ant-modal.main-all-form-modal.design-update-modal
.ant-modal-content
.ant-modal-header
.ant-modal-title,
.drawer-update.main-all-form-modal .ant-drawer-title,
.main-drawer-div.drawer-update-design .ant-drawer-close,
.drawer-update.main-drawer-div .ant-drawer-close {
    color: var(--white-color);
    text-transform: capitalize;
}

.main-drawer-div.main-all-form-modal.drawer-update-design
.ant-drawer-header:before,
.ant-modal.main-all-form-modal.design-update-modal
.ant-modal-content
.ant-modal-header:before,
.drawer-update.main-drawer-div.main-all-form-modal .ant-drawer-header:before {
    display: none;
}

.divider-row {
    border-bottom: 1px solid var(--Gray-5-color);
    margin-bottom: 15px;
    color: var(--Gray-3-color);
    padding: 2px 0;
}

.pricing-group .ant-select-selection-placeholder,
.custom-table-cell-td-name-info
.editalble-form-data
.ant-select-selection-placeholder {
    color: red !important;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.divider-line {
    border-bottom: 1px solid var(--Gray-5-color);
    margin-bottom: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* .....required fields success/warning modals..... */
.required-fields-success-modal .ant-btn {
    background: var(--primary-color) !important;
}

.required-fields-success-modal .ant-btn.ant-btn-primary {
    background: var(--Green-1-color) !important;
}

.required-fields-warning-modal .ant-btn {
    background: var(--primary-color) !important;
}

.required-fields-warning-modal .ant-btn:hover {
    background: var(--Green-1-color) !important;
}

.required-fields-warning-modal .ant-btn.ant-btn-primary {
    background: red !important;
}

.required-fields-warning-modal .ant-btn.ant-btn-primary:hover {
    background: rgb(238, 104, 104) !important;
}

/* ........UnsavedExit PopUP........ */
.unsavedexitprompt-modal .ant-btn {
    background: #3f4e52 !important;
    color: var(--white-color) !important;
}

.unsavedexitprompt-modal .ant-btn:hover {
    background: var(--Green-1-color) !important;
    color: var(--white-color) !important;
}

.unsavedexitprompt-modal .ant-btn.ant-btn-primary {
    background: #bd5050 !important;
}

.unsavedexitprompt-modal .ant-btn.ant-btn-primary:hover {
    background: rgb(238, 104, 104) !important;
}

/* ........................ */
.tree-heading.tree-heading-custom {
    border-bottom: 1px solid var(--Gray-5-color);
}

.divider-line-div {
    border: 4px solid var(--Gray-5-color);
    margin: 15px 0;
    width: 100%;
}

.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main {
    background: transparent;
    border: 0;
}

.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
> .ant-collapse-item {
    margin-bottom: 15px;
    border: 1px solid var(--Gray-5-color);
    background: var(--daf2eb-color);
    border-radius: 6px;
}

.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
> .ant-collapse-item
.ant-collapse-header {
    padding: 16px;
    color: var(--Gray-1-color);
}

.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
> .ant-collapse-item
.ant-collapse-header:hover {
    background: var(--daf2eb-color) !important;
}

.ant-collapse.vehicle-group-collapse-main.dry-group-collapse-main
.ant-collapse-content
> .ant-collapse-content-box {
    padding: 0;
}

.taxable-nontaxable-div {
    padding: 15px 45px;
    border-bottom: 1px solid var(--Gray-5-color);
}

.taxable-nontaxable-div h6 {
    letter-spacing: 0.07px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #a9a7a7;
}

.taxable-nontaxable-div p {
    color: var(--Gray-2-color);
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
}

.small-heading-in-form p {
    color: var(--Gray-4-color);
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

.main-inner-form
.ant-input-group-compact
.ant-select.bg-gray
.ant-select-selector {
    background: var(--Gray-6-color);
    border-color: var(--Gray-5-color);
    border-left: 1px solid var(--Gray-5-color) !important;
    border-radius: 4px;
}

.custom-uom-table.custom-uom-table-2 .table tr th,
.custom-uom-table.custom-uom-table-2 .table tr td {
    width: 33.33%;
    position: relative;
}

.custom-uom-table.custom-uom-table-2 .table tr td {
    border-top: 1px solid #e0e0e0;
}

.warehouse-select-box {
    width: calc(100% - 120px);
    white-space: break-spaces;
    display: inline-block;
}

.custom-table-change .custom-table-cell-th {
    background: #3f4e52;
}

.custom-table-change .custom-th-heading {
    color: var(--white-color);
}

.custom-table-change
.custom-table-collapse-main.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.custom-table-row
.custom-table-cell-td:nth-child(1) {
    background: var(--daf2eb-color);
    color: var(--Gray-2-color);
}

.custom-table-main-row-wage-info-main
.line-item-grid
.custom-table-cell-td.wage-info-collapse-td {
    width: 33.1%;
}

.custom-table-main-row-wage-info-main.proposal-update-table
.line-item-grid
.custom-table-cell-td.wage-info-collapse-td {
    width: 33.15%;
}
.proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.proposal-update-table-edit{
    width: 1550px;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-th.custom-table-cell-th-2{
    width: 11.6%;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-th,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td{
    width: 10.6%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4){
    width: 9.1%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5),
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6){
    width: 10.6%;
}
.proposal-update-table.proposal-update-table-edit .line-item-grid .custom-table-cell-td:nth-child(3),
.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .custom-table-cell-td:nth-child(4){
    width:8.5%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7){
    width: 10.6%;
}
.custom-table-main-row-wage-info-main.proposal-update-table.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-9 {
    width: 8.4%;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td:nth-child(6){
    width: 10.5%;
}
.custom-table-main-row-wage-info-main.proposal-update-table.proposal-update-table-edit .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
    width: 23.15%;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-1,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-2{
    width: 11.6%;
}



.custom-table-main-row-wage-info-main
.custom-table-change
.custom-table-cell-td.background-white-div,
.custom-table-main-row-wage-info-main
.custom-table-change
.custom-table-cell-td.custom-table-cell-td-1,
.custom-table-main-row-wage-info-main
.custom-table-change
.custom-table-cell-td:nth-child(1) {
    background: #f7fefc;
}

.custom-table-main-row-wage-info-main
.service-variants-table
.custom-table-cell-th:nth-child(4),
.custom-table-main-row-wage-info-main
.service-variants-table
.custom-table-cell-td:nth-child(4) {
    width: 8.41%;
}

.custom-table-main-row-wage-info-main
.service-variants-table
.custom-table-cell-th:nth-child(5),
.custom-table-main-row-wage-info-main
.service-variants-table
.custom-table-cell-td:nth-child(5) {
    width: 9.16%;
}

.no-data-card-row-new.billing-account-data .no-data-card-row {
    height: calc(100vh - 430px)
}

.custom-table-main-row-wage-info-main
.service-variants-table
.line-item-grid
.custom-table-cell-td:nth-child(4),
.custom-table-main-row-wage-info-main
.service-variants-table
.line-item-grid
.custom-table-cell-td:nth-child(5),
.service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6),
.custom-table-main-row-wage-info-main
.custom-table-cell-th.custom-table-cell-th-9 {
    width: 8.41%;
}

.custom-table-main-row-wage-info-main
.custom-table-cell-th.custom-table-cell-th-9.wage-info {
    width: 10.80%;
}

.custom-table-main-row-wage-info-main.proposal-update-table .custom-table-cell-td.custom-table-cell-td-9 {
    width: 8.45%;
}

.custom-table-main-row-wage-info-main.proposal-update-table .custom-table-cell-td:last-child {
    width: 8.4%;
    justify-content: flex-start;
}

.custom-table-main-row-wage-info-main.proposal-update-table .custom-table-cell-td:last-child span {
    padding: 0 15px !important;
}

.custom-table-main-row-wage-info-main.proposal-update-table .custom-table-cell-td:last-child > div {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.custom-table-main-row.custom-table-main-row-proposal-line-item {
    width: 1147px;
}

.proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item {
    width: 1300px;
    overflow: initial;
}
.proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.service-v-update{
    width: 1500px;
}

.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th.custom-table-cell-th-9,
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.custom-table-cell-td-1,
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.custom-table-cell-td-9 {
    width: 9.206%;
}
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th,
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td {
    width: 8.463%;
}
.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .custom-table-cell-th:nth-child(4),
.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .custom-table-cell-td:nth-child(4),
.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .custom-table-cell-th:nth-child(5),
.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .custom-table-cell-td:nth-child(5){
    width: 7.41%;
}
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th:nth-child(6),
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th:nth-child(6),
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th:nth-child(7),
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td:nth-child(7){
    width: 7.62%;
}
.custom-table-main-row-wage-info-main.proposal-update-table.service-v-update .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
    width: 41%;
}
.custom-table-main-row-wage-info-main.proposal-update-table.service-v-update .line-item-grid.disposal-added .custom-table-cell-td.wage-info-collapse-td{
    width: 26%;
}
.update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid.disposal-added .custom-table-cell-td.wage-info-collapse-td{
    width: 53.3%;
}
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.background-white-div,
.custom-table-main-row-wage-info-main.service-v-update .disposal-added .custom-table-cell-td.background-white-div {
    width: 7.53%;
}
.proposal-update-table.service-v-update .line-item-grid.disposal-added .custom-table-cell-td:nth-child(3),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(4){
    width: 7.5%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(5){
    width: 7.6%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(6),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(7){
    width: 8.5%;
    justify-content: center;
}
.custom-table-main-row-wage-info-main.service-v-update .disposal-added .custom-table-cell-td{
        width: 9.2%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(8){
    width: 8.45%;
    justify-content: center;
}
.proposal-update-table.service-v-update .line-item-grid .custom-table-cell-td:nth-child(3) {
    width: 7.65%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6) {
    width: 8.46%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(8) {
    width: 9.2%;
}
.custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td:nth-child(6){
    width: 7.6%;
}
.custom-table-main-row-wage-info-main.proposal-update-table.service-v-update .custom-table-cell-td:last-child {
    width: 9.2%;
    justify-content: flex-start;
}
.service-v-update .ant-select.edit-select-box,
.main-inner-form .service-v-update .custom-table-cell-td .edit-select-box.ant-select .ant-select-selector,
.ant-form.main-inner-form .service-v-update .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 100%;
}
.service-v-update .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder, .custom-table-cell-td .ant-select-single.ant-select-show-arrow.edit-select-box .ant-select-selection-placeholder {
    line-height: 57px;
    text-align: left;
    color: #000;
}
.disposal-added .custom-table-cell-td .ant-select-single.ant-select-show-arrow.edit-select-box .ant-select-selection-placeholder{
    padding-left: 0;
}
.disposal-added .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 59px;
    border-color: transparent;
}
.disposal-added .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--Green-1-color);
}






.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr.is-required
> td,
.main-table-all tbody tr.is-required td {
    background-color: #fef6f6;
}

.vehicle-table-text-center
.main-table-all
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:nth-child(2) {
    font-weight: 500;
}

.is-required .red-text-disposal {
    font-style: italic;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.07px;
    color: #eb5757;
}

.red-text-workorder {
    font-style: italic;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.07px;
    color: #eb5757;
}

.info-gray-div.info-red-div {
    background-color: #fef6f6;
    border-left-color: #eb5757;
    position: absolute;
    top: 47px;
    left: 47px;
    width: 92.7%;
    margin: 0;
}

.user-name-tg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--Green-2-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: var(--white-color);
}

.custom-tree-row-1 .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drawer-unsaved-modal {
    z-index: 99999 !important;
}

.ant-form.main-inner-form
.custom-search-select.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    padding-left: 35px;
}

.ant-select-single.custom-search-select
.ant-select-selector
.ant-select-selection-search {
    left: 35px;
}

.ant-dropdown-link.cancel-red-btn.cancel-btn-treed-flex {
    color: #eb5757;
    width: 66px;
    height: 20px;
    border: 0;
    line-height: 12px;
    background: var(--Gray-6-color);
    border-radius: 20px;
}

.drawer-required-success-modal .ant-modal-footer .ant-btn {
    width: 0 !important;
    height: 0 !important;
    display: none;
}

.-btn-cancel-none.drawer-required-success-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    width: 100% !important;
    height: 50px !important;
}

.drawer-required-success-modal .ant-modal-footer .ant-btn.ant-btn-primary {
    width: 100%;
    display: inline-block;
}

.option-icon {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
}

.option-txt {
    width: 89%;
    display: inline-block;
}

.option-txt .custom-select-drop-main .custom-tree-row {
    padding: 7px;
}

.cursor-not-allow {
    cursor: not-allowed;
    color: var(--Gray-3-color);

}

.custom-search-select .ant-select-item-option {
    border-bottom: 1px solid var(--Gray-5-color);
}

.custom-table-main-row.inventory-items-table {
    width: 100%;
}

.custom-table-main-row-wage-info-main.inventory-items-table
.custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main.inventory-items-table
.custom-table-cell-td.custom-table-cell-td-1 {
    width: 140px;
}

.custom-table-main-row-wage-info-main.inventory-items-table
.custom-table-cell-th.custom-table-cell-th-2,
.custom-table-main-row-wage-info-main.inventory-items-table
.custom-table-cell-td.custom-table-cell-td-2 {
    width: 200px;
}

.inventory-items-table .custom-table-cell-th,
.inventory-items-table .custom-table-cell-td,
.custom-table-main-row-wage-info-main.inventory-items-table
.custom-table-cell-td {
    width: 120px;
}

.ant-dropdown-link.dropdown-row-a {
    position: absolute;
    top: 19px;
    right: 5px;
}

.ant-dropdown.inventory-kit-dropdown .ant-dropdown-menu {
    background: var(--white-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.ant-dropdown.inventory-kit-dropdown .ant-dropdown-menu-item a {
    color: var(--Gray-2-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}

.editalble-form-data.caret-arrow-hide .search-btn-icon {
    display: none;
}

.custom-tree-structure.ant-tree .ant-tree-treenode.filter-node {
    background: #fef6f6;
}

.custom-tree-structure.custom-tree-structure-update.ant-tree
.ant-tree-treenode.filter-node:hover,
.custom-tree-structure.ant-tree
.filter-node
.ant-tree-node-content-wrapper:hover {
    background: #fef6f6;
}

/* ...delete-item modal.. */
.confirmation-popup-modal.ant-modal.delete-item-modal
.ant-modal-footer
.ant-btn {
    width: 50%;
    display: inline-block;
}

.confirmation-popup-modal.ant-modal.delete-item-modal
.ant-modal-footer
.ant-btn.ant-btn-primary {
    width: 50%;
    display: inline-block;
}

/* .............. */
.remove-tree-bottom-line:before {
    display: none;
}

.option-design-fix .ant-select-item {
    padding: 10px 20px;
    border-bottom: 1px solid var(--Gray-5-color);
}

.dropdown-fixed .ant-select-selection-item {
    display: none;
}

.option-design-fix .ant-select-item:last-child {
    border: none;
}

.disabledBtnStyle.ant-btn[disabled],
.disabledBtnStyle.ant-btn[disabled]:hover,
.disabledBtnStyle.ant-btn[disabled]:focus,
.disabledBtnStyle.ant-btn[disabled]:active {
    background: #b7b7b7 !important;
    border-color: #b7b7b7 !important;
}

.ant-form.main-inner-form
.uom-select-fixes
.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
    padding-left: 15px;
    width: 88%;
}

.ant-form.main-inner-form
.editalble-form-data.uom-select-fixes
.ant-select-single
.ant-select-selector
.ant-select-selection-item {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.opportunity-proposal-account-main-row {
    border: 1px solid var(--Gray-5-color);
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 30px;
    margin-top: 15px;
}

.opportunity-proposal-account-main-row .site-details-row-card.no-data-card-row {
    background: transparent;
    border-radius: 0;
    border: none;
    min-height: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.opportunity-proposal-account-main-row .site-details-row-card > div {
    padding: 0;
}

.home-labor-group-employee-view {
    top: initial;
}

.home-group-row-div-bg {
    background: #f7fefc;
    border-color: var(--Green-1-color);
}

.main-drawer-div .ant-btn.ant-btn-primary.continue-green {
    width: 40%;
    background-color: var(--Green-1-color);
}

.ant-select.select-pdf-options {
    width: 100%;
    margin-top: 8px;
}

.select-pdf-options.ant-select:not(.ant-select-customize-input)
.ant-select-selector {
    background: #3f4e52;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 36px;
    width: 100%;
    border: none;
}

.ant-select.select-pdf-options .ant-select-selection-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: var(--white-color);
}

.ant-select.select-pdf-options .ant-select-arrow {
    color: var(--white-color);
}

.pdf-icon-tg {
    left: 30px;
    top: 14px;
}

.pdf-select-view .ant-select-item {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: var(--Gray-2-color);
    padding: 8px 12px;
    background: var(--white-color);
}

.inner-modal-main.main-all-form-modal.design-update-modal .ant-modal-body {
    padding: 30px;
}

.summary-info-inner-row
.account-contact-collapse-div.account-contact-update
.ant-collapse
> .ant-collapse-item,
.account-contact-collapse-div.account-contact-update
.ant-collapse
> .ant-collapse-item {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    background: var(--white-color);
}

.opportunity-customer-div.account-contact-update .ant-collapse-header:hover {
    background: var(--white-color) !important;
}

.summary-info-inner-row
.account-contact-collapse-div.account-contact-update
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header {
    padding: 12px 60px 12px 0;
}

.opportunity-customer-div.account-contact-update
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
    left: auto;
    right: 30px;
    top: 45%;
    color: #828282;
}

.summary-info-inner-row
.account-contact-collapse-div.account-contact-update
.site-owner-collapse-main
.ant-collapse-content,
.account-contact-collapse-div.account-contact-update .ant-collapse-content {
    background: #f7fefc;
    padding: 0 30px 20px 30px;
}

.account-contact-update .site-details-row-card.no-data-card-row {
    border: 0;
    background: transparent;
}

.account-contact-update .site-details-row-card.no-data-card-row img {
    height: 35px;
    width: 35px;
}

.account-contact-update .site-details-row-card h5 {
    margin-bottom: 5px;
}

.account-contact-update .site-details-row-card h6 {
    margin-top: 0;
}

.account-contact-update
.site-details-row-card.no-data-card-row
.contact-site-add-card
img {
    height: 25px;
    width: 25px;
}

.small-heading-contact {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: var(--Gray-3-color);
}

.ant-form.main-inner-form
.hide-arrow.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector,
.search-overlap .ant-select-single.ant-select-show-arrow .ant-select-selector {
    padding-left: 30px;
}

.avatar-user-common {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    text-transform: uppercase;
    color: var(--white-color);
    background: var(--Green-1-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.add-sub-tier-input-form
.ant-btn.ant-dropdown-trigger.ant-dropdown-link-resource {
    background: #3f4e52;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    height: 32px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    padding: 8px 35px;
    border-radius: 4px;
}

.add-sub-tier-input-form
.ant-btn.ant-dropdown-trigger.ant-dropdown-link-resource.ant-dropdown-open,
.add-sub-tier-input-form
.ant-btn.ant-dropdown-trigger.ant-dropdown-link-resource:hover,
.add-sub-tier-input-form
.ant-btn.ant-dropdown-trigger.ant-dropdown-link-resource:focus {
    background: var(--Green-1-color);
}

.add-adding-dropdown.add-resource-dropdown .ant-dropdown-menu {
    border: none;
    border-radius: 8px;
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.01)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.03));
}

.custom-table-main-row-wage-info-main.inventory-items-table
.table-change-inventoryKit
.custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main.inventory-items-table
.table-change-inventoryKit
.custom-table-cell-td.custom-table-cell-td-1 {
    width: 16%;
}

.custom-table-main-row-wage-info-main.inventory-items-table
.table-change-inventoryKit
.custom-table-cell-th.custom-table-cell-th-2,
.custom-table-main-row-wage-info-main.inventory-items-table
.table-change-inventoryKit
.custom-table-cell-td.custom-table-cell-td-2 {
    width: 25%;
}

.inventory-items-table .table-change-inventoryKit .custom-table-cell-th,
.inventory-items-table .table-change-inventoryKit .custom-table-cell-td,
.custom-table-main-row-wage-info-main.inventory-items-table
.table-change-inventoryKit
.custom-table-cell-td {
    width: 14.7%;
}

.role-name-tg {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: var(--bdbdbd-color);
    letter-spacing: 0.02em;
    border-left: 1px solid var(--bdbdbd-color);
    padding-left: 8px;
    margin-left: 5px;
}

.opt-group-multi
.ant-select-item.ant-select-item-option.ant-select-item-option-grouped {
    height: auto;
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 10px 20px;
}

.opt-group-multi
.ant-select-item.ant-select-item-option.ant-select-item-option-grouped:last-child {
    border: 0;
}

.opt-group-multi .select-option-details p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    color: var(--bdbdbd-color);
}

.opt-group-multi .select-option-icon {
    margin-right: 10px;
}

.to-associate-details {
    right: 30px;
    top: 45px;
}

.main-inner-form .to-associate-details li {
    color: var(--Gray-2-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 0;
}

.ant-form.main-inner-form .ant-input.text-editor-field {
    height: 247px;
    margin-top: 30px;
    padding: 15px;
}

.proposal-send-form .ant-picker-input > input[value] {
    color: var(--Gray-2-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
}

.grid-system-div ul {
    margin-right: 15px;
}

.grid-system-div ul li {
    color: var(--Gray-3-color);
    letter-spacing: 0.07px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}

.grid-system-div ul li.list-inline-item:not(:last-child) {
    margin-right: 0.7rem;
}

.grid-system-div ul li.list-inline-item:nth-child(1) {
    margin-right: 15px;
}

.grid-system-div ul li .ant-btn {
    position: relative;
    padding: 0;
    border: 0;
    background: transparent;
}

.grid-system-div ul li .ant-btn img {
    transition: 0.3s ease all;
}

.grid-system-div ul li .ant-btn.active img.img-gray,
.grid-system-div ul li .ant-btn:hover img.img-gray {
    opacity: 0;
}

.grid-system-div ul li .ant-btn img.img-active {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    opacity: 0;
}

.grid-system-div ul li .ant-btn.active img.img-active,
.grid-system-div ul li .ant-btn:hover img.img-active {
    opacity: 1;
}

.contact-details-table
.ant-table.ant-table-middle
.ant-table-tbody
> tr
> td:nth-child(2),
.contact-details-table
.ant-table.ant-table-middle
.ant-table-thead
> tr
> th:nth-child(2) {
    text-align: right;
}

.custom-table-cell-td.custom-table-cell-td-3 span {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.update-table-dispatch .proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.dispatch-service-varient {
    width: 100%;
}

.custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
    width: 77.01%;
}

.update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
    width: 76.7%;
}

.update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid .custom-table-cell-td:nth-child(2) {
    width: 11.6%;
}

.update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid .custom-table-cell-td:nth-child(3) {
    width: 11.7%;
}

.update-table-dispatch .custom-table-main-row-wage-info-main .custom-table-cell-th.custom-table-cell-th-1,
.update-table-dispatch .custom-table-main-row-wage-info-main .custom-table-cell-td.custom-table-cell-td-1 {
    width: 14.2%;
}

.update-table-dispatch .custom-table-main-row-wage-info-main .custom-table-cell-th,
.update-table-dispatch .custom-table-main-row-wage-info-main .custom-table-cell-td {
    width: 13.7%;
}

.border-green.ant-select-dropdown {
    border: 2px solid var(--Green-1-color);
    border-radius: 8px;
    min-width: 120px !important;
    width: 120px !important;
}

.pointer-no-card, .pointer-no-card h6 {
    transition: 0.3s ease all;
    color: var(--Gray-2-color);
}

.pointer-no-card:hover h6 {
    color: var(--Green-1-color);
}

.inventory-sub-total.sub-total-text {
    color: var(--Gray-1-color);
    margin: 25px 30px 0 0;
    font-weight: 500;
    text-align: right;
}

.sub-total-amount {
    margin: 0 0 0 30px;
}

.ant-select.select-pdf-options.ant-select-disabled {
    opacity: 0.5;
}

.custom-select-multi .ant-form-item .ant-select.search-and-select-tag .ant-select-selection-search-input {
    padding-left: 0;
}

.custom-select-multi .ant-form-item .ant-select.search-and-select-tag .ant-select-selector {
    padding-right: 80px;
}

.dropdown-select-search .ant-select-arrow {
    top: 12px;
    right: 5px;
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.dropdown-select-search.ant-select-focused .ant-select-arrow {
    opacity: 1;
}

.retrieve-oppo.cursor {
    cursor: no-drop !important;
    color: var(--Gray-3-color);
}

.blank-red .ant-select-selection-placeholder {
    color: red !important;
}

.retrieve-oppo.cursor {
    cursor: no-drop !important;
    color: var(--Gray-3-color);
}

.blank-red .ant-select-selection-placeholder {
    color: red !important;
}

.cut-text {
    margin-right: 8px;
    text-decoration: line-through;
}

.vehicle-group-heading .labor-group-info {
    position: relative;
    top: -1px;
    left: 10px;
    padding: 0;
}

.labor-group-info-update.labor-group-info {
    position: relative;
    top: -1px;
    left: 6px;
    padding: 0;
}


.display_name_info {
    position: relative;
    top: -1px;
    left: 15px;
    width: 290px;
    padding: 0;
}

.proposal-labor .labor-grp-icon {
    height: 15px;
    width: 15px;
}

.proposal-labor .labor-groups {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.004rem;
    color: #828282;
}

.ant-form.main-inner-form .edit-box-custom.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 59px;
}

.ant-form.main-inner-form .edit-box-custom.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 57px;
}

.ant-form.main-inner-form .edit-box-custom.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 57px;
}

.ant-btn.load-more-btn {
    background: var(--white);
    border: none;
    box-shadow: none;
    color: var(--Green-1-color);
    letter-spacing: -0.08px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 0 8px;
}

.ant-btn.load-more-btn:hover {
    background: var(--Green-1-color);
    color: var(--white-color);
}

.dashed-line {
    content: '';
    top: 19px;
    left: 0;
    right: 0;
    margin: auto;
    width: 7px;
    height: 2px;
    background: var(--Gray-2-color);
}

.create-btn-main.filter-btn {
    width: 150px;
}

.edit-select-box .ant-select-arrow {
    z-index: 2;
}

.edit-select-box .ant-select-selector {
    z-index: 99;
}

.post-custom-tab {
    width: 100%;
}

.ant-tabs.carpet-cleaning-main-common-tab .post-custom-tab .ant-tabs-content-holder {
    padding-top: 10px;
    overflow: auto;
}

.ant-tabs.carpet-cleaning-main-common-tab .post-custom-tab .ant-tabs-nav {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    top: 1px;
    height: 47px;
    z-index: 0;
}

.ant-tabs.carpet-cleaning-main-common-tab .post-custom-tab .ant-tabs-tab {
    border: 0;
}

.post-custom-tab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
    background: transparent;
    border: 0;
    color: var(--bdbdbd-color);
    padding: 15px;
}

.post-custom-tab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--Gray-6-color);
}

.set-to-repeat-row h6 {
    color: var(--bdbdbd-color);
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
}

.coming-soon-div.coming-soon-div-update {
    background: rgba(242, 242, 242, 0.75);
    border-radius: 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    left: 15px;
    width: calc(100% - 30px);
    height: 100px;
}

.border-top-1 {
    border-top: 1px solid var(--Gray-5-color);
}

.small-gray-heading {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: var(--Gray-3-color);
}

.custom-uom-table.custom-dispatch-table-div .table tr th,
.custom-uom-table.custom-dispatch-table-div .table tr td {
    width: 25%;
}

.custom-uom-table.custom-dispatch-table-div .table tr td {
    min-height: 62px;
    border-top: 1px solid #e0e0e0;
}

.custom-uom-table.custom-dispatch-table-div .table:last-child tr:last-child td:last-child,
.custom-uom-table.custom-dispatch-table-div .table tr td:nth-child(3),
.custom-uom-table.custom-dispatch-table-div .table tr th:nth-child(3),
.custom-uom-table.custom-dispatch-table-div .table tr th:last-child {
    border-left: 0;
}

.user-info-div.operator-info-div {
    padding-top: 0;
    padding-left: 10px;
}

.operator-info-div .heading-op {
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    color: var(--Gray-2-color);
    display: block;
}

.operator-info-div .op-description {
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
    color: var(--bdbdbd-color);
    display: block;
}

.operator-info-div .more-op {
    color: #3391FF;
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
    display: block;

}

.not-accepted-div {
    border-radius: 3px;
    background: var(--Gray-6-color);
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    color: #EB5757;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 5px;
    bottom: 0;
    margin: auto;
    height: 20px;
}

.user-info-div-main.operators-info-div-main {
    padding: 0 15px;
    margin: 8px 0;
}

.ant-btn.create-btn-main.create-btn-main-update {
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: var(--Gray-2-color);
    width: 70px;
    box-shadow: none;
    padding: 0;
}

.ant-btn.create-btn-main.create-btn-main-update:hover {
    background: var(--Gray-5-color);
}

/*--------Dispatch Css Start Here------*/
.dispatch-header-fixed {
    height: 62px;
    background: rgba(242, 242, 242, 0.5);
    align-items: center;
    position: fixed;
    width: calc(100% - 250px);
    z-index: 99;
    transition: 0.3s ease all;
    display: flex;
}

.dispatch-header-main {
    padding: 0 30px;
    width: 426px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease all;
}

.dispatch-header-main.dispatch-header-main-right {
    padding: 0 30px;
    width: calc(100% - 426px);
    left: 426px;
}

.card-dispatch-vehicle {
    width: 66.67%;
    float: left;
    transition: 0.1s ease all;
}

.card-dispatch-holding {
    width: 100%;
    display: -webkit-box;
    overflow: auto;
}

.dispatch-main-left {
    border-right: 2px solid var(--Gray-5-color);
    width: 426px;
    float: left;
    transition: 0.3s ease all;
    position: fixed;
    z-index: 9;
    background: var(--white);
    left: 250px;
}

.update-dispatch .dispatch-main-left {
    left: -1000%;
}

.dispatch-main-right {
    border-left: 2px solid var(--Gray-5-color);
    width: calc(100% - 426px);
    float: left;
    transition: 0.3s ease all;
    margin-left: 426px;
}

.update-dispatch .dispatch-main-right {
    width: 100%;
    margin-left: 0;
}

.update-dispatch .ant-tabs.carpet-cleaning-main-common-tab.common-tab-dispatch .ant-tabs-nav {
    width: calc(100% - 250px);
    padding-left: 30px;
}

.update-dispatch .dispatch-header-main {
    left: -1000%;
}

.update-dispatch .dispatch-header-main.dispatch-header-main-right {
    width: 100%;
    left: 0;
}

.dispatch-select-header.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
    background: transparent;
    border: 0;
    width: auto;
}

.dispatch-select-header.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
.dispatch-select-header .ant-select-selection-item {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: var(--Gray-1-color);
}
.dispatch-select-header.ant-select:not(.ant-select-customize-input).ant-select-focused .ant-select-selector{
    outline: 0;
    border-color: transparent ;
    box-shadow: none ;
}
.dispatch-select-header .ant-select-arrow {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DAF2EB;
    border-radius: 50%;
    right:10px;
    z-index: -1;
    top: 0;
    bottom: 0;
    margin: auto;
}

.dispatch-select-header .ant-select-arrow .anticon {
    color: var(--Green-1-color);
}

.select-dispatch-header.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    width: 100px;
}

.select-dispatch-header.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
.select-dispatch-header .ant-select-selection-item {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-2-color);
}

.dash-line {
    background: var(--Gray-2-color);
    width: 7px;
    height: 2px;
    position: absolute;
    top: 15px;
    right: -3px;
}

.dispatch-left-main, .dispatch-right-main {
    padding: 20px 30px;
    margin-top: 62px;
    z-index: -1;
    height: calc(100vh - 137px);
    overflow: auto;
}

.scroll-custom-common::-webkit-scrollbar-track, .card-dispatch-holding::-webkit-scrollbar-track {
    border-radius: 5rem;
    background-color: var(--Gray-5-color);
}

.scroll-custom-common::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.scroll-custom-common::-webkit-scrollbar-thumb, .card-dispatch-holding::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--Gray-3-color);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.card-dispatch-holding::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 3px;
}

/*.dispatch-left-min-header-row {*/
/*    padding: 0 0 15px;*/
/*}*/

.filter-btn.filter-btn-dispatch.ant-btn {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: auto;
    height: 32px;
}

.filter-btn.filter-btn-dispatch.ant-btn img {
    width: 24px;
    height: 24px;
}

.dispatch-left-min-header-row .search-bar-div {
    width: 67%;
    float: left;
}

.dispatch-left-min-header-row .search-bar-div .ant-form .ant-btn {
    height: 32px;
    left: 10px;
}

.dispatch-left-min-header-row .search-bar-div .ant-form .ant-input,
.dispatch-date-input-row .ant-picker {
    border-radius: 6px;
    width: 100%;
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    height: 32px;
}

.dispatch-date-input-row .ant-picker-input > input,
.dispatch-left-min-header-row .search-bar-div .ant-form .ant-input {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
}

.dispatch-left-min-header-row .search-bar-div .ant-form .ant-input {
    padding: 0 15px 0 35px;
}

.dispatch-date-input-row .ant-picker-input > input::-webkit-input-placeholder,
.dispatch-left-min-header-row .search-bar-div .ant-form .ant-input::-webkit-input-placeholder {
    color: var(--bdbdbd-color);
}

.dispatch-left-min-header-row .new-opportunity-btn-div {
    float: left;
    width: 33%;
}

.dispatch-date-input-row {
    margin-top: 20px;
}

.dispatch-word-card {
    background: var(--white-color);
    border: 1px solid var(--Gray-5-color);
    box-sizing: border-box;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 15px 0;
    /*border-bottom: 7px solid #F9FBFB;*/
    min-height: 100px;
    margin: 15px 0;
    position: relative;
}

.dispatch-word-card.active {
    border-left: 4px solid #7A7FE6;
}

.dispatch-card-heading {
    padding-bottom: 5px;
}

.dispatch-word-card h6 {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
}

.work-id {
    font-weight: 700;
    color: var(--Gray-3-color);
    float: left;
    border-right: 1px solid var(--Gray-3-color);
    padding-right: 5px;
    width: 40px;
}
.total-assign{
    color: var(--Gray-3-color);
}
.project-name {
    color: var(--Gray-2-color);
    font-weight: 600;
    width: calc(100% - 40px);
    float: left;
    padding-left: 10px;
    /* display: inline-block; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dispatch-word-card small {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    color: var(--Gray-3-color);
}

.rescheduled-div {
    width: 100%;
    min-height: 20px;
    background: var(--Gray-6-color);
    border-radius: 3px;
    color: #7A7FE6;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    justify-content: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    padding: 4px 8px;
}

.dispatch-word-card ul {
    margin: 8px 0 18px;
}

.dispatch-word-card ul li {
    color: var(--Gray-2-color);
    letter-spacing: 0.07px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin: 3px 0;
}

.dispatch-word-card ul li img {
    margin-right: 2px;
}

.ant-btn.ant-dropdown-link-custom {
    border: 0;
    background: var(--white-color);
    padding: 0;
    box-shadow: none;
    position: absolute;
    top: 0;
    right: 5px;
    height: auto;
}

.heading-dispatch-left {
    background: var(--Gray-6-color);
    min-height: 30px;
    align-items: center;
    margin: 15px 0;
}

.heading-dispatch-left h6 {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: var(--bdbdbd-color);
}

.color-gray-3 {
    color: var(--Gray-3-color) !important;
}

.repeats-btn.ant-btn {
    background: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    color: var(--bdbdbd-color);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    position: absolute;
    bottom: -10px;
    right: 15px;
}

.time-schedule-div {
    padding-top: 10px;
}

.time-schedule-div ul li {
    color: var(--bdbdbd-color);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
}

.time-schedule-div .repeats-btn.ant-btn {
    position: relative;
    top: 0;
    right: 0;
    height: auto;
}

.repeats-btn.ant-btn img {
    margin-left: 5px;
}

.ps-filter {
    position: absolute;
    right: 19px;
    top: 41px;
    width: 120px;
    text-align: left;
}

.ant-tabs.carpet-cleaning-main-common-tab.common-tab-dispatch .ant-tabs-nav {
    width: calc(100% - 700px);
    top: 135px;
    padding-left: 15px;
}

.ant-tabs.carpet-cleaning-main-common-tab.common-tab-dispatch .ant-tabs-content-holder {
    padding-top: 50px;
}

/*drag-and-drop-calendar-css*/

.drag-drop-main-row {
    /*margin-bottom: 20px;*/
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
}

.box-1-header {
    width: 22%;
    float: left;
    border-right: 1px solid var(--Gray-5-color);
    padding: 10px 4px;
    display: flex;
    align-items: center;
}

.box-1-header.box-1-header-main {
    width: 22.2%
}

.box-1-header ul li {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
}

.box-1-header ul .list-inline-item:not(:last-child) {
    margin-right: 0;
}

.box-1-header ul li .arrow-btn {
    border: 0;
    background: transparent;
    padding: 0;
}

.box-2-header {
    width: 78%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-2-header h6 {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
}

.common-box-calender {
    border-top: 1px solid var(--Gray-5-color);
    display: flex;
    align-items: center;
}

.box-1-left {
    width: 100%;
    border-bottom: 1px solid var(--Gray-5-color);
    padding: 10px 15px;
}

.box-1-left:last-child {
    border-bottom: 0;
}

.common-card-width {
    float: left;
    width: 20%;
    border-right: 1px solid var(--Gray-5-color);
}

.common-card-width:last-child {
    border-right: 0;
}

.data-header-employees-scroll-common {
    width: 100%;
    float: left;
    margin: 0;
    border-top: 1px solid var(--Gray-5-color);
}

.data-header-employees-scroll-common-vehicles {
    padding: 0 0 10px 10px;
}

/*.data-header-employees-scroll {*/
/*    height: 505px;*/
/*    overflow: auto;*/
/*}*/


/*custom-card-fleet-group*/
.fleet-data-left, .fleet-data-right-main {
    width: 33.33%;
    display: inline-block;
    position: relative;
    border-right: 1px solid var(--Gray-5-color);
    background: #F7F8FA;
}

.custom-scroll-dispatch {
    height: calc(100vh - 380px);
    overflow: auto;
}

.inner-card-common {
    padding: 10px;
}

.data-inner-all {
    height: calc(100vh - 290px);
}

.custom-card-main {
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
    border-radius: 6px;
    padding: 8px 15px 45px 15px;
    border: 1px solid var(--Gray-5-color);
    min-height: 146px;
    width: 100%;
    background: var(--white-color);
    min-width: 100%;
    cursor: pointer;
    /* max-width: 250px; */
    position: relative;
    margin: 0 0 10px;
}
.ant-select-dropdown.dispatch-warehouse-dropdown{
    min-width: 220px !important;
    width: 220px !important;
}
.custom-card-main.card-crew-chief {
    min-height: 68px;
    padding: 8px 15px;
}

.time-schedule-bar {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--Gray-2-color);
    text-align: center;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    padding: 5px 0;
    background: var(--Gray-5-color);
}

.name-avatar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--Green-2-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--white-color);
    margin-right: 10px;
}

.crew-chief-name {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #38BC94;
    margin-left: 10px;
}


.scroll-time-list {
    height: 404px;
    overflow: auto;
}

.box-2-header-update::-webkit-scrollbar-track,
.data-header-employees-scroll-common::-webkit-scrollbar-track,
.react-trello-board.custom-data-board::-webkit-scrollbar-track,
.custom-scroll-dispatch::-webkit-scrollbar-track,
.data-header-employees-scroll::-webkit-scrollbar-track,
.react-trello-board.custom-data-board .react-trello-lane > div::-webkit-scrollbar-track,
.scroll-time-list::-webkit-scrollbar-track,
.custom-right-scroll::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: var(--Gray-5-color);
}

.box-2-header-update::-webkit-scrollbar,
.scroll-time-list::-webkit-scrollbar,
.data-header-employees-scroll-common::-webkit-scrollbar,
.custom-scroll-dispatch::-webkit-scrollbar,
.react-trello-board.custom-data-board::-webkit-scrollbar,
.react-trello-board.custom-data-board .react-trello-lane > div::-webkit-scrollbar,
.data-header-employees-scroll::-webkit-scrollbar,
.custom-right-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
}

.custom-right-scroll{
    overflow: scroll !important;
    height:calc(100vh - 290px);
}

.box-2-header-update::-webkit-scrollbar-thumb,
.scroll-time-list::-webkit-scrollbar-thumb,
.data-header-employees-scroll-common::-webkit-scrollbar-thumb,
.custom-scroll-dispatch::-webkit-scrollbar-thumb,
.react-trello-board.custom-data-board::-webkit-scrollbar-thumb,
.react-trello-board.custom-data-board .react-trello-lane > div::-webkit-scrollbar-thumb,
.custom-right-scroll::-webkit-scrollbar-thumb,
.data-header-employees-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--Gray-3-color);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.react-trello-board.custom-data-board .react-trello-lane header, .react-trello-board.custom-data-board .react-trello-lane header > span,
.custom-data-board .data-details-inn {
    padding: 0;
    width: 100%;
}

.no-time-div {
    letter-spacing: 0.07px;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: var(--bdbdbd-color);
}

/*react-trello-css*/
.react-trello-board.custom-data-board {
    background: var(--white-color);
    padding: 0;
    height: calc(100vh - 290px);
}

.react-trello-board.custom-data-board > div {
    width: 100%;
}

.react-trello-board.custom-data-board .react-trello-lane {
    margin: 0 0 -16px;
    border-radius: 0;
    background: var(--white-color);
    padding: 0 1px;
    border-right: 1px solid var(--Gray-5-color);
    position: relative;
    top: 0;
    width: 33.33%;
}

.react-trello-board.custom-data-board .react-trello-lane:first-child,
.fleet-data-left {
    position: sticky;
    left: 0;
    z-index: 99;
    display: inline-block;
    float: left;
    background: var(--white-color);
}

.data-header-employees-scroll-common-vehicles .react-trello-board.custom-data-board .react-trello-lane {
    top: 0;
    margin: 0;
}

.data-header-employees-scroll-common-vehicles .react-trello-board.custom-data-board .react-trello-lane:first-child {
    position: sticky;
    z-index: 9;
    top: -16px;
    left: 0;
}

.react-trello-board.custom-data-board .react-trello-lane > div {
    min-width: 100%;
    margin-top: 1px;
    min-height: 100px;
    padding-bottom: 385px;
    overflow: auto;
    max-height: 100vh;
    background: var(--white-color);
}

.eHHYCH {
    display: none;
}

.custom-data-board article {
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 8px 15px 45px 15px;
    border: 1px solid var(--Gray-5-color);
    min-height: 146px;
    width: 100%;
    background: var(--white-color);
    min-width: 100%;
}

.custom-data-board article:hover {
    background: var(--white-color);
}

.custom-data-board article[data-id='Card1'] {
    border-color: var(--Gray-5-color);
}

.custom-data-board article header {
    margin: 0;
    border: 0;
    padding: 0;
}

.custom-data-board article small, .custom-data-board article p {
    letter-spacing: 0.07px;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: var(--Gray-3-color);
}

.custom-data-board article h6, .custom-card-main h6 {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: var(--Gray-2-color);
    letter-spacing: 0.01em;
    white-space: break-spaces;
}

.custom-data-board article p, .custom-card-main p {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
}

.pull-push-btn-div {
    transform: rotate(-180deg);
    position: absolute;
    left: -115px;
    margin: auto;
    top: 50%;
    z-index: 9;
}

.update-dispatch .pull-push-btn-div {
    left: -90px;
}

.close-btn-dispatch.ant-btn {
    background: #DAF2EB;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    width: 206px;
    height: 26px;
    padding: 0;
    text-align: center;
    letter-spacing: 0.07px;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    color: #828282;
    transform: rotate(-90deg);
    border: 0;
    border-radius: 0 0 12px 12px;
}

.update-dispatch .close-btn-dispatch.ant-btn {
    border-radius: 12px 12px 0 0;
}

.ant-btn.close-btn-dispatch .anticon {
    transform: rotate(-90deg);
    font-size: 8px;
    position: relative;
    bottom: 3px;
}

.close-text {
    transform: rotate(-180deg);
    bottom: -1px;
    position: relative;
}

.data-details-inn {
    border: 1px solid #E0E0E0;
    background: #F9F9F9;
    width: 33.33%;
    height: 56px;
}

.data-details-inn h6 {
    font-weight: 600;
    font-size: 9px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: var(--Gray-2-color);
    white-space: break-spaces;
}

.arrow-btn.ant-btn {
    background: transparent;
    border: 0;
}

.common-project-btn {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
    height: 20px;
    font-weight: 600;
    font-size: 8px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    border: 0;
    margin-top: 5px;
    padding: 0 10px;
}

.common-project-btn.assign-btn {
    background: #3F4E52;
    color: var(--white-color);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 6px 6px;
    height: 25px;
    width: 100%;
}

.common-project-btn.assign-btn.dispatch-btn-new {
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
    margin-bottom: 15px;
}

.common-project-btn.assign-btn.dispatch-btn-new:disabled, .common-project-btn.assign-btn.dispatch-btn-new:disabled:hover {
    opacity: 0.5;
    background: #3F4E52;
    color: var(--white-color);
}

.common-project-btn.btn-regular {
    background: var(--Gray-5-color);
    color: var(--Gray-2-color);
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.common-project-btn.dispatch-now {
    background: var(--Gray-5-color);
    color: var(--Gray-2-color);
    border: 1px solid #828282;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 31px;
    background: var(--white-color);
}

.common-project-btn.btn-regular .anticon {
    color: var(--Green-1-color);
    font-size: 14px;
}

.tag-common {
    top: 6px;
    text-align: center;
    height: 16px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;
    width: 100%;
    right: 0;
    position: relative;
}

.custom-data-board .ant-btn.ant-dropdown-link-custom {
    top: 10px;
}

.line-row {
    display: inline-block;
    width: 100%;
}

.line-row li {
    border-bottom: 1px solid var(--Gray-5-color);
    height: 23px;
    display: inline-block;
    width: 100%;
}

.preview .preview-pdf-proposal .ant-btn{
    color: #38BC94;
    text-align: left;
}

.preview .preview-pdf-proposal {
    text-align: left;
    background-color: #FFFFFF;
    border: 1px solid var(--Gray-5-color);
    height: 44px;
}

.box-1-left ul li {
    font-weight: 400;
    font-size: 15px;
    color: var(--bdbdbd-color);
    line-height: 20px;
    text-align: center;
    padding: 4px 3px;
    height: 29px;
    display: inline-block;
}

.custom-data-board .eDjtPr.project-card-row-update {
    position: absolute;
    top: 0;
}

.custom-data-board .eDjtPr p.site-address-dispatch,
.custom-card-main p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    color: var(--Gray-2-color);
    margin: 15px 0 0;
    white-space: break-spaces;
}

.common-checkbox .ant-checkbox-wrapper {
    padding: 4px 0;
}

.common-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.common-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.common-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--Green-1-color);
}

.common-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--Green-1-color);
}

.common-checkbox .ant-checkbox-checked::after {
    border: 1px solid var(--Green-1-color);
}

.search-btn-common {
    top: 46px;
    left: 8px;
    height: 38px;
}

.small-heading-div {
    letter-spacing: 0.07px;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: var(--Gray-4-color);
}


.box-2-header.box-2-header-update {
    display: inline-block;
    overflow: auto;
}

.card-box-header-2 {
    overflow: auto;
    width: 1000px;
}

.card-operator {
    width: 100%;
    min-height: 90px;
    float: left;
}

.no-data-card {
    border: 1px solid var(--Gray-5-color);
    background: var(--Gray-6-color);
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-data-card h6 {
    color: var(--Gray-4-color);
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
}

.operator-card-main {
    border: 1px solid var(--Gray-5-color);
    background: #F7FEFC;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    width: 100%;
    min-height: 90px;
    align-items: center;
}

.operator-initial {
    width: 40px;
    float: left;
}

.emp-tag-name {
    width: 32px;
    height: 32px;
    background: var(--Green-2-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--white-color);
}

.operator-details {
    width: calc(100% - 40px);
    float: left;
    display: grid;
}

.operator-details h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: 0.01em;
    color: var(--Gray-2-color);
    padding: 5px;
}

.loading-spinner {
    text-align: center !important;
    width: 100%;
    margin-top: 50px;

}

.operator-details h6,
.operator-details p {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.operator-details p {
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.07px;
    color: var(--bdbdbd-color);
    line-height: 2;
}

.ant-collapse.custom-collapse-work-order {
    border: 0;
    border-radius: 6px;
}

.custom-collapse-work-order.ant-collapse > .ant-collapse-item {
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 15px;
    overflow: hidden;
}

.custom-collapse-work-order.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    background: rgba(242, 242, 242, 0.5);
    height: 32px;
    color: var(--Gray-3-color);
    letter-spacing: 0.02em;
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
    padding: 0 15px;
}

.custom-collapse-work-order.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
    background: rgba(242, 242, 242, 0.5) !important;
}

.custom-collapse-work-order.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 5px;
}

.content-row-details {
    align-items: center;
    padding: 2px 0;
}

.content-row-details.disabled {
    opacity: 0.5;
}

.content-row-details .ant-select {
    width: 100%;
}

.content-row-details h6,
.content-row-details h6 .ant-radio-wrapper {
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: var(--Gray-3-color);
    margin: 0;
}

.content-row-details .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
    border: 0;
    font-weight: 700;
    font-size: 11px;
    line-height: 17px;
    color: var(--Gray-3-color);
}

.content-row-details .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
}

.content-row-details .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0;
    line-height: 30px;
    color: var(--Green-1-color);
}

.content-row-details .ant-select-arrow {
    color: var(--Green-1-color);
    top: 50%;
}

.content-row-details .ant-checkbox-inner {
    border-radius: 50%;
    border-color: var(--bdbdbd-color);
    width: 18px;
    height: 18px;
}

.content-row-details .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: var(--Green-1-color);
    outline: 0;
}

.content-row-details .ant-checkbox-checked::after {
    border-color: var(--Green-1-color);
    border-radius: 50%;
}

.content-row-details .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--Green-1-color);
    border-color: var(--Green-1-color);
}

.content-row-details .in_active .ant-checkbox-checked::after {
    border-color: #EB5757;
}

.content-row-details .in_active .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #EB5757;
    border-color: #EB5757;
}

.border-TB-1 {
    border-top: 1px solid var(--Gray-6-color);
    border-bottom: 1px solid var(--Gray-6-color);
}

.content-row-details .ant-radio-inner {
    width: 12px;
    height: 12px;
    color: var(--Gray-5-color);
}

.content-row-details .qty-select-box.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-weight: 400;
}

.content-row-details .qty-select-box .ant-select-arrow {
    color: var(--Gray-3-color);
}

.in_active .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border-right: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    top: 48%;
    width: 6px;
    height: 9px;
}

.in_active .ant-checkbox-checked .ant-checkbox-inner::before {
    position: absolute;
    display: table;
    border-left: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(143deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    top: 3px;
    left: -1px;
    width: 6px;
    height: 9px;
}

body .background-f2 .ant-input-number-disabled .ant-input-number-input {
    background: var(--Gray-6-color) !important;
}

.select-option-details .custom-select-option-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.summary-info-inner-row .ant-collapse > .ant-collapse-item.ant-collapse-item-disabled > .ant-collapse-header:hover {
    color: rgba(0, 0, 0, 0.25) !important;
}

.custom-data-board article h6 .total-assign {
    color: var(--Gray-3-color);
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.24px;
}

.custom-data-board article h6 .line-y {
    color: var(--bdbdbd-color);
    font-size: 11px;
}

.custom-breadcrumb-dispatch {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #DAF2EB;
    height: 20px;
    padding: 0 5px;
    display: inline-block;
    /*overflow: auto;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 10px;
}

.custom-breadcrumb-dispatch .ant-breadcrumb-separator {
    margin: 0 3px;
}

.custom-breadcrumb-dispatch.ant-breadcrumb > span {
    color: var(--Gray-2-color);
    font-weight: 600;
    font-size: 5px;
    line-height: 20px;
}

.custom-breadcrumb-dispatch.ant-breadcrumb > span > a {
    color: var(--Gray-3-color);
    font-weight: 400;
}

.status-btn {
    border-radius: 3px;
    background: var(--Gray-6-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: #7A7FE6;
    position: absolute;
    top: 15px;
    right: 30px;
    padding: 4px 15px;
}

.custom-table-main-row-wage-info-main.dispatch-service-varient .service-variants-table .custom-table-cell-th:nth-child(4),
.custom-table-main-row-wage-info-main.dispatch-service-varient .service-variants-table .custom-table-cell-th:nth-child(5),
.custom-table-main-row-wage-info-main.dispatch-service-varient .service-variants-table .custom-table-cell-th:nth-child(6),
.custom-table-main-row-wage-info-main.dispatch-service-varient .service-variants-table .custom-table-cell-th:nth-child(7),
.custom-table-main-row-wage-info-main.dispatch-service-varient .service-variants-table .custom-table-cell-th:nth-child(8),
.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(4),
.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(5),
.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(6),
.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(7),
.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(8) {
    width: 11.68%;
}

.custom-table-main-row-wage-info-main.dispatch-service-varient .custom-table-cell-td:nth-child(1) {
    /*background: #DAF2EB;*/
    background-color: #f7fefc;
}

.custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid .custom-table-cell-td:nth-child(2) {
    width: 11.5%;
}

.custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid .custom-table-cell-td:nth-child(3) {
    width: 8.5%;
}

.dispatch-service-varient .line-item-grid .custom-table-cell-td:nth-child(4) {
    padding: 0 15px !important;
}

.proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.dispatch-service-varient {
    width: 1300px;
}

.custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
    width: 79.95%;
    background: #DAF2EB;
}

.banner-apply-filter-row {
    border: 1px solid #E8F7FF;
    background: #E8F7FF;
    border-left: 6px solid #2D9CDB;
    margin-bottom: 30px;
    padding: 6px 5px;
}

.banner-apply-filter-row ul li {
    color: var(--Gray-2-color);
    letter-spacing: 0.07px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    padding: 0 0.5rem 0 0;
    border-right: 1px solid #bdbdbd;
}

.banner-apply-filter-row ul li:last-child {
    border-right: 0;
}

.banner-apply-filter-row ul li .ant-btn {
    background: transparent;
    border: 0;
    padding: 0;
    box-shadow: none;
    height: auto;
}

.banner-apply-filter-row ul li .ant-btn.applied-filter {
    color: #2D9CDB;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
}

.banner-apply-filter-row ul li .ant-btn.clear-btn {
    color: var(--Gray-3-color);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
}

.banner-apply-filter-row ul li strong {
    font-weight: 600;
}

.info-signifire {
    color: #EB5757;
    letter-spacing: 0.07px;
    font-style: italic;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}

.serviceVNote {
    letter-spacing: 0.07px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: var(--bdbdbd-color);
    position: absolute;
    bottom: 5px;
    left: 15px;
}

.person-list-details li {
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.07px;
    color: var(--bdbdbd-color);
}

.person-list-details li img {
    margin-right: 3px;
}

.custom-radio-div {
    justify-content: center;
}

.custom-radio-div .ant-radio-wrapper {
    margin: 0;
}

.custom-radio-div .ant-radio-inner {
    width: 22px;
    height: 22px;
    border: 7px solid #d9d9d9;

}

.custom-radio-div .ant-radio:hover .ant-radio-inner,
.custom-radio-div .ant-radio-input:focus + .ant-radio-inner,
.custom-radio-div .ant-radio-checked .ant-radio-inner,
.custom-radio-div .ant-radio-checked::after {
    border-color: var(--Green-1-color);
}

.custom-radio-div .ant-radio-inner::after {
    display: none;
}

.editalble-form-data .custom-search-update.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 10px;
    text-align: left;
}

.ant-select-single.custom-search-select.custom-search-update .ant-select-selector .ant-select-selection-search {
    left: 0;
    right: 0;
}

.custom-search-update.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 57px;
    line-height: 57px;
    padding: 0 10px;
}

.editalble-form-data .ant-select-single.custom-search-update .ant-select-selector .ant-select-selection-placeholder {
    line-height: 58px;
    padding: 0;
    color: var(--Green-1-color) !important;
}

.resource-name {
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.07px;
    color: var(--Green-1-color);
    padding-right: 30px;
}

.fleet-kit-table thead tr th {
    border: 1px solid #E0E0E0;
    background: #3F4E52;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    padding: 20px;
}

.fleet-kit-table.disposal-priceunit-table thead tr th {
    border: 1px solid #E0E0E0;
    font-weight: 700;
    background: #F9FBFB;
    color: var(--Gray-2-color)
}

.fleet-kit-table tbody tr td {

    border: 1px solid #E0E0E0;
    font-weight: 500;
    font-size: 15px;
    padding: 20px;
    line-height: 20px;
    background: var(--Gray-6-color);
    letter-spacing: -0.24px;
    color: #4F4F4F;
    position: relative;
}

.fleet-kit-table tbody tr td:first-child {
    background: #F7FEFC;
}

.ant-dropdown-trigger.custoom-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    display: flex;
    align-items: center;
}

.branch-tree-div {
    padding-left: 25px;
}

.rectangle-icon-div.rectangle-icon-update {
    left: 15px;
}

.fleet-kit-table tbody tr:last-child td:last-child .rectangle-icon-div.rectangle-icon-update:before {
    display: none;
}

.dispatch-info-header {
    padding-top: 30px;
}

.dispatch-info-header h6 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    color: #A9A7A7;
}

.dispatch-info-header ul li {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    width: 44%;
    color: var(--Gray-3-color);
}

.dispatch-info-header ul .list-inline-item:not(:last-child) {
    color: #333333;
}

.dispatch-info-header .text-area {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #333333;

}

.fleet-kit-parent {
    position: relative;
}

.fleet-kit-parent::before {
    content: url('../images/icons/treeArrow.svg');
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
    width: 15px;
    height: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
}

.fleet-kit-child {
    padding-left: 30px;
}

.ant-btn.remove-tier {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
}

.ant-btn.remove-tier:hover {
    background: var(--white);
    border-color: var(--Gray-4-color);
    color: var(--Gray-1-color);
}
.ant-btn.remove-tier[disabled]:hover{
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}
.tier-filter-data span:not(:last-child):after {
    content: " ,";
    position: relative


}

.my__custom{
    position: absolute;
    top: -20px;
    right: 50px;
    z-index: 999;
}

.custom-right-scroll::-webkit-scrollbar {
    height: 10px;
  }

.update_time_button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: #7FD4BA;
}
.update_time_button:hover{
    background-color: transparent;
    color: #7FD4BA;
    text-decoration-line: underline!important;
}


@media only screen and (max-width: 1200px) {
    .custom-table-main-row-wage-info-main
    .custom-table-cell-th.custom-table-cell-th-9.wage-info {
        width: 12.20%;
    }
}

    @media only screen and (min-width: 1200px) and (max-width: 1600px){
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .custom-table-cell-td:nth-child(2){
        width: 11.57%;
    }
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .custom-table-cell-td:nth-child(3){
        width: 10.62%;
    }
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .custom-table-cell-td:nth-child(4){
        width: 8.45%;
    }
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .custom-table-cell-td:nth-child(6){
        width: 10.58%;
    }
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .custom-table-cell-td:nth-child(9){
        width: 8.4%;
    }
    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .line-item-grid .custom-table-cell-td {
    width: 10.64%;
}
    .proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5){
        width: 10.59%;
    }
    .proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6){
        width: 10.61%;
    }
    .proposal-update-table.proposal-update-table-edit.invoice-table-main .line-item-grid .custom-table-cell-td:nth-child(3){
        width: 8.41%;
    }
    .proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4) {
    width: 9.17%;
}

    .custom-table-main-row-wage-info-main.proposal-update-table-edit.invoice-table-main .line-item-grid .custom-table-cell-td:nth-child(9){
        width: 8.38%;
    }

    .custom-table-main-row-wage-info-main
    .custom-table-cell-th.custom-table-cell-th-9.wage-info {
        width: 12.20%;
    }

}
@media only screen and (min-width: 1601px) {

    .proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item {
        width: 100%;
    }

    .custom-table-cell-td, .custom-th-heading {
        font-size: 12px;
    }

    .service-variant-update.custom-table-header1 .custom-table-cell-th.custom-table-cell-th-3,
    .service-variant-update.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-3 {
        width: 12%;
    }

    .custom-table-main-row,
    .custom-table-main-row.custom-update-table {
        width: 100%;
    }

    .service-varient-edit-col .custom-table-main-row.custom-update-table {
        width: 1500px;
    }

    .custom-table-header1 .custom-table-cell-th,
    .custom-table-header1 .custom-table-cell-td {
        width: 9.65%;
    }

    .custom-update-table.custom-table-header1 .custom-table-cell-th,
    .custom-update-table.custom-table-header1 .custom-table-cell-td {
        width: 8.1%;
    }

    .custom-table-header1 .custom-table-cell-th.custom-table-cell-th-1,
    .custom-table-header1 .custom-table-cell-td.custom-table-cell-td-1 {
        width: 14%;
    }

    .custom-update-table.custom-table-header1
    .custom-table-cell-th.custom-table-cell-th-1,
    .custom-update-table.custom-table-header1
    .custom-table-cell-td.custom-table-cell-td-1 {
        width: 8%;
    }

    .custom-table-header1 .custom-table-cell-th.custom-table-cell-th-2,
    .custom-table-header1 .custom-table-cell-td.custom-table-cell-td-2 {
        width: 15%;
    }

    .custom-update-table.custom-table-header1
    .custom-table-cell-th.custom-table-cell-th-2,
    .custom-update-table.custom-table-header1
    .custom-table-cell-td.custom-table-cell-td-2 {
        width: 11%;
    }

    .custom-table-cell-th.custom-table-cell-th-3,
    .custom-table-header1 .custom-table-cell-td.custom-table-cell-td-3 {
        width: 13%;
    }

    .custom-update-table .custom-table-cell-th.custom-table-cell-th-3,
    .custom-update-table.custom-table-header1
    .custom-table-cell-td.custom-table-cell-td-3 {
        width: 11%;
    }

    .custom-update-table.custom-table-header1 .custom-table-cell-td.custom-table-cell-td-3 {
        width: 12%;
    }

    .custom-table-main-row-wage-info-main
    .custom-table-cell-th.custom-table-cell-th-1,
    .custom-table-main-row-wage-info-main
    .custom-table-cell-th.custom-table-cell-th-9,
    .custom-table-main-row-wage-info-main
    .custom-table-cell-td.custom-table-cell-td-1,
    .custom-table-main-row-wage-info-main
    .custom-table-cell-td.custom-table-cell-td-9 {
        width: 10.8%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-th.custom-table-cell-th-1,
    .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-th.custom-table-cell-th-9,
    .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-td.custom-table-cell-td-1 {
        width: 7.8%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-td.custom-table-cell-td-9 {
        width: 7.8%;
    }

    .custom-table-main-row-wage-info-main.proposal-update-table .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
        width: 30.1%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4) {
        width: 11.2%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5) {
        width: 11.2%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6) {
        width: 11.2%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7) {
        width: 7.8%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(8) {
        width: 8.5%;
    }

    .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-td:last-child {
        width: 7.7% !important;
    }

    .custom-table-main-row-wage-info-main .custom-table-cell-th,
    .custom-table-main-row-wage-info-main .custom-table-cell-td {
        width: 11.18%;
    }

    .custom-table-main-row-wage-info-main
    .custom-table-cell-td.wage-info-collapse-td {
        width: 336px;
    }

    .custom-table-main-row-wage-info-main
    .custom-table-cell-td.wage-info-collapse-td-second {
        width: 194px;
    }

    .custom-table-main-row-wage-info-main
    .wage-table-view
    .custom-table-cell-td.wage-info-collapse-td {
        width: 446px;
    }

    .custom-table-main-row-wage-info-main
    .wage-table-view
    .custom-table-cell-td.wage-info-collapse-td-second {
        width: 300px;
    }

    .wage-collapse .custom-table-cell-td.wage-info-collapse-td {
        width: 21.98%;
    }

    .wage-collapse-first-col {
        width: 33.21% !important;
    }

    .wage-collapse-col {
        width: 22.32% !important;
    }

    .custom-table-main-row-wage-info-main
    .wage-table-edit
    .custom-table-cell-td.wage-info-collapse-td-second {
        width: 213px;
    }

    .custom-table-main-row-wage-info-main
    .service-variants-table
    .custom-table-cell-th:nth-child(4),
    .custom-table-main-row-wage-info-main
    .service-variants-table
    .custom-table-cell-td:nth-child(4) {
        width: 11.41%;
    }

    .line-item-grid .custom-table-cell-td:nth-child(2) {
        width: 13.4%;
    }

    .proposal-update-table .line-item-grid .custom-table-cell-td:nth-child(2) {
        width: 11.4%;
    }

    .custom-table-main-row-wage-info-main
    .service-variants-table
    .line-item-grid
    .custom-table-cell-td:nth-child(4) {
        width: 11.2%;
    }

    .custom-table-main-row-wage-info-main
    .service-variants-table
    .line-item-grid
    .custom-table-cell-td:nth-child(5) {
        width: 11.2%;
    }

    .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6) {
        width: 11.2%;
    }

    .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7) {
        width: 10.4%;
    }

    .custom-table-main-row.custom-table-main-row-proposal-line-item {
        width: 100%;
    }

    .update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid .custom-table-cell-td:last-child {
        width: 12.5% !important;
    }
    .update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid.work-order-dispatch-thead .custom-table-cell-td:last-child {
        width: 12.5% !important;
    }
    .update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid.work-order-dispatch-thead .custom-table-cell-td:last-child {
        width: 12.5% !important;
    }

    .update-table-dispatch .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-th,
    .update-table-dispatch .proposal-update-table.custom-table-main-row-wage-info-main .custom-table-cell-td {
        width: 12.5% !important;
    }

    .update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid .custom-table-cell-td.wage-info-collapse-td {
        width: 75% !important;
    }
    .update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid.work-order-dispatch-thead .custom-table-cell-td.wage-info-collapse-td {
        width: 75% !important;
    }
    .update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid.work-order-dispatch-thead .custom-table-cell-td.wage-info-collapse-td {
        width: 75% !important;
    }

    .proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.service-v-update {
        width: 1400px;
    }
    .custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th.custom-table-cell-th-1, .custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-th.custom-table-cell-th-9, .custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.custom-table-cell-td-1, .custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.custom-table-cell-td-9{
        width: 9.206%;
    }
    .custom-table-main-row-wage-info-main.service-v-update .custom-table-cell-td.background-white-div {
        width: 7.53%;
    }
    .proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7), .proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(8),
    .custom-table-main-row-wage-info-main.proposal-update-table.service-v-update .custom-table-cell-td:last-child {
        width: 9.2% !important;
    }


.proposal-update-table.custom-table-main-row.custom-table-main-row-proposal-line-item.proposal-update-table-edit{
    width: 1850px;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-th.custom-table-cell-th-1,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-th.custom-table-cell-th-2{
    width: 11.6%;
}
.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .custom-table-cell-th,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .custom-table-cell-td,
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(4),
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(5),
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(6),
.proposal-update-table.proposal-update-table-edit .line-item-grid .custom-table-cell-td:nth-child(3),
.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .custom-table-cell-td:nth-child(4),
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(7),
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .service-variants-table .line-item-grid .custom-table-cell-td:nth-child(8),
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-1,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-2,
.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td:nth-child(6),
.custom-table-main-row-wage-info-main.proposal-update-table.proposal-update-table-edit .custom-table-cell-td.custom-table-cell-td-9{
    width: 10%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.proposal-update-table-edit .custom-table-cell-td:last-child{
    width: 10% !important;
}
.custom-table-main-row-wage-info-main.proposal-update-table.proposal-update-table-edit .line-item-grid .custom-table-cell-td.wage-info-collapse-td, .table-width {
    width: 20%;
}
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(7),
.proposal-update-table.custom-table-main-row-wage-info-main.service-v-update .service-variants-table .line-item-grid.disposal-added .custom-table-cell-td:nth-child(8){
    width: 8.5% !important;
}
.custom-table-main-row-wage-info-main.proposal-update-table.service-v-update .disposal-added .custom-table-cell-td:last-child{
    width: 9.11% !important;
}
.update-table-dispatch .custom-table-main-row-wage-info-main.proposal-update-table.dispatch-service-varient .custom-table-body-update .line-item-grid.disposal-added .custom-table-cell-td.wage-info-collapse-td {
    width: 50% !important;
}
.update-table-dispatch .custom-table-main-row-wage-info-main.dispatch-service-varient .line-item-grid.disposal-added .custom-table-cell-td:last-child {
    width: 12.5% !important;
}





}
.invoicing-inner-collapse.ant-collapse .ant-collapse-item .ant-collapse-header{
    padding: 25px 15px 25px 0;
}
.create-opportunity-row .ant-collapse.invoicing-inner-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    left: auto;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}
.create-opportunity-row .ant-collapse.invoicing-inner-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
    transform: rotate(180deg);
}
.create-opportunity-row .invoicing-inner-collapse .ant-collapse-content{
    background: #F7FEFC;
    padding: 15px;
}
.service-variants-table .ant-collapse .ant-collapse-item .ant-collapse-header,
.create-opportunity-row .service-variants-table .ant-collapse-content{
    padding: 0;
}

.edit-select-box .ant-select-selector{
    align-items: center;
}

.img-logo{
    width: 200px;
    height: 200px;
}
