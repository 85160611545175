

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    padding: 12px 15px;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    overflow: hidden;
}

.header {
    border-bottom: solid 1px #E0E0E0;
    margin-bottom: 1.5rem;
    padding: 4px 0;
}

.header h2 {
    margin-bottom: 0;
}

.title {
    color: #849AA9;
    font-weight: 500;
    font-size: 16px;
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.progressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 500;
}
.progressContainer p {
    margin-bottom: 2px;
    font-size: 12px;
}

.progressContainer p:first-child {
    color: #BDBDBD;
}