
/*report main */


/*nav*/
.ant-tabs.carpet-cleaning-main-common-tab.analytics .ant-tabs-nav,
.ant-tabs.carpet-cleaning-main-common-tab.analytics .ant-tabs-content-holder {
    background-color: #F9F9F9;
}

.ant-tabs.carpet-cleaning-main-common-tab.analytics .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #daf2eb;
}


/* report - operation tab */

/* remove add on bootstrap col style */
.analytics.col-5, .analytics.col-7, .analytics.col-1 {
    padding-left: 0;
    margin-left: 0;
}

.analytics.col-5 {
    padding-right: 0;
}

.analytics.col-12 {
    margin-left: 0;
}

/*!* progress bar*!*/
.ant-progress-inner {
    border-radius: 0;
}

/*!*nav tab for chart*!*/
.operation-graph-data.ant-tabs .ant-tabs-nav {
    position: static;
    width: 100%;
    z-index: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-bottom: solid 1px #E0E0E0;
    background-color: #FFFFFF !important;
    overflow: hidden;
}

.operation-graph-data.ant-tabs .ant-tabs-content-holder {
    padding-top: 0;
}

.operation-graph-data.ant-tabs .ant-tabs-tab {
    padding: 16px 28px;
}

.operation-graph-data.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    justify-content: end;
    width: 100%;
}

/*!* data table header *!*/
.fullWidthDataTable .ant-table-thead > tr:nth-child(2) > .ant-table-cell {
    border-left: 1px solid #F0F0F0;
    background-color: #FFFFFF;
}

.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(1),
.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(2),
.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(3),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(1) {
    border-left:  1px solid rgba(240, 240, 240, 1);
}

.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(6),
.fullWidthDataTable .ant-table-thead > tr:nth-child(2) > th:nth-child(9),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(12) {
    border-right:  1px solid rgba(240, 240, 240, 1);
}

.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(4),
.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(5),
.fullWidthDataTable .ant-table-thead > tr:nth-child(1) > th:nth-child(6),
.fullWidthDataTable .ant-table-thead > tr:nth-child(2) > th:nth-child(1),
.fullWidthDataTable .ant-table-thead > tr:nth-child(2) > th:nth-child(4),
.fullWidthDataTable .ant-table-thead > tr:nth-child(2) > th:nth-child(7) {
    border-left:  2px solid rgba(79, 79, 79, 0.3);
}

/*!*data table body Border *!*/
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(4),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(7),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(10){
    border-left:  2px solid rgba(79, 79, 79, 0.3);
}

    /*data table body BG */
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(4),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(6),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(7),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(9),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(10),
.fullWidthDataTable .ant-table-tbody > tr > td:nth-child(12) {
    background-color: rgba(250, 250, 250, 1);
}

.fullWidthDataTable .ant-table-thead > tr > th {
    text-transform: none;
}


/*!* toolbar *!*/
.operation-card .ant-card-head {
    border-bottom: none;
}

.operation-card .ant-card-body {
    padding: 12px 24px;
}

/*!* report - operation-resource tab *!*/
/*!* selector *!*/
.data-table .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    background: var(--white-color);
    width: 100%;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-table .ant-picker {
    height: 40px;
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    width: 100%;
}

/*.ant-collapse > .ant-collapse-item > .ant-collapse-header {*/
/*    display: flex;*/
/*}*/

.donut-chart .ant-collapse-header:hover {
    background: #FFFFFF;
    color: var(--Green-1-color);
}




