.card {
    margin-left: 10px;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    /*height: 200px;*/
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    overflow: hidden;
    /*padding: 12px 16px;*/
    border-radius: 5px;
    margin-bottom: 10px;
}

.header {
    color: rgba(79, 79, 79, 1);
    font-weight: 500;
    font-size: 16px;
}

.ant-card-head {
    border-bottom: none;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 18px;
}

.title {
    color: #849AA9;
    letter-spacing: 0.02em;
    font-size: 12px;
}

.number {
    font-size: 20px;
    color: #4f4f4f;
    font-weight: 600;
    letter-spacing: 1px;
}

.divider {
    margin: 5px 0;
}

.secondDivider {
    background-color: rgba(79, 79, 79, 0.5);
}

.textColor {
    color: #EB5757;
}