

.miscellaneous .ant-modal-header {
    background-color: rgba(130, 130, 130, 1);
    color: white;
}

.miscellaneous .ant-modal-header .ant-modal-title {
    color: white;
}

.miscellaneous .ant-modal-close-x .anticon svg {
    width: 22px;
    height: 22px;
    color: white;
}

.textLabel {
    font-weight: 500;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 8px;
}

.miscellaneous .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border: 1px solid var(--Gray-5-color);
    background: var(--white-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: var(--Gray-2-color);
    width: 100%;
}

.miscellaneous .ant-btn-primary {
    background-color: rgba(63, 78, 82, 1);
    border: none;
}

.miscellaneous .ant-modal-footer {
    padding: 18px;
}
