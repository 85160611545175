.main {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    margin: 30px 0 0 10px;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.graph {
    width: 100%;
    padding: 20px 20px 20px 30px;
}


.graphHeader {
    padding: 4px 8px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
}
.graphHeader h5 {
    margin-bottom: 0;
}

.flexBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.test
{
    width: 120px;
    margin: 0 16px;
}

