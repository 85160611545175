
.main {
    padding: 20px 45px;
}

.cardSection {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}


.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}


@media (max-width: 1200px) {
    .cardSection {
        display: flex;
        flex-direction: column;
    }
}



