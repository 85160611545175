.wrap-class{
    z-index: 9999;
}

.dropdown-class{
    z-index:99999;
}

.picker-class.ant-picker:hover{
    border-color: var(--Green-1-color);
}