.main {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    padding: 10px;
    width: 50%;
    background-color: #FFFFFF;
}

.tableHeader {
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



