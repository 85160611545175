.main {
    padding: 20px 45px;
}

.doubleCardRow {
    margin: 4px 0 30px 10px;
    display: flex;
    flex-direction: row;
    gap: 28px;
}


