
.noDataContainer {
    background: #F9F9F9;
    height: 100px;
    border: 1px solid var(--Gray-5-color);
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.noDataContainer h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: var(--Gray-2-color);
    text-transform: capitalize;
    margin-top: 3px;
    letter-spacing: 0.01em;
}

.miscellaneous-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.miscellaneous-table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
}

.miscellaneous-table::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .4);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.miscellaneous-table .ant-table-thead {
    background-color: rgba(249, 251, 251, 1);
}

.miscellaneous-table .ant-table-container table > thead > tr th {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.miscellaneous-table .ant-table-container {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 5px;
}

.miscellaneous-table .ant-table-tbody > tr > td {
    border-left:  1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 12px;
}

.miscellaneous-table .ant-table-tbody > tr > td .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.miscellaneous-table .ant-table-tbody > tr > td .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
    border: none;
    background: transparent;
    box-shadow: none;
}

.miscellaneous-table .ant-table-tbody  > tr > td .ant-input-number,
.miscellaneous-table .ant-table-tbody  > tr > td .ant-input-number:focus {
    border: none;
    background: transparent;
    box-shadow: none;
}
.miscellaneous-table .ant-input-group-addon {
    border: none;
    background: transparent;
    box-shadow: none;
}

.miscellaneous-table .ant-table-tbody  > tr > td .ant-input {
    border: none;
    background: transparent;
    box-shadow: none;
}

.tableSection {
    display: block;
    border-left: 2px solid rgba(224, 224, 224, 1);
    border-right: 2px solid rgba(224, 224, 224, 1);
    padding: 10px 16px;
    overflow: hidden;
}

.tableSection p {
    color: rgba(130, 130, 130, 1);
    font-weight: 500;
    margin: 0;
}

.tableTotalPrice {
    background-color: rgba(224, 224, 224, 1);
}

.warning {
    color: rgba(235, 87, 87, 1);
}

.warning .ant-input-number-input {
    color: rgba(235, 87, 87, 1);
}

.tableSelectOption {
    background-color: transparent;
    min-width: 200px;
    border: none;
}

.tableSelectOption.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #4F4F4F;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.removeBtn {
    padding: 2px 1px;
    cursor: pointer;
    width: 10px;
}

.nameCol {
    min-width: 200px;
}

.disableInput {
    min-width: 120px;
}


.disableInput .ant-input-number .ant-input-number-input {
    text-align: center;
}

.disableInput .ant-input-number.ant-input-number-disabled,
.disableInput .ant-input-number-disabled .ant-input-number-input,
body .disableInput .ant-input-number-disabled .ant-input-number-input {
    background: transparent !important;
    border: none;
    color: var(--Gray-2-color);
    text-align: center;
}


