
.main {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
    padding: 2px;
}


.filterBadgeContainer {
    padding: 10px 0 15px;
}


.filterBtn {
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    width: 110px;
    color: #828282;
    text-transform: uppercase;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonIcon {
    width: 24px;
    height: 24px;
}

.filterBtn:hover, .filterBtn:focus {
    background-color: #DAF2EB;
    color: #828282;
    border: 1px solid #38BC94;
}

