
.createBtn .ant-btn {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    height: 36px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--white-color);
}

.dropdown {
    background: transparent;
    color: #4F4F4F !important;
}