
.flexContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    height: 80px;
}

.subtext {
    color: #828282;
}

.typeText {
    color: #38BC94;
}

.title {
    color: #4F4F4F;
}