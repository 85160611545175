
.main {
    padding: 20px 45px;
}

.pieChartSection {
    display: flex;
    flex-direction: row;
    gap: 28px;
}

.doubleCardRow {
    margin: 30px 0 30px 10px;
    display: flex;
    flex-direction: row;
    gap: 28px;
}

.mainDetailCard {
    width: calc(100% / 2);
}
.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 18px;
}
