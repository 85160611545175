.link{
    display: inline;
    position: relative;
}
.link-popup{
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
    width: fit-content;
    z-index: 1;
}

.input-container {
    display:flex;
    gap:4px;
    margin:5px 2px;
}
/* img{
    height: 15px;
} */
.popup-wrapper{
    display: inline;
    position: relative;
}
.popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;

}
