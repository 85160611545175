.main {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.03);
    margin: 30px 0 0 10px;
    padding: 10px;
    background-color: #FFFFFF;
}

.graph {
    width: 100%;
    padding: 20px 20px 20px 30px;
}


.graphHeader {
    padding: 12px 18px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: solid 1px #E0E0E0;
}

